import React, { FC, useEffect, useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Formik, Form, Field } from 'formik'
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiPage,
    EuiPageContent,
    EuiPageBody,
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiSpacer,
    EuiButton,
    EuiFieldPassword,
    EuiText,
    EuiPanel,
    EuiTitle,
    EuiImage
} from '@elastic/eui'
import * as Yup from 'yup'
import { login } from '../common/auth'
import YFlogo from '../assets/logo.svg'

interface LoginPageProps extends RouteComponentProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setLoggedIn: any
}

const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Please use a valid email').required('Email is required'),
    password: Yup.string().required('Password is required')
})

export const LoginPage: FC<LoginPageProps> = ({ navigate, setLoggedIn }) => {
    const [loginError, setLoginError] = useState<string | null>(null)

    useEffect(() => {
        if (localStorage.getItem('yf-admin-token')) {
            navigate && navigate('/dashboard')
        }
    }, [])

    return (
        <div style={{ display: 'flex', minHeight: '100vh' }}>
            <EuiPage paddingSize="none">
                <EuiPageBody style={{ background: '#fff' }}>
                    <EuiPageContent borderRadius="none" hasShadow={false}>
                        <EuiPanel style={{ maxWidth: '400px', margin: '0 auto' }}>
                            <div style={{ textAlign: 'center' }}>
                                <EuiImage src={YFlogo} alt="Logo" size="xs" />
                                <EuiSpacer size="s" />
                                <EuiTitle>
                                    <h2>Admin</h2>
                                </EuiTitle>
                            </div>
                            <EuiFlexGroup justifyContent="spaceAround">
                                <EuiFlexItem grow={1}>
                                    <EuiSpacer size="xxl" />
                                    <Formik
                                        validationSchema={SignupSchema}
                                        initialValues={{
                                            email: '',
                                            password: ''
                                        }}
                                        onSubmit={async (values) => {
                                            try {
                                                setLoginError(null)
                                                const loginRes = await login(values.email, values.password)
                                                localStorage.setItem('yf-admin-token', loginRes.token.access_token)
                                                setLoggedIn(true)
                                                navigate && navigate('/', { replace: true })
                                            } catch (err) {
                                                setLoginError(err.message)
                                            }
                                        }}
                                    >
                                        {({ errors, touched, isSubmitting }) => (
                                            <EuiForm>
                                                <Form>
                                                    <EuiFormRow
                                                        label="Email"
                                                        isInvalid={touched.email && errors.email ? true : false}
                                                        error={errors.email}
                                                    >
                                                        <Field as={EuiFieldText} name="email" disabled={isSubmitting} />
                                                    </EuiFormRow>
                                                    <EuiFormRow
                                                        label="Password"
                                                        isInvalid={touched.password && errors.password ? true : false}
                                                        error={errors.password}
                                                    >
                                                        <Field
                                                            as={EuiFieldPassword}
                                                            name="password"
                                                            type="password"
                                                            disabled={isSubmitting}
                                                        />
                                                    </EuiFormRow>

                                                    <EuiSpacer />

                                                    <EuiButton isLoading={isSubmitting} type="submit" fill>
                                                        Login
                                                    </EuiButton>

                                                    <EuiSpacer />

                                                    {loginError && (
                                                        <EuiText textAlign="center" color="danger">
                                                            {loginError}
                                                        </EuiText>
                                                    )}
                                                </Form>
                                            </EuiForm>
                                        )}
                                    </Formik>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiPanel>
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>
        </div>
    )
}
