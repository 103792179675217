import { v4 as uuidv4 } from 'uuid'

import { fetchTileChallenge } from 'API/fetchTileChallenge'
import { ENDPOINTS } from 'common/constants/endpoints'
import axios from '../../common/axios'

export const createIntialTile = async (challengeId: number) => {
    const initialTileChallengeRes = await fetchTileChallenge(challengeId)

    const createTileChallengeId = await axios.post(`${ENDPOINTS.TILE_API}`, {
        ...initialTileChallengeRes.challenge,
        description: 'INIT_DATA_EXERCISE'
    })

    if (createTileChallengeId.status !== 200 || createTileChallengeId.data?.hasError) {
        throw new Error(createTileChallengeId.data.message)
    }

    const tile = {
        key: uuidv4(),
        challengeId: challengeId,
        tileChallengeId: createTileChallengeId.data.tileChallengeId,
        tileId: null,
        isDirty: true,
        index: 0,
        mediaUrl: null,
        mediaContentType: 'video/mp4',
        challengeImage: null,
        challengeImageId: null,
        criteria: null,
        estimatedDuration: '5 MINS',
        description: 'INIT_DATA_EXERCISE',
        title: 'INIT_DATA_EXERCISE'
    }

    const createAndAssignTile = await axios.post(`${ENDPOINTS.TILE_API}`, {
        ...createTileChallengeId.data.challenge,
        tiles: [tile]
    })

    if (createAndAssignTile.status !== 200 || createAndAssignTile.data?.hasError) {
        throw new Error(createAndAssignTile.data.message)
    }

    return createAndAssignTile.data
}
