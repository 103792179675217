import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { LaneAdmin } from 'components/LaneAdmin'
import { FC } from 'react'

type ExercisePrioritizePageProps = RouteComponentProps

export const ExercisePrioritizePage: FC<ExercisePrioritizePageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.EXERCISES }}>
            <LaneAdmin eventType={EVENT_TYPE.EXERCISES} />
        </AdminPageLayout>
    )
}
