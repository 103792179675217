import {
    EuiDatePicker,
    EuiDescribedFormGroup,
    EuiFieldNumber,
    EuiFieldText,
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiFormRow,
    EuiPanel,
    EuiTextArea
} from '@elastic/eui'
import { Field, FormikValues, useFormikContext } from 'formik'
import moment, { Moment } from 'moment'
import { AWSPersonalComboBox } from 'pages/challenges/components/Detail/AWSPersonalComboBox'
import { MetaKeywordsComboBox } from 'pages/challenges/components/Detail/MetaKeywordsComboBox'
import { Fragment } from 'react'
import ReactQuill from 'react-quill'

export default function General() {
    const { errors, touched, values, setFieldValue, handleBlur, handleChange } = useFormikContext<FormikValues>()

    return (
        <EuiPanel paddingSize="l">
            <EuiForm component="div">
                <EuiDescribedFormGroup
                    fullWidth
                    descriptionFlexItemProps={{
                        style: {
                            maxWidth: '300px'
                        }
                    }}
                    title={<h3>General Info</h3>}
                    description={<Fragment>This information is required prior to publishing the exercise. </Fragment>}
                >
                    <EuiFormRow
                        label="Title"
                        isInvalid={touched.title && errors.title ? true : false}
                        error={errors.title}
                    >
                        <Field as={EuiFieldText} name="title" aria-label="title" onBlur={handleBlur('title')} />
                    </EuiFormRow>
                    <EuiFormRow
                        label="Challenge URL"
                        isInvalid={touched.challengeUrl && errors.challengeUrl ? true : false}
                        error={errors.challengeUrl}
                    >
                        <Field
                            as={EuiFieldText}
                            name="challengeUrl"
                            aria-label="url"
                            onBlur={handleBlur('challengeUrl')}
                        />
                    </EuiFormRow>
                    <EuiFormRow
                        label="Meta Description"
                        isInvalid={touched.metaDescription && errors.metaDescription ? true : false}
                        error={errors.metaDescription}
                    >
                        <Field
                            as={EuiTextArea}
                            isInvalid={touched.metaDescription && errors.metaDescription ? true : false}
                            name="metaDescription"
                            placeholder="Write a description for the exercise here."
                            onBlur={handleBlur('metaDescription')}
                        />
                    </EuiFormRow>
                    <EuiFormRow
                        label="Message"
                        hasChildLabel={false}
                        fullWidth
                        isInvalid={touched.message && errors.message ? true : false}
                        error={errors.message}
                    >
                        <ReactQuill
                            aria-label="Exercise Message form"
                            value={values.message}
                            onChange={handleChange('message')}
                            className="euiTextArea euiTextArea--resizeVertical euiTextArea--fullWidth"
                        />
                    </EuiFormRow>
                    <EuiFormRow
                        isInvalid={touched.metaKeywords && errors.metaKeywords ? true : false}
                        error={errors.metaKeywords}
                    >
                        {/* TODO: Need to refactor this MetaKeywordsComboBox and the AWSComboBox to be a single component. They are essentiially the same. */}

                        <Field
                            as={MetaKeywordsComboBox}
                            name="metaKeywords"
                            initialData={values.metaKeywords}
                            formikField="metaKeywords"
                            aria-label="A form for meta keywords."
                            onBlur={handleBlur('metaKeywords')}
                        />
                    </EuiFormRow>
                    <EuiFormRow
                        isInvalid={touched.personalizeKeywords && errors.personalizeKeywords ? true : false}
                        error={errors.personalizeKeywords}
                    >
                        <Field
                            as={AWSPersonalComboBox}
                            name="personalizeKeywords"
                            placeholder="Select or create options"
                            initialData={values.personalizeKeywords}
                            formikField="personalizeKeywords"
                            onBlur={handleBlur('personalizeKeywords')}
                        />
                    </EuiFormRow>
                    <EuiFormRow
                        label="Sort Weight"
                        helpText="The higher the number, the nearer to the top of the Challenges screen."
                        isInvalid={touched.sortWeight && errors.sortWeight ? true : false}
                        error={errors.sortWeight}
                    >
                        <EuiFieldNumber
                            name="sortWeight"
                            aria-label="A form for setting the sort weight."
                            value={values.sortWeight}
                            onChange={handleChange('sortWeight')}
                        />
                    </EuiFormRow>
                    <EuiFlexGroup style={{ maxWidth: 600, marginTop: 20 }}>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Start Date"
                                isInvalid={touched.startDate && errors.startDate ? true : false}
                                error={errors.startDate}
                            >
                                <Field
                                    as={EuiDatePicker}
                                    name="startDate"
                                    selected={values.startDate ? moment(values.startDate) : null}
                                    onChange={(date: Moment) => setFieldValue('startDate', date.format('MM/DD/YYYY'))}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="End Date"
                                isInvalid={touched.endDate && errors.endDate ? true : false}
                                error={errors.endDate}
                            >
                                <Field
                                    as={EuiDatePicker}
                                    name="endDate"
                                    selected={values.endDate ? moment(values.endDate) : null}
                                    onChange={(date: Moment) => setFieldValue('endDate', date.format('MM/DD/YYYY'))}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiFlexGroup style={{ maxWidth: 600, marginTop: 20, marginBottom: 20 }}>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Registration Close Date"
                                isInvalid={touched.closeDate && errors.closeDate ? true : false}
                                error={errors.closeDate}
                            >
                                <Field
                                    as={EuiDatePicker}
                                    name="closeDate"
                                    selected={values.closeDate ? moment(values.closeDate) : null}
                                    onChange={(date: Moment) => setFieldValue('closeDate', date.format('MM/DD/YYYY'))}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiFormRow
                                label="Maximum Registrations"
                                isInvalid={touched.maxRegistrations && errors.maxRegistrations ? true : false}
                                error={errors.maxRegistrations}
                            >
                                <Field
                                    as={EuiFieldNumber}
                                    name="maxRegistrations"
                                    value={values.maxRegistrations}
                                    onBlur={handleBlur('maxRegistrations')}
                                />
                            </EuiFormRow>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    {/* <EuiDescribedFormGroup
                        descriptionFlexItemProps={{
                            style: {
                                maxWidth: '300px'
                            }
                        }}
                        title={<h3>Is this exercise specific to a challenge?</h3>}
                        description={
                            <Fragment>
                                If yes, this will NOT have an exercise card or page in the website or mobile
                                application.
                            </Fragment>
                        }
                    >
                        <EuiFormRow
                            isInvalid={touched.challengeSpecific && errors.challengeSpecific ? true : false}
                            error={errors.challengeSpecific}
                        >
                            <Field
                                as={EuiSwitch}
                                name="challengeSpecific"
                                label={values.challengeSpecific ? 'Yes' : 'No'}
                                checked={values.challengeSpecific}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('challengeSpecific', e.target.checked)
                                }
                            />
                        </EuiFormRow>
                    </EuiDescribedFormGroup>
                    <EuiDescribedFormGroup
                        descriptionFlexItemProps={{
                            style: {
                                maxWidth: '300px'
                            }
                        }}
                        title={<h3>Is this exercise able to be used in classes?</h3>}
                        description={
                            <Fragment>If no, this will NOT be a selectable exercise when building challenges.</Fragment>
                        }
                    >
                        <EuiFormRow
                            isInvalid={touched.classEnabled && errors.classEnabled ? true : false}
                            error={errors.classEnabled}
                        >
                            <Field
                                as={EuiSwitch}
                                name="classEnabled"
                                label={values.classEnabled ? 'Yes' : 'No'}
                                checked={values.classEnabled}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('classEnabled', e.target.checked)
                                }
                            />
                        </EuiFormRow>
                    </EuiDescribedFormGroup>
                    <EuiDescribedFormGroup
                        descriptionFlexItemProps={{
                            style: {
                                maxWidth: '300px'
                            }
                        }}
                        title={<h3>Is this exercise able to be used in challenges?</h3>}
                        description={
                            <Fragment>If no, this will NOT be a selectable exercise when building challenges.</Fragment>
                        }
                    >
                        <EuiFormRow
                            isInvalid={touched.challengeEnabled && errors.challengeEnabled ? true : false}
                            error={errors.challengeEnabled}
                        >
                            <Field
                                as={EuiSwitch}
                                name="challengeEnabled"
                                label={values.challengeEnabled ? 'Yes' : 'No'}
                                checked={values.challengeEnabled}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('challengeEnabled', e.target.checked)
                                }
                            />
                        </EuiFormRow>
                    </EuiDescribedFormGroup> */}
                </EuiDescribedFormGroup>
            </EuiForm>
        </EuiPanel>
    )
}
