import React, { useState } from 'react'

import { matches } from '../../common/utils/index'

import { EuiSideNav, EuiLink } from '@elastic/eui'
import { useLocation, useNavigate } from '@reach/router'

export default function SideNav() {
    const location = useLocation()
    const navigate = useNavigate()
    const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false)

    const toggleOpenOnMobile = () => {
        setisSideNavOpenOnMobile(!isSideNavOpenOnMobile)
    }

    const sideNav = [
        {
            name: 'Home',
            id: 0,
            isSelected: location.pathname == '/',
            onClick: () => navigate('/')
        },
        {
            name: 'Fitness',
            id: 1,
            items: [
                {
                    name: 'Races',
                    id: 2,
                    isSelected: matches(location.pathname, '/races'),
                    onClick: () => navigate('/races'),
                    items: [
                        {
                            name: 'Overview',
                            id: 31,
                            isSelected: location.pathname === '/races',
                            onClick: () => navigate('/races')
                        },
                        {
                            name: 'Manage',
                            id: 32,
                            isSelected: matches(location.pathname, '/races/manage'),
                            onClick: () => navigate('/races/manage')
                        },
                        {
                            name: 'Prioritize',
                            id: 33,
                            isSelected: matches(location.pathname, '/races/prioritize'),
                            onClick: () => navigate('/races/prioritize')
                        }
                    ]
                },
                {
                    name: 'Challenges',
                    id: 3,
                    isSelected: matches(location.pathname, '/challenges'),
                    onClick: () => navigate('/challenges'),
                    items: [
                        {
                            name: 'Overview',
                            id: 31,
                            isSelected: location.pathname === '/challenges',
                            onClick: () => navigate('/challenges')
                        },
                        {
                            name: 'Manage',
                            id: 32,
                            isSelected: matches(location.pathname, '/challenges/manage'),
                            onClick: () => navigate('/challenges/manage')
                        },
                        {
                            name: 'Prioritize',
                            id: 33,
                            isSelected: matches(location.pathname, '/challenges/prioritize'),
                            onClick: () => navigate('/challenges/prioritize')
                        }
                    ]
                },
                {
                    name: 'Exercises',
                    id: 4,
                    isSelected: matches(location.pathname, '/exercises'),
                    onClick: () => navigate('/exercises'),
                    items: [
                        {
                            name: 'Overview',
                            id: 31,
                            isSelected: location.pathname === '/exercises',
                            onClick: () => navigate('/exercises')
                        },
                        {
                            name: 'Manage',
                            id: 32,
                            isSelected: matches(location.pathname, '/exercises/manage'),
                            onClick: () => navigate('/exercises/manage')
                        },
                        {
                            name: 'Prioritize',
                            id: 33,
                            isSelected: matches(location.pathname, '/exercises/prioritize'),
                            onClick: () => navigate('/exercises/prioritize')
                        }
                    ]
                },
                {
                    name: 'Trainers',
                    id: 5,
                    onClick: () => null
                },
                {
                    name: 'Trackers',
                    id: 56,
                    onClick: () => null
                }
            ]
        },
        {
            name: 'Store',
            id: 6,
            items: [
                {
                    name: 'Products',
                    id: 7,
                    isSelected: matches(location.pathname, '/products'),
                    onClick: () => navigate('/products'),
                    items: [
                        {
                            name: 'Overview',
                            id: 71,
                            isSelected: location.pathname === '/products',
                            onClick: () => navigate('/products')
                        },
                        {
                            name: 'Manage',
                            id: 72,
                            isSelected: matches(location.pathname, '/products/manage'),
                            onClick: () => navigate('/products/manage')
                        },
                        {
                            name: 'Prioritize',
                            id: 73,
                            isSelected: matches(location.pathname, '/products/prioritize'),
                            onClick: () => navigate('/products/prioritize')
                        }
                    ]
                },
                {
                    name: (
                        <EuiLink color="text" href="https://app.voucherify.io/#/login" target="_blank">
                            Gift Cards
                        </EuiLink>
                    ),
                    id: 8
                },
                {
                    name: 'Orders',
                    id: 10,
                    onClick: () => null
                },
                {
                    name: (
                        <EuiLink color="text" href="https://app.voucherify.io/#/login" target="_blank">
                            Discounts
                        </EuiLink>
                    ),
                    id: 11
                }
            ]
        },
        {
            name: 'Media Library',
            id: 12,
            items: [
                {
                    name: 'Images',
                    id: 13,
                    onClick: () => null
                },
                {
                    name: 'Videos',
                    id: 14,
                    onClick: () => null
                },
                {
                    name: (
                        <EuiLink color="text" href="https://cms.yes.fit/admin" target="_blank">
                            Carousels
                        </EuiLink>
                    ),
                    id: 15
                }
            ]
        },
        {
            name: 'Social',
            id: 16,
            items: [
                {
                    name: 'Posts',
                    id: 17,
                    onClick: () => null
                },
                {
                    name: 'Users',
                    id: 18,
                    onClick: () => null
                },
                {
                    name: 'Groups',
                    id: 19,
                    onClick: () => null
                }
            ]
        },
        {
            name: 'Website',
            id: 20,
            items: [
                {
                    name: (
                        <EuiLink color="text" href="https://yesfit.sanity.studio/" target="_blank">
                            Blog Posts
                        </EuiLink>
                    ),
                    id: 21
                },
                {
                    name: 'Pages',
                    id: 22,
                    onClick: () => null
                }
            ]
        }
    ]

    return (
        <EuiSideNav
            aria-label="Admin Side Navigation Panel"
            mobileTitle="Navigation Menu"
            toggleOpenOnMobile={() => toggleOpenOnMobile()}
            isOpenOnMobile={isSideNavOpenOnMobile}
            items={sideNav}
        />
    )
}
