import axios from '../axios'
import { ENDPOINTS } from '../constants/endpoints'

export const login = async (email: string, password: string) => {
    const loginResponse = await axios.get(ENDPOINTS.LOGIN_API, {
        params: {
            username: email,
            password: password
        }
    })

    if (loginResponse.status !== 200 || loginResponse.data?.hasError) {
        throw new Error(loginResponse.data.message)
    }

    localStorage.setItem('admin:profile', JSON.stringify(loginResponse.data))

    return loginResponse.data
}
