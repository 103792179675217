export const NewUsersGoalArray = [
    { g: 'Goal', x: '	2021-01-01	', y: 677 },
    { g: 'Goal', x: '	2021-01-02	', y: 1355 },
    { g: 'Goal', x: '	2021-01-03	', y: 2032 },
    { g: 'Goal', x: '	2021-01-04	', y: 2709 },
    { g: 'Goal', x: '	2021-01-05	', y: 3386 },
    { g: 'Goal', x: '	2021-01-06	', y: 4064 },
    { g: 'Goal', x: '	2021-01-07	', y: 4741 },
    { g: 'Goal', x: '	2021-01-08	', y: 5418 },
    { g: 'Goal', x: '	2021-01-09	', y: 6096 },
    { g: 'Goal', x: '	2021-01-10	', y: 6773 },
    { g: 'Goal', x: '	2021-01-11	', y: 7450 },
    { g: 'Goal', x: '	2021-01-12	', y: 8127 },
    { g: 'Goal', x: '	2021-01-13	', y: 8805 },
    { g: 'Goal', x: '	2021-01-14	', y: 9482 },
    { g: 'Goal', x: '	2021-01-15	', y: 10159 },
    { g: 'Goal', x: '	2021-01-16	', y: 10837 },
    { g: 'Goal', x: '	2021-01-17	', y: 11514 },
    { g: 'Goal', x: '	2021-01-18	', y: 12191 },
    { g: 'Goal', x: '	2021-01-19	', y: 12869 },
    { g: 'Goal', x: '	2021-01-20	', y: 13546 },
    { g: 'Goal', x: '	2021-01-21	', y: 14223 },
    { g: 'Goal', x: '	2021-01-22	', y: 14900 },
    { g: 'Goal', x: '	2021-01-23	', y: 15578 },
    { g: 'Goal', x: '	2021-01-24	', y: 16255 },
    { g: 'Goal', x: '	2021-01-25	', y: 16932 },
    { g: 'Goal', x: '	2021-01-26	', y: 17610 },
    { g: 'Goal', x: '	2021-01-27	', y: 18287 },
    { g: 'Goal', x: '	2021-01-28	', y: 18964 },
    { g: 'Goal', x: '	2021-01-29	', y: 19641 },
    { g: 'Goal', x: '	2021-01-30	', y: 20319 },
    { g: 'Goal', x: '	2021-01-31	', y: 20996 },
    { g: 'Goal', x: '	2021-02-01	', y: 21642 },
    { g: 'Goal', x: '	2021-02-02	', y: 22288 },
    { g: 'Goal', x: '	2021-02-03	', y: 22934 },
    { g: 'Goal', x: '	2021-02-04	', y: 23579 },
    { g: 'Goal', x: '	2021-02-05	', y: 24225 },
    { g: 'Goal', x: '	2021-02-06	', y: 24871 },
    { g: 'Goal', x: '	2021-02-07	', y: 25517 },
    { g: 'Goal', x: '	2021-02-08	', y: 26163 },
    { g: 'Goal', x: '	2021-02-09	', y: 26809 },
    { g: 'Goal', x: '	2021-02-10	', y: 27455 },
    { g: 'Goal', x: '	2021-02-11	', y: 28100 },
    { g: 'Goal', x: '	2021-02-12	', y: 28746 },
    { g: 'Goal', x: '	2021-02-13	', y: 29392 },
    { g: 'Goal', x: '	2021-02-14	', y: 30038 },
    { g: 'Goal', x: '	2021-02-15	', y: 30684 },
    { g: 'Goal', x: '	2021-02-16	', y: 31330 },
    { g: 'Goal', x: '	2021-02-17	', y: 31976 },
    { g: 'Goal', x: '	2021-02-18	', y: 32622 },
    { g: 'Goal', x: '	2021-02-19	', y: 33267 },
    { g: 'Goal', x: '	2021-02-20	', y: 33913 },
    { g: 'Goal', x: '	2021-02-21	', y: 34559 },
    { g: 'Goal', x: '	2021-02-22	', y: 35205 },
    { g: 'Goal', x: '	2021-02-23	', y: 35851 },
    { g: 'Goal', x: '	2021-02-24	', y: 36497 },
    { g: 'Goal', x: '	2021-02-25	', y: 37143 },
    { g: 'Goal', x: '	2021-02-26	', y: 37788 },
    { g: 'Goal', x: '	2021-02-27	', y: 38434 },
    { g: 'Goal', x: '	2021-02-28	', y: 39080 },
    { g: 'Goal', x: '	2021-03-01	', y: 39895 },
    { g: 'Goal', x: '	2021-03-02	', y: 40710 },
    { g: 'Goal', x: '	2021-03-03	', y: 41524 },
    { g: 'Goal', x: '	2021-03-04	', y: 42339 },
    { g: 'Goal', x: '	2021-03-05	', y: 43154 },
    { g: 'Goal', x: '	2021-03-06	', y: 43969 },
    { g: 'Goal', x: '	2021-03-07	', y: 44783 },
    { g: 'Goal', x: '	2021-03-08	', y: 45598 },
    { g: 'Goal', x: '	2021-03-09	', y: 46413 },
    { g: 'Goal', x: '	2021-03-10	', y: 47227 },
    { g: 'Goal', x: '	2021-03-11	', y: 48042 },
    { g: 'Goal', x: '	2021-03-12	', y: 48857 },
    { g: 'Goal', x: '	2021-03-13	', y: 49672 },
    { g: 'Goal', x: '	2021-03-14	', y: 50486 },
    { g: 'Goal', x: '	2021-03-15	', y: 51301 },
    { g: 'Goal', x: '	2021-03-16	', y: 52116 },
    { g: 'Goal', x: '	2021-03-17	', y: 52930 },
    { g: 'Goal', x: '	2021-03-18	', y: 53745 },
    { g: 'Goal', x: '	2021-03-19	', y: 54560 },
    { g: 'Goal', x: '	2021-03-20	', y: 55375 },
    { g: 'Goal', x: '	2021-03-21	', y: 56189 },
    { g: 'Goal', x: '	2021-03-22	', y: 57004 },
    { g: 'Goal', x: '	2021-03-23	', y: 57819 },
    { g: 'Goal', x: '	2021-03-24	', y: 58634 },
    { g: 'Goal', x: '	2021-03-25	', y: 59448 },
    { g: 'Goal', x: '	2021-03-26	', y: 60263 },
    { g: 'Goal', x: '	2021-03-27	', y: 61078 },
    { g: 'Goal', x: '	2021-03-28	', y: 61892 },
    { g: 'Goal', x: '	2021-03-29	', y: 62707 },
    { g: 'Goal', x: '	2021-03-30	', y: 63522 },
    { g: 'Goal', x: '	2021-03-31	', y: 64337 },
    { g: 'Goal', x: '	2021-04-01	', y: 65410 },
    { g: 'Goal', x: '	2021-04-02	', y: 66484 },
    { g: 'Goal', x: '	2021-04-03	', y: 67558 },
    { g: 'Goal', x: '	2021-04-04	', y: 68632 },
    { g: 'Goal', x: '	2021-04-05	', y: 69706 },
    { g: 'Goal', x: '	2021-04-06	', y: 70780 },
    { g: 'Goal', x: '	2021-04-07	', y: 71853 },
    { g: 'Goal', x: '	2021-04-08	', y: 72927 },
    { g: 'Goal', x: '	2021-04-09	', y: 74001 },
    { g: 'Goal', x: '	2021-04-10	', y: 75075 },
    { g: 'Goal', x: '	2021-04-11	', y: 76149 },
    { g: 'Goal', x: '	2021-04-12	', y: 77222 },
    { g: 'Goal', x: '	2021-04-13	', y: 78296 },
    { g: 'Goal', x: '	2021-04-14	', y: 79370 },
    { g: 'Goal', x: '	2021-04-15	', y: 80444 },
    { g: 'Goal', x: '	2021-04-16	', y: 81518 },
    { g: 'Goal', x: '	2021-04-17	', y: 82591 },
    { g: 'Goal', x: '	2021-04-18	', y: 83665 },
    { g: 'Goal', x: '	2021-04-19	', y: 84739 },
    { g: 'Goal', x: '	2021-04-20	', y: 85813 },
    { g: 'Goal', x: '	2021-04-21	', y: 86887 },
    { g: 'Goal', x: '	2021-04-22	', y: 87960 },
    { g: 'Goal', x: '	2021-04-23	', y: 89034 },
    { g: 'Goal', x: '	2021-04-24	', y: 90108 },
    { g: 'Goal', x: '	2021-04-25	', y: 91182 },
    { g: 'Goal', x: '	2021-04-26	', y: 92256 },
    { g: 'Goal', x: '	2021-04-27	', y: 93330 },
    { g: 'Goal', x: '	2021-04-28	', y: 94403 },
    { g: 'Goal', x: '	2021-04-29	', y: 95477 },
    { g: 'Goal', x: '	2021-04-30	', y: 96551 },
    { g: 'Goal', x: '	2021-05-01	', y: 97478 },
    { g: 'Goal', x: '	2021-05-02	', y: 98406 },
    { g: 'Goal', x: '	2021-05-03	', y: 99333 },
    { g: 'Goal', x: '	2021-05-04	', y: 100261 },
    { g: 'Goal', x: '	2021-05-05	', y: 101188 },
    { g: 'Goal', x: '	2021-05-06	', y: 102116 },
    { g: 'Goal', x: '	2021-05-07	', y: 103043 },
    { g: 'Goal', x: '	2021-05-08	', y: 103971 },
    { g: 'Goal', x: '	2021-05-09	', y: 104898 },
    { g: 'Goal', x: '	2021-05-10	', y: 105825 },
    { g: 'Goal', x: '	2021-05-11	', y: 106753 },
    { g: 'Goal', x: '	2021-05-12	', y: 107680 },
    { g: 'Goal', x: '	2021-05-13	', y: 108608 },
    { g: 'Goal', x: '	2021-05-14	', y: 109535 },
    { g: 'Goal', x: '	2021-05-15	', y: 110463 },
    { g: 'Goal', x: '	2021-05-16	', y: 111390 },
    { g: 'Goal', x: '	2021-05-17	', y: 112317 },
    { g: 'Goal', x: '	2021-05-18	', y: 113245 },
    { g: 'Goal', x: '	2021-05-19	', y: 114172 },
    { g: 'Goal', x: '	2021-05-20	', y: 115100 },
    { g: 'Goal', x: '	2021-05-21	', y: 116027 },
    { g: 'Goal', x: '	2021-05-22	', y: 116955 },
    { g: 'Goal', x: '	2021-05-23	', y: 117882 },
    { g: 'Goal', x: '	2021-05-24	', y: 118810 },
    { g: 'Goal', x: '	2021-05-25	', y: 119737 },
    { g: 'Goal', x: '	2021-05-26	', y: 120664 },
    { g: 'Goal', x: '	2021-05-27	', y: 121592 },
    { g: 'Goal', x: '	2021-05-28	', y: 122519 },
    { g: 'Goal', x: '	2021-05-29	', y: 123447 },
    { g: 'Goal', x: '	2021-05-30	', y: 124374 },
    { g: 'Goal', x: '	2021-05-31	', y: 125302 },
    { g: 'Goal', x: '	2021-06-01	', y: 126113 },
    { g: 'Goal', x: '	2021-06-02	', y: 126924 },
    { g: 'Goal', x: '	2021-06-03	', y: 127735 },
    { g: 'Goal', x: '	2021-06-04	', y: 128547 },
    { g: 'Goal', x: '	2021-06-05	', y: 129358 },
    { g: 'Goal', x: '	2021-06-06	', y: 130169 },
    { g: 'Goal', x: '	2021-06-07	', y: 130980 },
    { g: 'Goal', x: '	2021-06-08	', y: 131792 },
    { g: 'Goal', x: '	2021-06-09	', y: 132603 },
    { g: 'Goal', x: '	2021-06-10	', y: 133414 },
    { g: 'Goal', x: '	2021-06-11	', y: 134225 },
    { g: 'Goal', x: '	2021-06-12	', y: 135036 },
    { g: 'Goal', x: '	2021-06-13	', y: 135848 },
    { g: 'Goal', x: '	2021-06-14	', y: 136659 },
    { g: 'Goal', x: '	2021-06-15	', y: 137470 },
    { g: 'Goal', x: '	2021-06-16	', y: 138281 },
    { g: 'Goal', x: '	2021-06-17	', y: 139093 },
    { g: 'Goal', x: '	2021-06-18	', y: 139904 },
    { g: 'Goal', x: '	2021-06-19	', y: 140715 },
    { g: 'Goal', x: '	2021-06-20	', y: 141526 },
    { g: 'Goal', x: '	2021-06-21	', y: 142338 },
    { g: 'Goal', x: '	2021-06-22	', y: 143149 },
    { g: 'Goal', x: '	2021-06-23	', y: 143960 },
    { g: 'Goal', x: '	2021-06-24	', y: 144771 },
    { g: 'Goal', x: '	2021-06-25	', y: 145583 },
    { g: 'Goal', x: '	2021-06-26	', y: 146394 },
    { g: 'Goal', x: '	2021-06-27	', y: 147205 },
    { g: 'Goal', x: '	2021-06-28	', y: 148016 },
    { g: 'Goal', x: '	2021-06-29	', y: 148827 },
    { g: 'Goal', x: '	2021-06-30	', y: 149639 },
    { g: 'Goal', x: '	2021-07-01	', y: 150623 },
    { g: 'Goal', x: '	2021-07-02	', y: 151608 },
    { g: 'Goal', x: '	2021-07-03	', y: 152593 },
    { g: 'Goal', x: '	2021-07-04	', y: 153578 },
    { g: 'Goal', x: '	2021-07-05	', y: 154563 },
    { g: 'Goal', x: '	2021-07-06	', y: 155547 },
    { g: 'Goal', x: '	2021-07-07	', y: 156532 },
    { g: 'Goal', x: '	2021-07-08	', y: 157517 },
    { g: 'Goal', x: '	2021-07-09	', y: 158502 },
    { g: 'Goal', x: '	2021-07-10	', y: 159487 },
    { g: 'Goal', x: '	2021-07-11	', y: 160471 },
    { g: 'Goal', x: '	2021-07-12	', y: 161456 },
    { g: 'Goal', x: '	2021-07-13	', y: 162441 },
    { g: 'Goal', x: '	2021-07-14	', y: 163426 },
    { g: 'Goal', x: '	2021-07-15	', y: 164411 },
    { g: 'Goal', x: '	2021-07-16	', y: 165395 },
    { g: 'Goal', x: '	2021-07-17	', y: 166380 },
    { g: 'Goal', x: '	2021-07-18	', y: 167365 },
    { g: 'Goal', x: '	2021-07-19	', y: 168350 },
    { g: 'Goal', x: '	2021-07-20	', y: 169334 },
    { g: 'Goal', x: '	2021-07-21	', y: 170319 },
    { g: 'Goal', x: '	2021-07-22	', y: 171304 },
    { g: 'Goal', x: '	2021-07-23	', y: 172289 },
    { g: 'Goal', x: '	2021-07-24	', y: 173274 },
    { g: 'Goal', x: '	2021-07-25	', y: 174258 },
    { g: 'Goal', x: '	2021-07-26	', y: 175243 },
    { g: 'Goal', x: '	2021-07-27	', y: 176228 },
    { g: 'Goal', x: '	2021-07-28	', y: 177213 },
    { g: 'Goal', x: '	2021-07-29	', y: 178198 },
    { g: 'Goal', x: '	2021-07-30	', y: 179182 },
    { g: 'Goal', x: '	2021-07-31	', y: 180167 },
    { g: 'Goal', x: '	2021-08-01	', y: 181107 },
    { g: 'Goal', x: '	2021-08-02	', y: 182048 },
    { g: 'Goal', x: '	2021-08-03	', y: 182988 },
    { g: 'Goal', x: '	2021-08-04	', y: 183928 },
    { g: 'Goal', x: '	2021-08-05	', y: 184869 },
    { g: 'Goal', x: '	2021-08-06	', y: 185809 },
    { g: 'Goal', x: '	2021-08-07	', y: 186749 },
    { g: 'Goal', x: '	2021-08-08	', y: 187690 },
    { g: 'Goal', x: '	2021-08-09	', y: 188630 },
    { g: 'Goal', x: '	2021-08-10	', y: 189570 },
    { g: 'Goal', x: '	2021-08-11	', y: 190510 },
    { g: 'Goal', x: '	2021-08-12	', y: 191451 },
    { g: 'Goal', x: '	2021-08-13	', y: 192391 },
    { g: 'Goal', x: '	2021-08-14	', y: 193331 },
    { g: 'Goal', x: '	2021-08-15	', y: 194272 },
    { g: 'Goal', x: '	2021-08-16	', y: 195212 },
    { g: 'Goal', x: '	2021-08-17	', y: 196152 },
    { g: 'Goal', x: '	2021-08-18	', y: 197093 },
    { g: 'Goal', x: '	2021-08-19	', y: 198033 },
    { g: 'Goal', x: '	2021-08-20	', y: 198973 },
    { g: 'Goal', x: '	2021-08-21	', y: 199913 },
    { g: 'Goal', x: '	2021-08-22	', y: 200854 },
    { g: 'Goal', x: '	2021-08-23	', y: 201794 },
    { g: 'Goal', x: '	2021-08-24	', y: 202734 },
    { g: 'Goal', x: '	2021-08-25	', y: 203675 },
    { g: 'Goal', x: '	2021-08-26	', y: 204615 },
    { g: 'Goal', x: '	2021-08-27	', y: 205555 },
    { g: 'Goal', x: '	2021-08-28	', y: 206495 },
    { g: 'Goal', x: '	2021-08-29	', y: 207436 },
    { g: 'Goal', x: '	2021-08-30	', y: 208376 },
    { g: 'Goal', x: '	2021-08-31	', y: 209316 },
    { g: 'Goal', x: '	2021-09-01	', y: 210124 },
    { g: 'Goal', x: '	2021-09-02	', y: 210931 },
    { g: 'Goal', x: '	2021-09-03	', y: 211738 },
    { g: 'Goal', x: '	2021-09-04	', y: 212545 },
    { g: 'Goal', x: '	2021-09-05	', y: 213352 },
    { g: 'Goal', x: '	2021-09-06	', y: 214159 },
    { g: 'Goal', x: '	2021-09-07	', y: 214966 },
    { g: 'Goal', x: '	2021-09-08	', y: 215774 },
    { g: 'Goal', x: '	2021-09-09	', y: 216581 },
    { g: 'Goal', x: '	2021-09-10	', y: 217388 },
    { g: 'Goal', x: '	2021-09-11	', y: 218195 },
    { g: 'Goal', x: '	2021-09-12	', y: 219002 },
    { g: 'Goal', x: '	2021-09-13	', y: 219809 },
    { g: 'Goal', x: '	2021-09-14	', y: 220616 },
    { g: 'Goal', x: '	2021-09-15	', y: 221424 },
    { g: 'Goal', x: '	2021-09-16	', y: 222231 },
    { g: 'Goal', x: '	2021-09-17	', y: 223038 },
    { g: 'Goal', x: '	2021-09-18	', y: 223845 },
    { g: 'Goal', x: '	2021-09-19	', y: 224652 },
    { g: 'Goal', x: '	2021-09-20	', y: 225459 },
    { g: 'Goal', x: '	2021-09-21	', y: 226266 },
    { g: 'Goal', x: '	2021-09-22	', y: 227074 },
    { g: 'Goal', x: '	2021-09-23	', y: 227881 },
    { g: 'Goal', x: '	2021-09-24	', y: 228688 },
    { g: 'Goal', x: '	2021-09-25	', y: 229495 },
    { g: 'Goal', x: '	2021-09-26	', y: 230302 },
    { g: 'Goal', x: '	2021-09-27	', y: 231109 },
    { g: 'Goal', x: '	2021-09-28	', y: 231916 },
    { g: 'Goal', x: '	2021-09-29	', y: 232724 },
    { g: 'Goal', x: '	2021-09-30	', y: 233531 },
    { g: 'Goal', x: '	2021-10-01	', y: 234647 },
    { g: 'Goal', x: '	2021-10-02	', y: 235763 },
    { g: 'Goal', x: '	2021-10-03	', y: 236880 },
    { g: 'Goal', x: '	2021-10-04	', y: 237996 },
    { g: 'Goal', x: '	2021-10-05	', y: 239112 },
    { g: 'Goal', x: '	2021-10-06	', y: 240228 },
    { g: 'Goal', x: '	2021-10-07	', y: 241345 },
    { g: 'Goal', x: '	2021-10-08	', y: 242461 },
    { g: 'Goal', x: '	2021-10-09	', y: 243577 },
    { g: 'Goal', x: '	2021-10-10	', y: 244694 },
    { g: 'Goal', x: '	2021-10-11	', y: 245810 },
    { g: 'Goal', x: '	2021-10-12	', y: 246926 },
    { g: 'Goal', x: '	2021-10-13	', y: 248043 },
    { g: 'Goal', x: '	2021-10-14	', y: 249159 },
    { g: 'Goal', x: '	2021-10-15	', y: 250275 },
    { g: 'Goal', x: '	2021-10-16	', y: 251391 },
    { g: 'Goal', x: '	2021-10-17	', y: 252508 },
    { g: 'Goal', x: '	2021-10-18	', y: 253624 },
    { g: 'Goal', x: '	2021-10-19	', y: 254740 },
    { g: 'Goal', x: '	2021-10-20	', y: 255857 },
    { g: 'Goal', x: '	2021-10-21	', y: 256973 },
    { g: 'Goal', x: '	2021-10-22	', y: 258089 },
    { g: 'Goal', x: '	2021-10-23	', y: 259205 },
    { g: 'Goal', x: '	2021-10-24	', y: 260322 },
    { g: 'Goal', x: '	2021-10-25	', y: 261438 },
    { g: 'Goal', x: '	2021-10-26	', y: 262554 },
    { g: 'Goal', x: '	2021-10-27	', y: 263671 },
    { g: 'Goal', x: '	2021-10-28	', y: 264787 },
    { g: 'Goal', x: '	2021-10-29	', y: 265903 },
    { g: 'Goal', x: '	2021-10-30	', y: 267020 },
    { g: 'Goal', x: '	2021-10-31	', y: 268136 },
    { g: 'Goal', x: '	2021-11-01	', y: 268972 },
    { g: 'Goal', x: '	2021-11-02	', y: 269807 },
    { g: 'Goal', x: '	2021-11-03	', y: 270643 },
    { g: 'Goal', x: '	2021-11-04	', y: 271479 },
    { g: 'Goal', x: '	2021-11-05	', y: 272315 },
    { g: 'Goal', x: '	2021-11-06	', y: 273150 },
    { g: 'Goal', x: '	2021-11-07	', y: 273986 },
    { g: 'Goal', x: '	2021-11-08	', y: 274822 },
    { g: 'Goal', x: '	2021-11-09	', y: 275658 },
    { g: 'Goal', x: '	2021-11-10	', y: 276493 },
    { g: 'Goal', x: '	2021-11-11	', y: 277329 },
    { g: 'Goal', x: '	2021-11-12	', y: 278165 },
    { g: 'Goal', x: '	2021-11-13	', y: 279001 },
    { g: 'Goal', x: '	2021-11-14	', y: 279836 },
    { g: 'Goal', x: '	2021-11-15	', y: 280672 },
    { g: 'Goal', x: '	2021-11-16	', y: 281508 },
    { g: 'Goal', x: '	2021-11-17	', y: 282344 },
    { g: 'Goal', x: '	2021-11-18	', y: 283179 },
    { g: 'Goal', x: '	2021-11-19	', y: 284015 },
    { g: 'Goal', x: '	2021-11-20	', y: 284851 },
    { g: 'Goal', x: '	2021-11-21	', y: 285687 },
    { g: 'Goal', x: '	2021-11-22	', y: 286522 },
    { g: 'Goal', x: '	2021-11-23	', y: 287358 },
    { g: 'Goal', x: '	2021-11-24	', y: 288194 },
    { g: 'Goal', x: '	2021-11-25	', y: 289030 },
    { g: 'Goal', x: '	2021-11-26	', y: 289865 },
    { g: 'Goal', x: '	2021-11-27	', y: 290701 },
    { g: 'Goal', x: '	2021-11-28	', y: 291537 },
    { g: 'Goal', x: '	2021-11-29	', y: 292373 },
    { g: 'Goal', x: '	2021-11-30	', y: 293208 },
    { g: 'Goal', x: '	2021-12-01	', y: 293638 },
    { g: 'Goal', x: '	2021-12-02	', y: 294067 },
    { g: 'Goal', x: '	2021-12-03	', y: 294496 },
    { g: 'Goal', x: '	2021-12-04	', y: 294925 },
    { g: 'Goal', x: '	2021-12-05	', y: 295354 },
    { g: 'Goal', x: '	2021-12-06	', y: 295783 },
    { g: 'Goal', x: '	2021-12-07	', y: 296212 },
    { g: 'Goal', x: '	2021-12-08	', y: 296641 },
    { g: 'Goal', x: '	2021-12-09	', y: 297070 },
    { g: 'Goal', x: '	2021-12-10	', y: 297500 },
    { g: 'Goal', x: '	2021-12-11	', y: 297929 },
    { g: 'Goal', x: '	2021-12-12	', y: 298358 },
    { g: 'Goal', x: '	2021-12-13	', y: 298787 },
    { g: 'Goal', x: '	2021-12-14	', y: 299216 },
    { g: 'Goal', x: '	2021-12-15	', y: 299645 },
    { g: 'Goal', x: '	2021-12-16	', y: 300074 },
    { g: 'Goal', x: '	2021-12-17	', y: 300503 },
    { g: 'Goal', x: '	2021-12-18	', y: 300933 },
    { g: 'Goal', x: '	2021-12-19	', y: 301362 },
    { g: 'Goal', x: '	2021-12-20	', y: 301791 },
    { g: 'Goal', x: '	2021-12-21	', y: 302220 },
    { g: 'Goal', x: '	2021-12-22	', y: 302649 },
    { g: 'Goal', x: '	2021-12-23	', y: 303078 },
    { g: 'Goal', x: '	2021-12-24	', y: 303507 },
    { g: 'Goal', x: '	2021-12-25	', y: 303936 },
    { g: 'Goal', x: '	2021-12-26	', y: 304365 },
    { g: 'Goal', x: '	2021-12-27	', y: 304795 },
    { g: 'Goal', x: '	2021-12-28	', y: 305224 },
    { g: 'Goal', x: '	2021-12-29	', y: 305653 },
    { g: 'Goal', x: '	2021-12-30	', y: 306082 },
    { g: 'Goal', x: '	2021-12-31	', y: 306511 }
]
