import axios from 'axios'
import moment from 'moment'

export const fetchSingleRaceViews = async (challengeId: any) => {
    const res = await axios.get(`https://old-tree-7434.fly.dev/all-views/${challengeId}`)

    const parsedWebViews = res.data?.allWebViews?.map(
        (view: { day: moment.MomentInput; product_id: any; count: any; context_campaign_source: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: view.context_campaign_source ? `🕸 - ${view.context_campaign_source}` : '🕸 - no_utm_source',
                y: view.count
            }
        }
    )

    const parsedMobileViews = res.data?.allMobileViews?.map(
        (view: { day: moment.MomentInput; product_id: any; count: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '📱',
                y: view.count
            }
        }
    )

    return { views: [...parsedMobileViews, ...parsedWebViews] }
}
