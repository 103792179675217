import axios from 'axios'
import moment from 'moment'

export const fetchAllJoins = async () => {
    const res = await axios.get('https://old-tree-7434.fly.dev/all-joins')

    const parsedWebViews = res.data?.webJoins?.map((view: { day: moment.MomentInput; '?column?': any; count: any }) => {
        return {
            x: moment(view.day).toISOString(),
            g: view['?column?'],
            y: view.count
        }
    })

    const parsedMobileViews = res.data?.mobileJoins?.map(
        (view: { day: moment.MomentInput; '?column?': any; count: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: view['?column?'],
                y: view.count
            }
        }
    )

    return { webJoins: parsedWebViews, mobileJoins: parsedMobileViews }
}
