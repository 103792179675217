import React from 'react'
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiProgress,
    EuiSpacer,
    EuiSplitPanel,
    EuiStat,
    EuiText,
    EuiTitle
} from '@elastic/eui'
import { Chart, Settings, Axis, BarSeries, ScaleType, Position } from '@elastic/charts'
import { MockChart } from '../MockChart'
import { NewUsersGoalArray } from './data/newusergoal'
import moment from 'moment'
import { NewUsersArray } from './data/newusers'
import { useQuery } from 'react-query'
import { fetchRegistrations } from '../../../../API/analytics/fetchRegistrations'
import { fetchLPRegistrations } from '../../../../API/analytics/fetchLPRegistrations'
import { fetchRegistrationsByRef } from '../../../../API/analytics/fetchRegistrationsByRef'

const parsedGoal = NewUsersGoalArray.map((item) => {
    return {
        x: moment(item.x.trim()).toISOString(),
        g: item.g,
        y: item.y
    }
})

const parsed = NewUsersArray.map((item) => {
    return {
        x: moment(item.x.trim()).dayOfYear(),
        g: item.g,
        y: item.y
    }
})

export function NewUsersPanel() {
    const { data, isLoading } = useQuery('registers', fetchRegistrations)
    const { data: lpregistrationsData, isLoading: LPisLoading } = useQuery('lpregistrations', fetchLPRegistrations)
    const { data: refRegistrationsData, isLoading: RefisLoading } = useQuery(
        'refregistrations',
        fetchRegistrationsByRef
    )

    return (
        <>
            {isLoading && <EuiProgress size="xs" color="primary" />}
            <EuiPanel>
                <EuiSplitPanel.Outer hasShadow={false} direction="row" responsive={['xs', 's', 'm']}>
                    <EuiSplitPanel.Inner>
                        <EuiTitle size="l">
                            <h1>New Users</h1>
                        </EuiTitle>

                        <EuiSpacer size="m" />

                        <EuiTitle>
                            <h3>New Registrations by Registration Type</h3>
                        </EuiTitle>

                        {!isLoading && data?.registrations ? (
                            <MockChart data={[...data.registrations]} height={350} />
                        ) : null}
                        {/* <EuiSpacer size="m" />
                        <EuiTitle>
                            <h3>New registrations by landing page</h3>
                        </EuiTitle>
                        {!LPisLoading && lpregistrationsData?.registrations ? (
                            <MockChart data={[...lpregistrationsData.registrations]} height={350} />
                        ) : null} */}
                        <EuiSpacer size="m" />
                        <EuiTitle>
                            <h3>New Web Registrations by Referrer Page</h3>
                        </EuiTitle>

                        {!RefisLoading && refRegistrationsData?.registrations ? (
                            <Chart
                                size={{
                                    width: '100%',
                                    height: 350
                                }}
                            >
                                <Settings showLegend showLegendExtra legendPosition={Position.Right} />
                                <Axis
                                    id="bottom"
                                    position={Position.Bottom}
                                    showOverlappingTicks
                                    tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                                />
                                <Axis
                                    id="left2"
                                    position={Position.Left}
                                    tickFormat={(d: any) => Number(d).toFixed(2)}
                                />

                                <BarSeries
                                    id="bars"
                                    xScaleType={ScaleType.Linear}
                                    yScaleType={ScaleType.Linear}
                                    xAccessor="x"
                                    yAccessors={['y']}
                                    stackAccessors={['x']}
                                    splitSeriesAccessors={['g']}
                                    data={refRegistrationsData.registrations}
                                />
                            </Chart>
                        ) : null}
                    </EuiSplitPanel.Inner>
                </EuiSplitPanel.Outer>
            </EuiPanel>
        </>
    )
}
