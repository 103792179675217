import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { EventListDataGrid } from 'components/EventListDataGrid'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { FC } from 'react'

type ProductManagePageProps = RouteComponentProps

export const ProductManagePage: FC<ProductManagePageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.SWAG }}>
            <EventListDataGrid eventType={EVENT_TYPE.SWAG} />
        </AdminPageLayout>
    )
}
