import { ENDPOINTS } from 'common/constants/endpoints'
import axios from '../common/axios'

export type ChallengeImgTypeRes = ImageType[]

export type ImageType = {
    value: number
    description: string
}

export const fetchChallengeImageTypes = async () => {
    const challengeImgTypesRes = await axios.get(ENDPOINTS.IMAGE_TYPES_API)

    if (challengeImgTypesRes.status !== 200 || challengeImgTypesRes.data?.hasError) {
        throw new Error(challengeImgTypesRes.data.message)
    }

    const data: ChallengeImgTypeRes = challengeImgTypesRes.data

    return data
}
