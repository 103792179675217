import React, { FC, useState } from 'react'
import {
    EuiButton,
    EuiButtonIcon,
    EuiComboBox,
    EuiForm,
    EuiFormRow,
    EuiPopover,
    EuiRadioGroup,
    EuiSelect,
    EuiSpacer,
    EuiSwitch
} from '@elastic/eui'
import axios from 'common/axios'
import { EVENT_TYPE } from 'common/constants/eventType'
import { useQueryClient } from 'react-query'

type EditLanePopoverProps = {
    idx: number
    lane: any
    eventType: any
}

export const EditLanePopover: FC<EditLanePopoverProps> = ({ idx, lane, eventType }) => {
    const queryClient = useQueryClient()
    const [isEditPopoverOpen, setIsEditPopoverOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    // Edit Popover
    const onButtonClick = () => {
        setIsEditPopoverOpen(!isEditPopoverOpen)
    }

    const deleteLane = async () => {
        setIsDeleting(true)
        await axios.post('/api/v1/admin/DiscoverEventList', {
            ...lane,
            isDelete: true
        })
        setIsDeleting(false)
        queryClient.invalidateQueries(['lanes', eventType])
    }

    return (
        <EuiPopover
            id={`formPopover-${idx}`}
            button={<EuiButtonIcon onClick={onButtonClick} display="fill" size="s" iconType="gear" />}
            isOpen={isEditPopoverOpen}
            closePopover={() => setIsEditPopoverOpen(false)}
            initialFocus={`[name='popfirst-${idx}']`}
        >
            <div style={{ width: '300px' }}>
                <EuiForm component="form">
                    {/* <EuiFormRow>
                        <EuiSwitch checked={true} onChange={() => null} name="popswitch" label="Web" />
                    </EuiFormRow>

                    <EuiFormRow>
                        <EuiSwitch checked={false} onChange={() => null} name="popswitch" label="Mobile" />
                    </EuiFormRow>

                    <EuiFormRow label="Sorted By">
                        <EuiSelect
                            id="sortedByField"
                            options={[
                                { value: 'wtd_views', text: 'WTD Views' },
                                { value: 'mtd_views', text: 'MTD Views' },
                                { value: 'chronological', text: 'Chronological' },
                                { value: 'manual', text: 'Manual' },
                                { value: 'clicks', text: 'Clicks' },
                                { value: 'aws_ml', text: 'AWS ML' }
                            ]}
                            value={''}
                            onChange={() => null}
                            aria-label="Use aria labels when no actual label is in use"
                        />
                    </EuiFormRow>

                    <EuiFormRow label="Category Restriction">
                        <EuiComboBox
                            placeholder="Select category"
                            options={[{ label: 'Abs' }, { label: 'Cardio' }, { label: 'Core' }]}
                            selectedOptions={[{ label: 'Cardio' }]}
                            onChange={() => null}
                            isClearable={true}
                            data-test-subj="demoComboBox"
                        />
                    </EuiFormRow>

                    <EuiFormRow label="Card Type/Size">
                        <EuiRadioGroup
                            options={[
                                {
                                    id: `0`,
                                    label: 'Standard'
                                },
                                {
                                    id: `1`,
                                    label: 'Featured'
                                }
                            ]}
                            idSelected={'1'}
                            onChange={() => null}
                            name="card size/type radio group"
                        />
                    </EuiFormRow>

                    <EuiSpacer />
                    <EuiButton fullWidth>Save</EuiButton>
                    <EuiSpacer size="s" /> */}
                    <EuiButton
                        onClick={() => deleteLane()}
                        isLoading={isDeleting}
                        disabled={isDeleting}
                        color="danger"
                        fullWidth
                    >
                        Delete
                    </EuiButton>
                </EuiForm>
            </div>
        </EuiPopover>
    )
}
