import axios from 'axios'
import moment from 'moment'

export const fetchStripeCharges = async () => {
    const res = await axios.get(`https://old-tree-7434.fly.dev/stripe/charges`)

    const parsedCharges = res.data?.charges?.map((view: { day: moment.MomentInput; round: any; description: any }) => {
        return {
            x: moment(view.day).toISOString(),
            g: view.description,
            y: view.round
        }
    })

    return { charges: parsedCharges }
}
