/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react'

import { EuiComboBox, EuiFormRow } from '@elastic/eui'
import { FormikValues, useFormikContext } from 'formik'

const isValid = (value: string) => {
    // Only allow letters. No spaces, numbers, or special characters.
    return value.match(/^[a-z A-Z 1-10]+$/) !== null
}

type MetaKeywordsComboBoxProps = {
    initialData: string | null
    formikField?: string
}

export const MetaKeywordsComboBox: FC<MetaKeywordsComboBoxProps> = ({ initialData, formikField }) => {
    const [selectedOptions, setSelected] = useState<Array<any>>([])
    const [isInvalid, setInvalid] = useState(false)
    const { setFieldValue } = useFormikContext<FormikValues>()

    const parseSelected = (options: typeof selectedOptions) => {
        return options.map((option) => option.label).toString()
    }

    useEffect(() => {
        const initialOptionsArr = initialData?.split(',')
        if (initialOptionsArr?.length) {
            const initialSelectedOptions = initialOptionsArr
                .filter((i) => i.length)
                .map((option) => {
                    return {
                        label: option
                    }
                })

            setSelected(initialSelectedOptions)
        }
    }, [initialData])

    const onCreateOption = (searchValue: string) => {
        if (!isValid(searchValue)) {
            // Return false to explicitly reject the user's input.
            return false
        }

        const newOption = {
            label: searchValue
        }

        // Select the option.
        setSelected([...selectedOptions, newOption])
        formikField && setFieldValue(formikField, parseSelected([...selectedOptions, newOption]))
    }

    const onSearchChange = (searchValue: string) => {
        if (!searchValue) {
            setInvalid(false)

            return
        }

        setInvalid(!isValid(searchValue))
    }

    const onChange = (selectedOptions: any) => {
        setSelected(selectedOptions)
        setInvalid(false)
        formikField && setFieldValue(formikField, parseSelected(selectedOptions))
    }

    return (
        <EuiFormRow
            fullWidth
            label="Meta Keywords"
            helpText={<span>Use these keywords to describe important criterion.</span>}
            isInvalid={isInvalid}
            error={isInvalid ? 'Only letters are allowed' : undefined}
        >
            <EuiComboBox
                fullWidth
                noSuggestions
                selectedOptions={selectedOptions}
                onCreateOption={onCreateOption}
                onChange={onChange}
                onSearchChange={onSearchChange}
                isInvalid={isInvalid}
            />
        </EuiFormRow>
    )
}
