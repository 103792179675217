import { EuiFilePicker, EuiImage, EuiDescribedFormGroup, EuiText, EuiSpacer } from '@elastic/eui'
import { EVENT_TYPE } from 'common/constants/eventType'
import ReactPlayer from 'react-player'
import { IAssetUploadProps } from './AddAssetFlyout'
import React from 'react'

// eslint-disable-next-line react/display-name
export const AssetUpload = React.memo(({ setFile, file, isDisabled }: IAssetUploadProps) => {
    return <ExerciseAssetUpload setFile={setFile} file={file} isDisabled={isDisabled} />
})

type ChildAssetUploadProps = {
    file: File | null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setFile: any
    isDisabled: boolean
}

const ChallengeAssetUpload = ({ file, setFile, isDisabled }: ChildAssetUploadProps) => {
    return (
        <>
            <EuiFilePicker
                initialPromptText="Select or drag and drop a file"
                disabled={isDisabled}
                multiple={false}
                onChange={async (f) => {
                    if (f && f.length !== 0) {
                        setFile(f[0])
                    } else {
                        setFile(null)
                    }
                }}
                display="large"
                aria-label="Upload new media here."
            />
            {file && (
                <div>
                    <EuiImage
                        size="s"
                        hasShadow
                        allowFullScreen
                        caption="Small"
                        alt="Accessible image alt goes here"
                        src={URL.createObjectURL(file)}
                    />
                </div>
            )}
        </>
    )
}

const ExerciseAssetUpload = ({ file, setFile, isDisabled }: ChildAssetUploadProps) => {
    console.log('here2')
    return (
        <>
            <EuiDescribedFormGroup
                fullWidth
                descriptionFlexItemProps={{
                    style: {
                        maxWidth: '300px'
                    }
                }}
                title={<h3>Media Upload</h3>}
                description="Upload media for this challenge."
            >
                <EuiFilePicker
                    multiple={false}
                    disabled={isDisabled}
                    accept="image/gif, image/jpeg, image/png, video/mp4"
                    initialPromptText="Select or drag and drop a file"
                    onChange={async (f) => {
                        if (f && f.length !== 0) {
                            setFile(f[0])
                        } else {
                            setFile(null)
                        }
                    }}
                    display="large"
                    aria-label="Upload new media here."
                />
                {file && (
                    <div
                        style={{
                            maxWidth: '400px'
                        }}
                    >
                        <EuiSpacer size="xxl" />
                        <EuiText size="m">File Preview</EuiText>
                        <EuiSpacer size="xs" />
                        {file.type === 'video/mp4' ? (
                            <ReactPlayer width="100%" controls url={URL.createObjectURL(file)} />
                        ) : (
                            <EuiImage
                                size="s"
                                hasShadow
                                allowFullScreen
                                caption="Small"
                                alt="Accessible image alt goes here"
                                src={URL.createObjectURL(file)}
                            />
                        )}
                    </div>
                )}
            </EuiDescribedFormGroup>
        </>
    )
}
