import React, { Fragment } from 'react'
import { Formik, Form, Field, FieldProps } from 'formik'
import * as Yup from 'yup'
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiText,
    EuiTitle,
    EuiFieldText,
    EuiLink
} from '@elastic/eui'
import slugify from 'slugify'

import { useChallengesStore } from 'components/Layout/AdminPageLayout'
import { useMutation, useQueryClient } from 'react-query'
import { createChallenge } from 'API/createChallenge'
import { fetchChallengeDetail } from 'API/fetchChallengeDetail'
import { useToastsStore } from 'components/GlobalToastList'
import { Link } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'

// const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

const CreateEventSchema = Yup.object().shape({
    name: Yup.string().max(60, 'Name must be less than 60 chars').required('Name is required'),
    challengeUrl: Yup.string().max(60, 'URL must be less than 60 chars').required('URL is required')
})

export type CreateEventFlyoutProps = {
    eventType: number
}

const mapURL = (eventType: CreateEventFlyoutProps['eventType']) => {
    switch (eventType) {
        case EVENT_TYPE.EXERCISES:
            return 'exercises'
        case EVENT_TYPE.CHALLENGE:
            return 'challenges'
        case EVENT_TYPE.SWAG:
            return 'products'
        case EVENT_TYPE.RACES:
            return 'races'
        default:
            return 'challenges'
    }
}

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const CreateEventFlyout = ({ eventType }: CreateEventFlyoutProps) => {
    const addToast = useToastsStore((state) => state.addToast)
    const removeToast = useToastsStore((state) => state.removeToast)
    const queryClient = useQueryClient()
    const mutation = useMutation(createChallenge)
    const isFlyoutVisible = useChallengesStore((state) => state.isFlyoutVisible)
    const toggleFlyout = useChallengesStore((state) => state.toggleFlyout)

    if (isFlyoutVisible) {
        return (
            <Formik
                validationSchema={CreateEventSchema}
                initialValues={{
                    name: '',
                    challengeUrl: '',
                    isExercise: false
                }}
                onSubmit={async (values) => {
                    try {
                        const challenge = await mutation.mutateAsync({
                            challengeUrl: values.challengeUrl,
                            name: values.name,
                            eventType
                        })

                        queryClient.invalidateQueries('challengeList')
                        queryClient.prefetchQuery(['challenge', challenge.challengeId], () =>
                            fetchChallengeDetail(challenge.challengeId)
                        )
                        addToast({
                            color: 'success',
                            id: challenge.challengeId,
                            title: `${eventType} successfully created.`,
                            text: (
                                <Fragment>
                                    <EuiText size="s">
                                        <dl>
                                            <dt>ID:</dt>
                                            <dd>{challenge.challengeId}</dd>
                                            <dt>Title:</dt>
                                            <dd>{challenge.name}</dd>
                                            <dt>URL:</dt>
                                            <dd>{challenge.challengeUrl}</dd>
                                        </dl>
                                    </EuiText>

                                    <EuiSpacer />

                                    <p>
                                        <Link
                                            to={`/${mapURL(eventType)}/${challenge.challengeId}`}
                                            onClick={() => removeToast({ id: challenge.challengeId })}
                                        >
                                            <EuiLink>{mapURL(eventType)} Details</EuiLink>
                                        </Link>
                                    </p>
                                </Fragment>
                            )
                        })
                        toggleFlyout()
                    } catch (e) {
                        console.error(e)
                    }
                }}
            >
                {({ errors, touched, isSubmitting, setFieldValue, submitForm }) => (
                    <EuiFlyout ownFocus onClose={toggleFlyout} hideCloseButton paddingSize="l">
                        <EuiFlyoutHeader hasBorder>
                            <EuiTitle size="m">
                                <h2 id="flyoutComplicatedTitle">Create {capitalizeFirstLetter(mapURL(eventType))}</h2>
                            </EuiTitle>
                            <EuiSpacer size="s" />
                            <EuiText color="subdued">
                                <p>Additional fields will be shown after the challenge has been created.</p>
                            </EuiText>
                        </EuiFlyoutHeader>
                        <EuiFlyoutBody>
                            <EuiSpacer size="m" />

                            <EuiForm>
                                <Form>
                                    <EuiFormRow
                                        fullWidth
                                        label={`${capitalizeFirstLetter(mapURL(eventType))} name`}
                                        isInvalid={touched.name && errors.name ? true : false}
                                        error={errors.name}
                                    >
                                        <Field name="name" disabled={isSubmitting}>
                                            {({ field }: FieldProps): JSX.Element => (
                                                <EuiFieldText
                                                    fullWidth
                                                    disabled={isSubmitting}
                                                    {...field}
                                                    autoComplete="off"
                                                    onChange={(e) => {
                                                        field.onChange(e)
                                                        setFieldValue(
                                                            'challengeUrl',
                                                            slugify(e.target.value, {
                                                                lower: true,
                                                                strict: true
                                                            })
                                                        )
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </EuiFormRow>

                                    <EuiSpacer size="l" />

                                    <EuiFormRow
                                        fullWidth
                                        label={`${capitalizeFirstLetter(mapURL(eventType))} URL`}
                                        isInvalid={errors.challengeUrl ? true : false}
                                        error={errors.challengeUrl}
                                    >
                                        <Field
                                            as={EuiFieldText}
                                            fullWidth
                                            name="challengeUrl"
                                            autocomplete="off"
                                            disabled={isSubmitting}
                                        />
                                    </EuiFormRow>
                                </Form>
                            </EuiForm>

                            <EuiSpacer />
                        </EuiFlyoutBody>
                        <EuiFlyoutFooter>
                            <EuiFlexGroup justifyContent="spaceBetween">
                                <EuiFlexItem grow={false}>
                                    <EuiButtonEmpty iconType="cross" onClick={toggleFlyout} flush="left">
                                        Cancel
                                    </EuiButtonEmpty>
                                </EuiFlexItem>
                                <EuiFlexItem grow={false}>
                                    <EuiButton
                                        isLoading={isSubmitting}
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        fill
                                    >
                                        Create
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlyoutFooter>
                    </EuiFlyout>
                )}
            </Formik>
        )
    } else {
        return null
    }
}
