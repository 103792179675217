import React, { useState } from 'react'

import { EuiButton, EuiContextMenuPanel, EuiContextMenuItem, EuiPopover, EuiIcon } from '@elastic/eui'

export const DetailPopover = () => {
    const [isPopoverOpen, setPopover] = useState(false)

    const onButtonClick = () => {
        setPopover(!isPopoverOpen)
    }

    const closePopover = () => {
        setPopover(false)
    }

    const items = [
        <EuiContextMenuItem
            key="delete"
            icon={<EuiIcon type="trash" size="m" color="danger" />}
            color=""
            onClick={closePopover}
        >
            Delete
        </EuiContextMenuItem>
    ]

    const button = (
        <EuiButton iconType="arrowDown" iconSide="right" onClick={onButtonClick}>
            Options
        </EuiButton>
    )

    return (
        <EuiPopover
            id="smallContextMenuExample"
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="none"
            anchorPosition="downLeft"
        >
            <EuiContextMenuPanel size="s" items={items} />
        </EuiPopover>
    )
}
