import { EuiPageHeader, EuiButton } from '@elastic/eui'
import { useMatch, useNavigate } from '@reach/router'
import { FC, useEffect } from 'react'

type ChallengesPageHeaderProps = {
    onCreateClicked: () => void
}

export const ChallengesPageHeader: FC<ChallengesPageHeaderProps> = ({ onCreateClicked }) => {
    const navigate = useNavigate()
    const matchIndex = useMatch('/challenges')
    const matchManage = useMatch('/challenges/manage')
    const matchPrioritize = useMatch('/challenges/prioritize')

    useEffect(() => {
        console.info('RENDER: ChallengesPageHeader')
    })

    if (!matchIndex && !matchManage && !matchPrioritize) {
        return null
    }

    return (
        <EuiPageHeader
            restrictWidth={false}
            pageTitle="Challenges"
            tabs={[
                {
                    label: 'Overview',
                    onClick: () => navigate('/challenges'),
                    isSelected: matchIndex !== null
                },
                {
                    label: 'Manage',
                    onClick: () => navigate('/challenges/manage'),
                    isSelected: matchManage !== null
                },
                {
                    label: 'Prioritize',
                    onClick: () => navigate('/challenges/prioritize'),
                    isSelected: matchPrioritize !== null
                }
            ]}
            rightSideItems={[
                <EuiButton onClick={onCreateClicked} key="0" iconType="plus" fill>
                    Create Challenge
                </EuiButton>
            ]}
        />
    )
}
