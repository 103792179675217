import { EuiCheckboxGroup, EuiDatePicker, EuiDescribedFormGroup, EuiForm, EuiFormRow, EuiPanel } from '@elastic/eui'
import { Field, FormikValues, useFormikContext } from 'formik'
import moment, { Moment } from 'moment'
import { Fragment, useEffect, useState } from 'react'
import FreeBadge from './Badges/FreeBadge'
import GroupBadge from './Badges/GroupBadge'
import MemberBadge from './Badges/MemberBadge'

export default function Pricing() {
    const { errors, touched, values, setFieldValue } = useFormikContext<FormikValues>()
    const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState(values.pricing)

    useEffect(() => {
        setCheckboxIdToSelectedMap(values.pricing)
    }, [values])

    const priceOptions = [
        {
            id: `FreeMembership`,
            label: <FreeBadge />,
            disabled: checkboxIdToSelectedMap['CYFActive'] === true || checkboxIdToSelectedMap['CYFGroup'] === true
        },
        {
            id: `CYFActive`,
            label: <MemberBadge />,
            disabled: checkboxIdToSelectedMap['FreeMembership'] === true
        },
        {
            id: `CYFGroup`,
            label: <GroupBadge />,
            disabled: checkboxIdToSelectedMap['FreeMembership'] === true
        }
    ]

    const onChange = (optionId: string) => {
        const newSelectedCheckboxes = {
            ...checkboxIdToSelectedMap,
            ...{
                [optionId]: !checkboxIdToSelectedMap[optionId]
            }
        }
        setCheckboxIdToSelectedMap(newSelectedCheckboxes)
        setFieldValue('pricing', newSelectedCheckboxes)
    }

    return (
        <EuiPanel style={{ marginTop: 20 }}>
            <EuiForm component="div">
                <EuiDescribedFormGroup
                    fullWidth
                    descriptionFlexItemProps={{
                        style: {
                            maxWidth: '300px'
                        }
                    }}
                    title={<h3>Pricing/Packages</h3>}
                    description={
                        <Fragment>Here you can configure the package & pricing options for this exercise.</Fragment>
                    }
                >
                    <EuiFormRow isInvalid={touched.pricing && errors.pricing ? true : false} error={errors.pricing}>
                        <Field
                            as={EuiCheckboxGroup}
                            idToSelectedMap={values.pricing}
                            options={priceOptions}
                            onChange={(id: string) => onChange(id)}
                            name="pricing"
                        />
                    </EuiFormRow>
                    <EuiDescribedFormGroup
                        title={<h3>Exclusive Until</h3>}
                        descriptionFlexItemProps={{
                            style: {
                                maxWidth: '300px'
                            }
                        }}
                        description={
                            <Fragment>
                                You can set this excercise to be viewable only by members until a certain date. Then,
                                anyone in YF will have access to it. Leave it empty to keep it open.
                            </Fragment>
                        }
                    >
                        <EuiFormRow
                            label="Date"
                            isInvalid={touched.exclusiveUntil && errors.exclusiveUntil ? true : false}
                        >
                            <Field
                                name="exclusiveUntil"
                                selected={values.exclusiveUntil ? moment(values.exclusiveUntil) : null}
                                as={EuiDatePicker}
                                onChange={(date: Moment) => setFieldValue('exclusiveUntil', date.format('MM/DD/YYYY'))}
                            />
                        </EuiFormRow>
                    </EuiDescribedFormGroup>
                </EuiDescribedFormGroup>
            </EuiForm>
        </EuiPanel>
    )
}
