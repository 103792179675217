import { Chart, Settings, LineSeries, Axis, ScaleType } from '@elastic/charts'
import moment from 'moment'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MockChart({ data = [], height = 500 }: any) {
    return (
        <Chart
            size={{
                width: '100%',
                height: height
            }}
        >
            <Settings
                showLegend={true}
                legendPosition="right"
                showLegendExtra
                brushAxis={'x'}
                theme={{
                    areaSeriesStyle: {
                        point: {
                            visible: false
                        }
                    },
                    lineSeriesStyle: {
                        point: {
                            visible: false
                        }
                    }
                }}
            />
            <LineSeries
                id="bars"
                name="0"
                data={data}
                xScaleType={ScaleType.Time}
                yScaleType={ScaleType.Linear}
                xAccessor={'x'}
                yAccessors={['y']}
                splitSeriesAccessors={['g']}
                displayValueSettings={{
                    showValueLabel: true
                }}
            />
            <Axis
                id="bottom-axis"
                position="bottom"
                showGridLines
                tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
            />
            <Axis id="left-axis" position="left" showGridLines />
        </Chart>
    )
}
