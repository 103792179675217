import { EuiProgress, EuiPanel, EuiSplitPanel, EuiTitle, EuiSpacer } from '@elastic/eui'
import { RouteComponentProps } from '@reach/router'
import { fetchSingleRaceJoins } from 'API/analytics/fetchSingleRaceJoins'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { Chart, Settings, Axis, BarSeries, ScaleType, Position } from '@elastic/charts'
import { useChallengeDetails } from 'hooks/useChallengeDetails'
import { MockChart } from 'pages/dashboard/components/MockChart'
import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { fetchSingleRaceViews } from '../../API/analytics/fetchSingleRaceViews'
import { DetailStatHeader } from './components/Detail/DetailStatHeader'
import moment from 'moment'

interface ChallengeDetailOverviewPageProps extends RouteComponentProps {
    challengeId?: number
}

export const ChallengeDetailOverviewPage: FC<ChallengeDetailOverviewPageProps> = ({ challengeId }) => {
    const { data, status } = useChallengeDetails(challengeId || undefined)
    const { data: views, isLoading } = useQuery([challengeId, 'views'], () => fetchSingleRaceViews(challengeId))
    const { data: joins, isLoading: isJoinsLoading } = useQuery([challengeId, 'joins'], () =>
        fetchSingleRaceJoins(challengeId)
    )

    return (
        <AdminPageLayout
            headerProps={{
                eventType: EVENT_TYPE.CHALLENGE,
                challengeId: challengeId || undefined,
                isLoading: status === 'loading',
                pageTitle: data?.challenge?.name || null,
                isPrivate: data?.challenge?.isPrivateChallenge ?? true,
                onSaveClicked: null
            }}
        >
            {isLoading && <EuiProgress size="xs" color="primary" />}
            <EuiPanel>
                <EuiSplitPanel.Outer hasShadow={false} direction="row" responsive={['xs', 's', 'm']}>
                    <EuiSplitPanel.Inner grow={false} style={{ minWidth: '350px' }}>
                        <EuiTitle>
                            <h3>Race Views</h3>
                        </EuiTitle>
                    </EuiSplitPanel.Inner>
                    <EuiSplitPanel.Inner>
                        {!isLoading && views?.views ? (
                            <Chart
                                size={{
                                    width: '100%',
                                    height: 350
                                }}
                            >
                                <Settings showLegend showLegendExtra legendPosition={Position.Right} />
                                <Axis
                                    id="bottom"
                                    position={Position.Bottom}
                                    showOverlappingTicks
                                    tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                                />
                                <Axis
                                    id="left2"
                                    position={Position.Left}
                                    tickFormat={(d: any) => Number(d).toFixed(2)}
                                />

                                <BarSeries
                                    id="bars"
                                    xScaleType={ScaleType.Linear}
                                    yScaleType={ScaleType.Linear}
                                    xAccessor="x"
                                    yAccessors={['y']}
                                    stackAccessors={['x']}
                                    splitSeriesAccessors={['g']}
                                    data={views.views}
                                />
                            </Chart>
                        ) : null}
                    </EuiSplitPanel.Inner>
                </EuiSplitPanel.Outer>
            </EuiPanel>
            <EuiSpacer size="xxl" />
            {isJoinsLoading && <EuiProgress size="xs" color="primary" />}
            <EuiPanel>
                <EuiSplitPanel.Outer hasShadow={false} direction="row" responsive={['xs', 's', 'm']}>
                    <EuiSplitPanel.Inner grow={false} style={{ minWidth: '350px' }}>
                        <EuiTitle>
                            <h3>Race Joins</h3>
                        </EuiTitle>
                    </EuiSplitPanel.Inner>
                    <EuiSplitPanel.Inner>
                        {!isJoinsLoading && joins?.views ? <MockChart height={300} data={joins.views} /> : null}
                        {!isJoinsLoading && joins?.views ? (
                            <Chart
                                size={{
                                    width: '100%',
                                    height: 350
                                }}
                            >
                                <Settings showLegend showLegendExtra legendPosition={Position.Bottom} />
                                <Axis
                                    id="bottom"
                                    position={Position.Bottom}
                                    showOverlappingTicks
                                    tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                                />
                                <Axis
                                    id="left2"
                                    position={Position.Left}
                                    tickFormat={(d: any) => Number(d).toFixed(2)}
                                />

                                <BarSeries
                                    id="bars"
                                    xScaleType={ScaleType.Linear}
                                    yScaleType={ScaleType.Linear}
                                    xAccessor="x"
                                    yAccessors={['y']}
                                    stackAccessors={['x']}
                                    splitSeriesAccessors={['g']}
                                    data={[
                                        ...joins?.parsedMobileJoinsFreeOrder,
                                        ...joins?.parsedMobileJoinsPaidOrder,
                                        ...joins?.parsedWebJoinsFreeOrder,
                                        ...joins?.parsedWebJoinsPaidOrder
                                    ]}
                                />
                            </Chart>
                        ) : null}
                        {!isJoinsLoading && joins?.views ? (
                            <Chart
                                size={{
                                    width: '100%',
                                    height: 350
                                }}
                            >
                                <Settings showLegend showLegendExtra legendPosition={Position.Bottom} />
                                <Axis
                                    id="bottom"
                                    position={Position.Bottom}
                                    showOverlappingTicks
                                    tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                                />
                                <Axis
                                    id="left2"
                                    position={Position.Left}
                                    tickFormat={(d: any) => Number(d).toFixed(2)}
                                />

                                <BarSeries
                                    id="bars"
                                    xScaleType={ScaleType.Linear}
                                    yScaleType={ScaleType.Linear}
                                    xAccessor="x"
                                    yAccessors={['y']}
                                    stackAccessors={['x']}
                                    splitSeriesAccessors={['g']}
                                    data={[
                                        ...joins?.parsedWebJoinsExistingUsers,
                                        ...joins?.parsedWebJoinsNewUsers,
                                        ...joins?.parsedMobileJoinsExistingUsers,
                                        ...joins?.parsedMobileJoinsNewUsers
                                    ]}
                                />
                            </Chart>
                        ) : null}
                    </EuiSplitPanel.Inner>
                </EuiSplitPanel.Outer>
            </EuiPanel>
        </AdminPageLayout>
    )
}
