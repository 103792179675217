import { Moment } from 'moment'
import axios from '../common/axios'
import { ENDPOINTS } from '../common/constants/endpoints'

export interface IChallengeUpdate {
    challenge: IChallenge
    isNew: boolean
    hasError: boolean
    message?: string
    error: unknown
}

export interface IChallenge {
    challengeId: number
    name: string
    challengeUrl: string
    price: number
    startDate: string
    endDate: string
    registrationCloseDate: string
    maxNumberOfRegistrations: number
    isPrivateChallenge: boolean
    topMediaBlock: string
    sortWeight: number
    isExercise: boolean
    fundraisingMessage: string
    metaDescription: string
    metaKeywords: string
    addonDiscount: number
    personalizeKeywords: string
    hideDevices: boolean
    keywords: number[]
    timeZone: string
}

export interface ChallengeActivityType {
    challengeActivityTypeId: number
    challengeId: number
    activityTypeId: number
    activityType: ActivityType
}

export interface ActivityType {
    activityTypeId: number
    name: string
    description: string
    sortWeight: number
}
export interface IPricing {
    challengeId: number
    plans: IPlan[]
    hasError: boolean
    message: null
    error: null
}

export interface IPlan {
    challengeStripePlanId: number | null
    challengeId: number
    stripePlanId: string
    untilTS?: string | Moment
    stripePlanName: string
    raceIconUrl: string
    isGroupPlan: boolean
    isSelected: boolean
}

export const updateChallenge = async (body: IChallenge) => {
    const updateChallengeResp = await axios.post(`${ENDPOINTS.CHALLENGE_DETAILS_API}`, {
        ...body
    })

    if (updateChallengeResp.status !== 200 || updateChallengeResp.data?.hasError) {
        throw new Error(updateChallengeResp.data.message)
    }

    return updateChallengeResp.data.challenge
}

export const updateChallengePricing = async (body: IPricing) => {
    const updateChallengeResp = await axios.post(`${ENDPOINTS.CHALLENGE_PRICING_API}`, {
        ...body
    })

    if (updateChallengeResp.status !== 200 || updateChallengeResp.data?.hasError) {
        throw new Error(updateChallengeResp.data.message)
    }

    return updateChallengeResp.data.challenge
}
