/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useReducer } from 'react'
import {
    EuiPanel // EuiTitle
} from '@elastic/eui'
import { useChallengeImages } from 'hooks/useChallengeImages'
import { useChallengeImgTypes } from 'hooks/useChallengeImgTypes'
import { EVENT_TYPE } from 'common/constants/eventType'
import { MediaLane } from './MediaLane'
import { AddAssetFlyout } from './AddAssetFlyout'
import { ImageType } from 'API/fetchChallengeImageTypes'

type MediaPanelProps = {
    challengeId: number
    eventType: number
}

const initialState: any = {
    isFlyoutVisible: false,
    imageTypeToEdit: { value: 0, description: '' }
}

const exerciseImageTypeIds = [5, 8, 12]

const handleImageTypes = (eventType: number, imageTypes: any) => {
    return eventType === EVENT_TYPE.EXERCISES
        ? imageTypes?.filter((type: any) => exerciseImageTypeIds.indexOf(type.value) !== -1)
        : imageTypes
}

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'setImageTypeToEdit':
            return { ...state, imageTypeToEdit: action.payload, isFlyoutVisible: true }
        case 'setIsFlyoutVisible':
            return { ...state, isFlyoutVisible: action.payload }

        default:
            return state
    }
}

export const MediaPanel: FC<MediaPanelProps> = ({ challengeId, eventType }) => {
    const { data: challengeImageData, refetch } = useChallengeImages(challengeId)
    const { data: imageTypes } = useChallengeImgTypes()

    const [state, dispatch] = useReducer(reducer, initialState)

    const handleClose = useCallback(() => dispatch({ type: 'setIsFlyoutVisible', payload: false }), [dispatch])

    const handleAddAssetClick = useCallback(
        (type: ImageType) => {
            dispatch({ type: 'setImageTypeToEdit', payload: type })
        },
        [dispatch]
    )

    return (
        <EuiPanel paddingSize="l" hasShadow={true} style={{ marginTop: 20 }}>
            {/* Render media categories */}
            {imageTypes &&
                handleImageTypes(eventType, imageTypes).map((type: any, idx: number) => {
                    return (
                        <MediaLane
                            refetchImages={refetch}
                            challengeImages={challengeImageData?.images ?? []}
                            type={type}
                            id={idx}
                            key={idx}
                            onAddAsset={handleAddAssetClick}
                            challengeId={challengeId}
                        />
                    )
                })}
            {state.isFlyoutVisible && (
                <AddAssetFlyout
                    eventType={eventType}
                    challengeId={challengeId}
                    imageTypeToEdit={state.imageTypeToEdit}
                    onClose={handleClose}
                    refetchImages={refetch}
                />
            )}
        </EuiPanel>
    )
}
