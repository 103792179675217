/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'
import {
    EuiDragDropContext,
    EuiDraggable,
    EuiDroppable,
    EuiButtonIcon,
    EuiPanel,
    euiDragDropReorder,
    EuiText,
    EuiFlexItem,
    EuiFlexGroup,
    EuiIcon,
    EuiSpacer,
    htmlIdGenerator
} from '@elastic/eui'
import { useLaneStore } from './index'
import { prependAssetHost, prependAssetHostChallenge } from 'common/utils'
import { useQueryClient } from 'react-query'
// import activeFlameSvg from '../../../../assets/active-flame.svg'
// import defaultFlameSvg from '../../assets/default-flame.svg'

type LaneManagerProps = {
    items: Array<any>
    laneIndex: number
    setLaneItems: (items: Array<any>) => void
    eventType: number
}

// const FlameSection = ({ intensity = 0 }: any) => {
//     if (!intensity) return null

//     return (
//         <EuiFlexGroup gutterSize="xs" wrap={false} responsive={false}>
//             <EuiFlexItem grow={false}>
//                 <EuiIcon type={intensity >= 1 ? activeFlameSvg : defaultFlameSvg} size="m" />
//             </EuiFlexItem>
//             <EuiFlexItem grow={false}>
//                 <EuiIcon type={intensity >= 2 ? activeFlameSvg : defaultFlameSvg} size="m" />
//             </EuiFlexItem>
//             <EuiFlexItem grow={false}>
//                 <EuiIcon type={intensity >= 3 ? activeFlameSvg : defaultFlameSvg} size="m" />
//             </EuiFlexItem>
//         </EuiFlexGroup>
//     )
// }

export const LaneManager: FC<LaneManagerProps> = ({ laneIndex, setLaneItems, eventType }) => {
    const queryClient = useQueryClient()
    const laneItems = useLaneStore((state) => state.lanes[laneIndex].challengeLanes)

    const lists: any = {
        COMPLEX_DROPPABLE_SUB_PARENT: laneItems
    }

    const actions: any = {
        COMPLEX_DROPPABLE_SUB_PARENT: setLaneItems
    }

    const onDragEnd: any = ({ source, destination }: any) => {
        if (source && destination) {
            if (source.droppableId === destination.droppableId) {
                const items = euiDragDropReorder(lists[destination.droppableId], source.index, destination.index)

                actions[destination.droppableId](items)

                setTimeout(() => {
                    queryClient.invalidateQueries(['lanes', eventType])
                }, 500)

                console.log('here CARD position changed')
            }
        }
    }

    console.log({ laneItems })

    return (
        <EuiDragDropContext onDragEnd={onDragEnd}>
            <EuiDroppable
                className="LaneDroppable"
                droppableId="COMPLEX_DROPPABLE_SUB_PARENT"
                type="MACRO"
                direction="horizontal"
                spacing="l"
                style={{ display: 'flex', overflowX: 'scroll' }}
            >
                {laneItems?.map((item: any, idx: number) => (
                    <EuiDraggable
                        key={htmlIdGenerator()()}
                        index={idx}
                        draggableId={`COMPLEX_DRAGGABLE_${htmlIdGenerator()()}`}
                        spacing="l"
                        style={{ flex: '0 0 225px' }}
                        disableInteractiveElementBlocking // Allows button to be drag handle
                    >
                        {(provided) => (
                            <EuiPanel
                                style={{
                                    backgroundImage: `url(${
                                        item.challenge?.logo !== null
                                            ? prependAssetHostChallenge(item.challenge?.logo)
                                            : prependAssetHost('/icons/no-challenge-bg.png')
                                    })`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    width: '225px',
                                    height: '300px',
                                    position: 'relative'
                                }}
                                hasShadow={false}
                                color="subdued"
                                paddingSize="s"
                                {...provided.dragHandleProps}
                            >
                                <EuiButtonIcon
                                    color="ghost"
                                    size="s"
                                    display="base"
                                    iconType="grab"
                                    aria-label="Drag Handle"
                                />

                                <EuiButtonIcon
                                    onClick={() => {
                                        const innerLaneItems = [...laneItems]
                                        innerLaneItems[idx].isDelete = true
                                        setLaneItems(innerLaneItems)
                                        setTimeout(() => {
                                            queryClient.invalidateQueries(['lanes', eventType])
                                        }, 500)
                                    }}
                                    color="danger"
                                    size="s"
                                    display="base"
                                    iconType="trash"
                                    aria-label="Delete Handle"
                                />

                                <div style={{ position: 'absolute', left: 10, bottom: 15, zIndex: 1 }}>
                                    <EuiText color="ghost" size="m">
                                        <b>{item.challenge?.name}</b>
                                    </EuiText>
                                    {!item.challenge && (
                                        <EuiText color="ghost" size="m">
                                            <b>Error rendering ID: {item.challengeId}</b>
                                        </EuiText>
                                    )}
                                    <EuiFlexGroup gutterSize="xs" responsive={false} wrap={false}>
                                        <EuiFlexItem grow={false}>
                                            {item.challenge?.exerciseType && (
                                                <EuiText color="ghost" size="xs">
                                                    {item.challenge?.exerciseType} •
                                                </EuiText>
                                            )}
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={false}>
                                            {item.challenge?.totalDistance && (
                                                <EuiText
                                                    color="ghost"
                                                    size="xs"
                                                >{`${item.challenge?.totalDistance} ${item.challenge?.distanceUnit}`}</EuiText>
                                            )}
                                        </EuiFlexItem>
                                    </EuiFlexGroup>

                                    <EuiSpacer size="xs" />

                                    {/* <FlameSection intensity={item.challenge.skillLevel} /> */}
                                </div>

                                <div
                                    style={{
                                        pointerEvents: 'none',
                                        borderRadius: 'inherit',
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        left: 0,
                                        bottom: 0,
                                        background:
                                            'linear-gradient(180deg, rgba(68, 68, 68, 0) 0%, rgba(0, 0, 0, 0.6) 95%)'
                                    }}
                                ></div>
                            </EuiPanel>
                        )}
                    </EuiDraggable>
                )) ?? null}
            </EuiDroppable>
        </EuiDragDropContext>
    )
}
