import { IChallengeImageData } from 'API/challengeImages'
import { ENDPOINTS } from '../common/constants/endpoints'
import axios from '../common/axios'
import { AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { IChallengeImage } from './challengeImages'

export interface ISignedURLResp {
    hasError: boolean
    message: string
    error: string
    url: string
}

export interface IFileModel extends File {
    preview: string
    uploading: boolean
    error: null
    processing: boolean
    altText: string
    s3URL?: string | null
    challengeImageType?: string | null
    challengeImageTypeId?: number | null
}

interface IUploadMediaResp extends AxiosResponse {
    data: ISignedURLResp
}

interface IPutNewMediaResp extends AxiosResponse {
    data: IChallengeImageData
}

interface ChallengeMediaParams {
    challengeId: number
    fileModel: IFileModel
}

interface ChallengeMediaUpdateParams {
    challengeId: number
    image: IChallengeImage
}
interface ChallengeMediaDeletionParams {
    challengeId: number
    imageToDelete: IChallengeImage
}

export const fetchSignedUrl = async (key: string, contentType: string, challengeId: number) => {
    const signedUrlRes: IUploadMediaResp = await axios.get(`${ENDPOINTS.SIGNED_URL_API}`, {
        params: {
            key: key,
            contentType: contentType,
            challengeId: challengeId
        }
    })

    if (signedUrlRes.status !== 200 || signedUrlRes.data?.hasError) {
        throw new Error(signedUrlRes.data.message)
    }

    return signedUrlRes.data
}

export const putNewMedia = async (signedURL: string, file: IFileModel) => {
    const putNewMediaResp: IPutNewMediaResp = await axios.put(signedURL, file, {
        headers: {
            'Content-Type': file.type
        },
        transformRequest: (data, headers) => {
            delete headers['Authorization']
            return data
        }
    })

    if (putNewMediaResp.status !== 200 || putNewMediaResp.data?.hasError) {
        throw new Error(putNewMediaResp.data.message)
    }

    return putNewMediaResp.data
}

export const postChallengeMedia = async ({ challengeId, fileModel }: ChallengeMediaParams) => {
    const imageData = {
        key: uuidv4(),
        challengeImageId: null,
        challengeId: challengeId,
        challengeImageUrl: fileModel.s3URL,
        challengeImageContentType: fileModel.type,
        challengeImageTypeId: fileModel.challengeImageTypeId,
        challengeImageType: fileModel.challengeImageType,
        altText: fileModel.altText,
        muxAsset: null,
        muxAssetId: null,
        isDirty: true
    }
    const challengeMediaResp: IPutNewMediaResp = await axios.put(`${ENDPOINTS.MEDIA_LIBRARY_API}`, {
        challengeId: challengeId,
        images: [imageData]
    })

    if (challengeMediaResp.status !== 200 || challengeMediaResp.data?.hasError) {
        throw new Error(challengeMediaResp.data.message)
    }

    return challengeMediaResp.data
}

export const updateChallengeMedia = async ({ challengeId, image }: ChallengeMediaUpdateParams) => {
    const challengeMediaResp: IPutNewMediaResp = await axios.put(`${ENDPOINTS.MEDIA_LIBRARY_API}`, {
        challengeId: challengeId,
        images: [image]
    })

    if (challengeMediaResp.status !== 200 || challengeMediaResp.data?.hasError) {
        throw new Error(challengeMediaResp.data.message)
    }

    return challengeMediaResp.data
}

export const deleteChallengeMedia = async ({ challengeId, imageToDelete }: ChallengeMediaDeletionParams) => {
    imageToDelete.isDelete = true
    const challengeMediaResp: IPutNewMediaResp = await axios.put(`${ENDPOINTS.MEDIA_LIBRARY_API}`, {
        challengeId: challengeId,
        images: [imageToDelete]
    })

    if (challengeMediaResp.status !== 200 || challengeMediaResp.data?.hasError) {
        throw new Error(challengeMediaResp.data.message)
    }

    return challengeMediaResp.data
}
