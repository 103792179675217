import React from 'react'

import { EuiPage, EuiPageContent, EuiPageContentBody, EuiPageSideBar, EuiPageBody } from '@elastic/eui'

import Header from './Header'
import SideNav from './SideNav'
import { DashboardPageHeader } from '../../pages/dashboard/components/PageHeader'
import { ChallengesPageHeader } from '../../pages/challenges/components/PageHeader'
import { MatchRenderProps } from '@reach/router'
import create from 'zustand'
import { GlobalToastList } from 'components/GlobalToastList'
import { ChallengeDetailPageHeader } from 'pages/challenges/components/DetailPageHeader'
import { ExerciseDetailPageHeader } from 'pages/exercises/components/Detail/DetailPageHeader'
import { ProductsPageHeader } from 'pages/products/components/PageHeader'
import { RacesPageHeader } from 'pages/races/components/PageHeader'
import { ExercisesPageHeader } from 'pages/exercises/components/PageHeader'
import { CreateEventFlyout } from 'components/CreateEventFlyout'
import { EVENT_TYPE } from 'common/constants/eventType'

export type AdminPageProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CustomPageHeader?: (props: MatchRenderProps<unknown>) => JSX.Element

    createFlyoutProps?: {
        eventType: number
    }

    headerProps?: {
        eventType: number
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSaveClicked?: any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pageTitle?: any
        isLoading?: boolean
        isError?: boolean
        isPrivate?: boolean
        challengeId?: number
        onVisibilityChange?: any
    }
}

interface ChallengesState {
    isFlyoutVisible: boolean
    isExercise: boolean
    toggleFlyout: () => void
    toggleExerciseFlyout: () => void
}

export const useChallengesStore = create<ChallengesState>((set) => ({
    isFlyoutVisible: false,
    isExercise: false,
    toggleFlyout: () => set((state) => ({ isFlyoutVisible: !state.isFlyoutVisible, isExercise: false })),
    toggleExerciseFlyout: () =>
        set((state) => ({ isFlyoutVisible: !state.isFlyoutVisible, isExercise: !state.isExercise }))
}))

const handleHeaderSelection = (headerProps: AdminPageProps['headerProps']) => {
    switch (headerProps?.eventType) {
        case EVENT_TYPE.EXERCISES:
            return (
                <ExerciseDetailPageHeader
                    challengeId={headerProps?.challengeId || null}
                    onSaveClicked={headerProps?.onSaveClicked}
                    isLoading={headerProps?.isLoading ?? true}
                    isError={headerProps?.isError ?? false}
                    pageTitle={headerProps?.pageTitle || null}
                    isPrivate={headerProps?.isPrivate ?? false}
                    onVisibilityChange={headerProps?.onVisibilityChange}
                />
            )
        case EVENT_TYPE.CHALLENGE:
            return (
                <ChallengeDetailPageHeader
                    challengeId={headerProps?.challengeId || null}
                    onSaveClicked={headerProps?.onSaveClicked}
                    isLoading={headerProps?.isLoading ?? true}
                    pageTitle={headerProps?.pageTitle || null}
                    isPrivate={headerProps?.isPrivate ?? true}
                />
            )

        default:
            return null
    }
}

export function AdminPageLayout({ children, headerProps, createFlyoutProps }: AdminPageProps) {
    const toggleCreateEventFlyout = useChallengesStore((state) => state.toggleFlyout)

    return (
        <div>
            <Header />
            <EuiPage paddingSize="none">
                <EuiPageSideBar paddingSize="l" sticky>
                    <SideNav />
                </EuiPageSideBar>

                <EuiPageBody panelled>
                    <DashboardPageHeader />
                    <ChallengesPageHeader onCreateClicked={toggleCreateEventFlyout} />
                    <ExercisesPageHeader onCreateClicked={toggleCreateEventFlyout} />
                    <ProductsPageHeader onCreateClicked={toggleCreateEventFlyout} />
                    <RacesPageHeader onCreateClicked={toggleCreateEventFlyout} />

                    {headerProps && handleHeaderSelection(headerProps)}

                    <EuiPageContent
                        hasBorder={false}
                        hasShadow={false}
                        paddingSize="none"
                        color="transparent"
                        borderRadius="none"
                    >
                        <EuiPageContentBody style={{ minHeight: '76vh' }} restrictWidth={false}>
                            {children}
                        </EuiPageContentBody>
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>

            {createFlyoutProps && <CreateEventFlyout eventType={createFlyoutProps.eventType} />}
            <GlobalToastList />
        </div>
    )
}
