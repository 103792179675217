/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { EVENT_TYPE } from '../../common/constants/eventType'
import { EuiText, EuiStat, EuiFlexItem, EuiFlexGroup, EuiPanel, EuiSpacer, EuiTitle } from '@elastic/eui'

interface IStatHeaderProps {
    eventType: number
}

export const StatHeader = ({ eventType }: IStatHeaderProps) => {
    const [isLoading] = useState(false)
    const switchTitle = () => {
        switch (eventType) {
            case EVENT_TYPE.CHALLENGE:
                return 'Challenge'
            case EVENT_TYPE.EXERCISES:
                return 'Exercise'
            case EVENT_TYPE.RACES:
                return 'Race'
            case EVENT_TYPE.SWAG:
                return 'Product'
            default:
                return 'Challenge'
        }
    }
    const title = switchTitle()

    return (
        <div>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>
                                        {eventType === EVENT_TYPE.SWAG ? `${title}s sold` : `${title}s completed`}
                                    </h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>
                                        {eventType === EVENT_TYPE.SWAG
                                            ? `MAU that purchased a ${title}`
                                            : `MAU that completed a ${title}`}
                                    </h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>{title}s with 300+ views</h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>New {title}s created</h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
        </div>
    )
}
