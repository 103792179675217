/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useRef, useState } from 'react'
import axios from 'common/axios'
import { Formik, Field, FieldProps } from 'formik'
import * as Yup from 'yup'
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiTitle,
    EuiFieldText,
    EuiSwitch,
    EuiSelectable,
    EuiButtonIcon
} from '@elastic/eui'

import { useQueryClient } from 'react-query'
import { useLaneStore } from './index'
import { useChallengeList } from 'hooks/useChallengeList'
import { fetchUserJoinChallenge } from 'API/fetchUserJoinChallenge'
import { EVENT_TYPE } from 'common/constants/eventType'

// const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

// Yup.object().shape({
// 	id: Yup.number(),
// 	logo: Yup.string().nullable(),
// 	name: Yup.string(),
// 	totalDistance: Yup.number().nullable(),
// 	distanceUnit: Yup.string().nullable(),
// 	exerciseType: Yup.string().optional(),
// 	skillLevel: Yup.number().optional()
// })

function mapSkillLevelString(level: string) {
    switch (level) {
        case 'Beginner':
            return 1
        case 'Intermediate':
            return 2
        case 'Advanced':
            return 3
        default:
            return 0
    }
}

const CreateLaneSchema = Yup.object().shape({
    title: Yup.string().max(30, 'Lane title must be less than 30 chars').required('Lane title is required'),
    platforms: Yup.array().of(Yup.number()),
    isWeb: Yup.boolean(),
    isNative: Yup.boolean()
})

export const ManageItemsFlyout = ({ eventType, laneIdx }: { eventType: number; laneIdx: number }) => {
    // const user = useUserProfile()
    // const addToast = useToastsStore((state) => state.addToast)
    // const removeToast = useToastsStore((state) => state.removeToast)
    const formRef = useRef<any>()
    const queryClient = useQueryClient()
    // const mutation = useMutation(createChallenge)
    const { data } = useChallengeList(eventType)
    // const isFlyoutVisible = useLaneStore((state) => state.isFlyoutVisible)
    // const toggleFlyout = useLaneStore((state) => state.toggleFlyout)
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false)
    const selectedLane = useLaneStore((state) => state.lanes)
    const [options, setOptions] = useState<any>([])

    useEffect(() => {
        if (data && data.challenges.length) {
            const mappedChallenges = data.challenges
                .map((c) => {
                    if (selectedLane[laneIdx].challengeLanes.some((i: any) => i.challengeId === c.challengeId)) {
                        return {
                            key: `${c.challengeUrl}`,
                            label: c.name,
                            checked: 'on'
                        }
                    }
                    return {
                        key: `${c.challengeUrl}`,
                        label: c.name,
                        checked: null
                    }
                })
                .sort((a, b) => (a.checked === 'on' ? -1 : +1))
            setOptions([...mappedChallenges])
            formRef.current?.setFieldValue('items', [...mappedChallenges])
        }
    }, [data, isFlyoutVisible])

    useEffect(() => {
        formRef.current?.setFieldValue('title', selectedLane[laneIdx].name)
    }, [isFlyoutVisible])

    return (
        <>
            <EuiButtonIcon
                iconType="listAdd"
                aria-label="add Handle"
                size="s"
                display="base"
                onClick={() => setIsFlyoutVisible(true)}
            />
            {isFlyoutVisible ? (
                <Formik
                    innerRef={formRef}
                    validationSchema={CreateLaneSchema}
                    initialValues={{
                        title: '',
                        items: [],
                        isWeb: true,
                        isNative: true
                    }}
                    onSubmit={async (values) => {
                        try {
                            console.log({ data })
                            const challenges = values.items
                                .filter((item: any) => item.checked === 'on')
                                .map((item: any) =>
                                    data?.challenges.find((challenge) => challenge.challengeUrl === item.key)
                                )

                            console.log({ challenges })

                            // selected challenges (may or may not include existing challengeLane items depending on user input)
                            const challengePostBody = challenges.map((data, idx) => {
                                const mappedChallengeLaneId =
                                    selectedLane[laneIdx].challengeLanes.find(
                                        (lane: any) => lane.challengeId === data?.challengeId
                                    )?.challengeLaneId ?? null

                                return {
                                    challengeLaneId: mappedChallengeLaneId,
                                    challengeId: data?.challengeId,
                                    laneId: selectedLane[laneIdx].laneId ?? null,
                                    position: selectedLane[laneIdx].position ?? idx + 1,
                                    createTS: '2021-07-14T16:13:44.533Z',
                                    createUserId: 0,
                                    isDirty: false,
                                    isDelete: false
                                }
                            })

                            console.log({ challengePostBody, lane: selectedLane[laneIdx] })

                            const laneData = {
                                ...selectedLane[laneIdx],
                                name: values.title,
                                challengeLanes: challengePostBody
                            }

                            const res = await axios.post('/api/v1/admin/DiscoverEventList', laneData).catch((e) => {
                                console.log(e)
                            })
                            console.log('POST', res)

                            queryClient.invalidateQueries(['lanes', eventType])

                            setOptions([])
                            setIsFlyoutVisible(!isFlyoutVisible)
                        } catch (e) {
                            console.error(e)
                        }
                    }}
                >
                    {({ errors, touched, isSubmitting, submitForm }) => (
                        <EuiFlyout
                            maxWidth={500}
                            ownFocus
                            onClose={() => setIsFlyoutVisible(false)}
                            hideCloseButton
                            paddingSize="l"
                        >
                            <EuiFlyoutHeader hasBorder>
                                <EuiTitle size="m">
                                    <h2 id="flyoutComplicatedTitle">Manage Lane</h2>
                                </EuiTitle>
                            </EuiFlyoutHeader>
                            <EuiFlyoutBody>
                                <EuiSpacer size="m" />

                                <EuiForm id="modalFormId" component="form">
                                    <EuiFormRow
                                        label="Lane Title"
                                        isInvalid={touched.title && errors.title ? true : false}
                                        error={errors.title}
                                    >
                                        <Field name="title">
                                            {({ field }: FieldProps): JSX.Element => (
                                                <EuiFieldText disabled={isSubmitting} {...field} />
                                            )}
                                        </Field>
                                    </EuiFormRow>

                                    <EuiSpacer size="l" />

                                    <EuiFormRow
                                        label="Select Challenges"
                                        isInvalid={touched.items && errors.items ? true : false}
                                        error={errors.items}
                                    >
                                        <Field name="items">
                                            {({ form }: FieldProps): JSX.Element => (
                                                <EuiSelectable
                                                    aria-label="Searchable example"
                                                    searchable
                                                    searchProps={{
                                                        'data-test-subj': 'selectableSearchHere'
                                                    }}
                                                    height={500}
                                                    options={options}
                                                    onChange={(newOptions) => {
                                                        const selectedOptions = newOptions.map((item) => {
                                                            return { ...item, checked: item.checked }
                                                        })
                                                        form.setFieldValue('items', selectedOptions)
                                                        setOptions(newOptions)
                                                    }}
                                                >
                                                    {(list, search) => (
                                                        <Fragment>
                                                            {search}
                                                            {list}
                                                        </Fragment>
                                                    )}
                                                </EuiSelectable>
                                            )}
                                        </Field>
                                    </EuiFormRow>

                                    <EuiSpacer size="xl" />

                                    {/* <EuiFlexGroup>
                                        <EuiFlexItem grow={false}>
                                            <Field name="isWeb">
                                                {({ field, form, meta }: FieldProps): JSX.Element => (
                                                    <EuiSwitch
                                                        checked={field.value}
                                                        onChange={() => form.setFieldValue('isWeb', !field.value)}
                                                        name="popswitch"
                                                        label="Web"
                                                    />
                                                )}
                                            </Field>
                                        </EuiFlexItem>

                                        <EuiFlexItem>
                                            <Field name="isNative">
                                                {({ field, form, meta }: FieldProps): JSX.Element => (
                                                    <EuiSwitch
                                                        checked={field.value}
                                                        onChange={() => form.setFieldValue('isNative', !field.value)}
                                                        name="popswitch"
                                                        label="Mobile"
                                                    />
                                                )}
                                            </Field>
                                        </EuiFlexItem>
                                    </EuiFlexGroup> */}
                                </EuiForm>

                                <EuiSpacer />
                            </EuiFlyoutBody>
                            <EuiFlyoutFooter>
                                <EuiFlexGroup justifyContent="spaceBetween">
                                    <EuiFlexItem grow={false}>
                                        <EuiButtonEmpty
                                            iconType="cross"
                                            onClick={() => setIsFlyoutVisible(false)}
                                            flush="left"
                                        >
                                            Cancel
                                        </EuiButtonEmpty>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiButton
                                            isLoading={isSubmitting}
                                            disabled={isSubmitting}
                                            onClick={submitForm}
                                            fill
                                        >
                                            Save
                                        </EuiButton>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlyoutFooter>
                        </EuiFlyout>
                    )}
                </Formik>
            ) : null}
        </>
    )
}
