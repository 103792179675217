import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { Chart, Settings, Axis, BarSeries, ScaleType, Position } from '@elastic/charts'
import moment from 'moment'
import {
    EuiProgress,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel,
    EuiSpacer,
    EuiSplitPanel,
    EuiStat,
    EuiText,
    EuiTitle
} from '@elastic/eui'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { StatHeader } from 'components/StatHeader'
import { FC } from 'react'
import { useQuery } from 'react-query'
import { fetchAllRaceViews } from '../../API/analytics/fetchAllRaceViews'
import { MockChart } from 'pages/dashboard/components/MockChart'
import { fetchWebUTMViews } from '../../API/analytics/fetchWebUTMViews'
import { fetchAllJoins } from '../../API/analytics/fetchAllJoins'

type RacesOverviewPageProps = RouteComponentProps

export const RacesOverviewPage: FC<RacesOverviewPageProps> = () => {
    const { data: viewsData, isLoading } = useQuery('all-views', fetchAllRaceViews, {
        refetchOnWindowFocus: false
    })
    const { data: webUTMviewsData, isLoading: utmViewsIsLoading } = useQuery('all-web-utm-views', fetchWebUTMViews, {
        refetchOnWindowFocus: false
    })
    const { data: allJoinsData, isLoading: isJoinsLoading } = useQuery('all-joins', fetchAllJoins)

    console.log({ viewsData })

    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.RACES }}>
            {isLoading && <EuiProgress size="xs" color="primary" />}
            <EuiPanel>
                <EuiSplitPanel.Outer hasShadow={false} responsive={['xs', 's', 'm']}>
                    <EuiTitle>
                        <h3>All Race Views</h3>
                    </EuiTitle>
                    <EuiSpacer size="s" />
                    <EuiText>
                        <p>Only races that have had 20 views on at least 1 day are shown.</p>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiTitle size="s">
                        <h4>Web</h4>
                    </EuiTitle>
                    {!isLoading && viewsData?.webViews ? (
                        <Chart
                            size={{
                                width: '100%',
                                height: 350
                            }}
                        >
                            <Settings showLegend showLegendExtra legendPosition={Position.Right} />
                            <Axis
                                id="bottom"
                                position={Position.Bottom}
                                showOverlappingTicks
                                tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                            />
                            <Axis id="left2" position={Position.Left} tickFormat={(d: any) => Number(d).toFixed(2)} />

                            <BarSeries
                                id="bars"
                                xScaleType={ScaleType.Linear}
                                yScaleType={ScaleType.Linear}
                                xAccessor="x"
                                yAccessors={['y']}
                                stackAccessors={['x']}
                                splitSeriesAccessors={['g']}
                                data={viewsData?.webViews}
                            />
                        </Chart>
                    ) : null}
                    <EuiTitle size="s">
                        <h4>Mobile</h4>
                    </EuiTitle>
                    {!isLoading && viewsData?.mobileViews ? (
                        <Chart
                            size={{
                                width: '100%',
                                height: 350
                            }}
                        >
                            <Settings showLegend showLegendExtra legendPosition={Position.Right} />
                            <Axis
                                id="bottom"
                                position={Position.Bottom}
                                showOverlappingTicks
                                tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                            />
                            <Axis id="left2" position={Position.Left} tickFormat={(d: any) => Number(d).toFixed(2)} />

                            <BarSeries
                                id="bars"
                                xScaleType={ScaleType.Linear}
                                yScaleType={ScaleType.Linear}
                                xAccessor="x"
                                yAccessors={['y']}
                                stackAccessors={['x']}
                                splitSeriesAccessors={['g']}
                                data={viewsData?.mobileViews}
                            />
                        </Chart>
                    ) : null}
                </EuiSplitPanel.Outer>
            </EuiPanel>
            <EuiSpacer size="xxl" />
            {utmViewsIsLoading && <EuiProgress size="xs" color="primary" />}
            <EuiPanel>
                <EuiSplitPanel.Outer hasShadow={false} responsive={['xs', 's', 'm']}>
                    <EuiTitle>
                        <h3>Web Race Views by UTM Source</h3>
                    </EuiTitle>

                    {!utmViewsIsLoading && webUTMviewsData?.webViews ? (
                        <Chart
                            size={{
                                width: '100%',
                                height: 350
                            }}
                        >
                            <Settings showLegend showLegendExtra legendPosition={Position.Right} />
                            <Axis
                                id="bottom"
                                position={Position.Bottom}
                                showOverlappingTicks
                                tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                            />
                            <Axis id="left2" position={Position.Left} tickFormat={(d: any) => Number(d).toFixed(2)} />

                            <BarSeries
                                id="bars"
                                xScaleType={ScaleType.Linear}
                                yScaleType={ScaleType.Linear}
                                xAccessor="x"
                                yAccessors={['y']}
                                stackAccessors={['x']}
                                splitSeriesAccessors={['g']}
                                data={webUTMviewsData.webViews}
                            />
                        </Chart>
                    ) : null}
                </EuiSplitPanel.Outer>
            </EuiPanel>
            <EuiSpacer size="xxl" />
            {isJoinsLoading && <EuiProgress size="xs" color="primary" />}
            <EuiPanel>
                <EuiSplitPanel.Outer hasShadow={false} responsive={['xs', 's', 'm']}>
                    <EuiTitle size="l">
                        <h3>Joins</h3>
                    </EuiTitle>
                    <EuiSpacer size="s" />
                    <EuiText>
                        <p>
                            This will show all joins for races, challenges, and products. If there are multiple events
                            in a single cart, it will only show the first one.
                        </p>
                    </EuiText>
                    <EuiSpacer size="m" />
                    <EuiTitle>
                        <h3>Web Joins</h3>
                    </EuiTitle>

                    {!isJoinsLoading && allJoinsData?.webJoins ? (
                        <Chart
                            size={{
                                width: '100%',
                                height: 350
                            }}
                        >
                            <Settings showLegend showLegendExtra legendPosition={Position.Right} />
                            <Axis
                                id="bottom"
                                position={Position.Bottom}
                                showOverlappingTicks
                                tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                            />
                            <Axis id="left2" position={Position.Left} tickFormat={(d: any) => Number(d).toFixed(2)} />

                            <BarSeries
                                id="bars"
                                xScaleType={ScaleType.Linear}
                                yScaleType={ScaleType.Linear}
                                xAccessor="x"
                                yAccessors={['y']}
                                stackAccessors={['x']}
                                splitSeriesAccessors={['g']}
                                data={allJoinsData.webJoins}
                            />
                        </Chart>
                    ) : null}

                    <EuiTitle>
                        <h3>Mobile Joins</h3>
                    </EuiTitle>
                    {!isJoinsLoading && allJoinsData?.mobileJoins ? (
                        <Chart
                            size={{
                                width: '100%',
                                height: 350
                            }}
                        >
                            <Settings showLegend showLegendExtra legendPosition={Position.Right} />
                            <Axis
                                id="bottom"
                                position={Position.Bottom}
                                showOverlappingTicks
                                tickFormat={(d) => `${moment(d).utc().format('MM/DD')}`}
                            />
                            <Axis id="left2" position={Position.Left} tickFormat={(d: any) => Number(d).toFixed(2)} />

                            <BarSeries
                                id="bars"
                                xScaleType={ScaleType.Linear}
                                yScaleType={ScaleType.Linear}
                                xAccessor="x"
                                yAccessors={['y']}
                                stackAccessors={['x']}
                                splitSeriesAccessors={['g']}
                                data={allJoinsData.mobileJoins}
                            />
                        </Chart>
                    ) : null}
                </EuiSplitPanel.Outer>
            </EuiPanel>
            {/* <StatHeader eventType={EVENT_TYPE.RACES} /> */}
        </AdminPageLayout>
    )
}
