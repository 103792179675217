import React, { useState } from 'react'
import {
    EuiFlexGroup,
    EuiDescribedFormGroup,
    EuiSpacer,
    EuiBadge,
    EuiCard,
    EuiFlexItem,
    EuiIcon,
    EuiToolTip,
    EuiButtonIcon,
    EuiLoadingSpinner,
    EuiModal,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiModalBody,
    EuiDescriptionList
} from '@elastic/eui'
import ReactPlayer from 'react-player'
import { IChallengeImage } from 'API/challengeImages'
import { deleteChallengeMedia } from 'API/mediaLibrary'
import { prependAssetHostItem } from 'common/utils'
import Image from 'react-graceful-image'
import { useMutation, useQuery } from 'react-query'
import { fetchTileChallenge } from 'API/fetchTileChallenge'
import { updateTileChallenge } from 'API/tiles/updateTileChallenge'
import { ErrorBoundary } from '@sentry/react'

type ImageType = {
    value: number
    description: string
}

interface MediaLaneProps {
    id: number
    type: ImageType
    challengeImages: IChallengeImage[]
    onAddAsset: (type: ImageType) => void
    refetchImages: () => void
    challengeId: number
}

const MediaLaneImage = ({
    image,
    refetchImages,
    challengeId
}: {
    image: IChallengeImage
    refetchImages: any
    challengeId: number
}) => {
    const tileChallengeQuery = useQuery(['tileChallenge', challengeId], () => fetchTileChallenge(challengeId))
    const [isModalVisible, setIsModalVisible] = useState(false)
    const deleteMutation = useMutation(deleteChallengeMedia, {
        onSuccess: () => refetchImages()
    })
    const url = image.muxAsset ? image.muxAsset.thumbnailUrl : image.challengeImageUrl

    const onDeleteClicked = async () => {
        if (
            tileChallengeQuery.data.challenge.tiles.some(
                (tile: { challengeVideoId: number }) => tile.challengeVideoId === image.challengeImageId
            )
        ) {
            const tileToDelete = tileChallengeQuery.data.challenge.tiles.findIndex(
                (tile: { challengeVideoId: number }) => tile.challengeVideoId === image.challengeImageId
            )
            const tiles = tileChallengeQuery.data.challenge.tiles
            tiles[tileToDelete].challengeVideoId = null
            await updateTileChallenge({ ...tileChallengeQuery.data.challenge, tiles })
        }

        deleteMutation.mutate({
            challengeId: image.challengeId,
            imageToDelete: image
        })
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listItems: any = [
        {
            title: 'ID',
            description: image.challengeImageId
        },
        {
            title: 'Type',
            description: image.challengeImageContentType
        },
        {
            title: 'Alt',
            description: image.altText ?? ''
        }
    ]

    if (image.muxAsset) {
        listItems.push({
            title: 'Mux Asset Id',
            // eslint-disable-next-line react/display-name
            description: (
                <a
                    href={`https://dashboard.mux.com/environments/${process.env.REACT_APP_MUX_ENVIRONMENT_ID}/video/assets/${image.muxAsset.assetId}`}
                    target="_new"
                >
                    {image.muxAsset.assetId}
                </a>
            )
        })
    }

    return (
        <>
            <EuiFlexItem grow={false}>
                <div
                    onClick={() => setIsModalVisible(true)}
                    className="euiImage euiImage--hasShadow euiImage--allowFullScreen euiImage--small"
                    style={{ cursor: 'pointer' }}
                >
                    <Image
                        className="euiImage__img"
                        src={prependAssetHostItem(url)}
                        alt="My awesome image"
                        retry={{ count: 10, delay: 2 }}
                        onLoad={() => console.log('onLoad')}
                        onError={() => console.log('onError')}
                    />
                </div>
                <EuiSpacer size="xs" />
                <EuiToolTip position="bottom" content={image.challengeImageUrl}>
                    <EuiBadge style={{ maxWidth: 120 }}>{image.challengeImageUrl}</EuiBadge>
                </EuiToolTip>
                <EuiSpacer size="s" />
                <EuiFlexItem grow={false}>
                    {deleteMutation.isError ? <div>An error occurred. Please try again.</div> : null}
                    {deleteMutation.isLoading ? (
                        <EuiLoadingSpinner size="m" />
                    ) : (
                        <EuiButtonIcon
                            onClick={() => onDeleteClicked()}
                            display="base"
                            iconType="trash"
                            aria-label="Delete"
                            color="danger"
                        />
                    )}
                </EuiFlexItem>
            </EuiFlexItem>
            {isModalVisible && (
                <EuiModal onClose={() => setIsModalVisible(false)}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <h1>{image.filename}</h1>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        {image.muxAsset ? (
                            <ErrorBoundary>
                                <ReactPlayer
                                    url={image.muxAsset.url}
                                    controls
                                    onError={(e, ...rest) => console.log('react player error', e, rest)}
                                />
                            </ErrorBoundary>
                        ) : (
                            <Image
                                className="euiImage__img"
                                src={prependAssetHostItem(url)}
                                alt="My awesome image"
                                retry={{ count: 10, delay: 2 }}
                                onLoad={() => console.log('onLoad')}
                                onError={() => console.log('onError')}
                            />
                        )}

                        <EuiSpacer />

                        <EuiDescriptionList textStyle="reverse" listItems={listItems} />
                    </EuiModalBody>
                </EuiModal>
            )}
        </>
    )
}

export const MediaLane = ({
    id,
    type,
    challengeImages,
    onAddAsset,
    refetchImages,
    challengeId
}: MediaLaneProps): JSX.Element => {
    console.log({ type })

    const getTypeDescription = (typeId: number) => {
        switch (typeId) {
            case 5:
                return (
                    <span>
                        This will serve as the hero image on the event&apos;s detail page (and sometimes the event index
                        page e.g /races). Please use an image that is <b>1040x585(16:9)</b>
                    </span>
                )
            case 8:
                return (
                    <span>
                        This will serve as the card image (both normal and featured) on web & mobile. Please use an
                        image that is <b>450x600(3:4)</b>
                    </span>
                )
            default:
                return ''
        }
    }

    return (
        <EuiDescribedFormGroup
            key={id}
            fullWidth
            descriptionFlexItemProps={{
                style: {
                    maxWidth: '300px'
                }
            }}
            title={<h3>{type.description}</h3>}
            description={<p>{getTypeDescription(type.value)}</p>}
        >
            {/* Render each uploaded item for this catergory. */}
            <EuiFlexGroup wrap gutterSize="xl">
                {challengeImages
                    ?.filter((i: IChallengeImage) => i.challengeImageTypeId === type.value)
                    .map((image: IChallengeImage, id: number) => {
                        return (
                            <MediaLaneImage
                                challengeId={challengeId}
                                refetchImages={refetchImages}
                                key={id}
                                image={image}
                            />
                        )
                    })}
                <EuiFlexItem grow={false}>
                    <EuiCard
                        style={{
                            maxWidth: 175
                        }}
                        icon={<EuiIcon size="l" type="plus" />}
                        title={`Add`}
                        titleSize="xs"
                        display="subdued"
                        description={`Add ${type.description} asset`}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.preventDefault()
                            onAddAsset(type)
                        }}
                    />
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="xl" />
        </EuiDescribedFormGroup>
    )
}
