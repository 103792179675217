import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { FC } from 'react'
import { LaneAdmin } from 'components/LaneAdmin'

type RacesPrioritizePageProps = RouteComponentProps

export const RacesPrioritizePage: FC<RacesPrioritizePageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.RACES }}>
            <LaneAdmin eventType={EVENT_TYPE.RACES} />
        </AdminPageLayout>
    )
}
