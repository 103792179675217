import React from 'react'
import create from 'zustand'
import { EuiGlobalToastList } from '@elastic/eui'
import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'

interface ToastsState {
    toasts: Array<Toast>
    addToast: (newToast: Toast) => void
    removeToast: (removedToast: Toast) => void
}

export const useToastsStore = create<ToastsState>((set) => ({
    toasts: [],
    addToast: (newToast) => set((state) => ({ toasts: [...state.toasts, newToast] })),
    removeToast: (removedToast) =>
        set((state) => ({
            toasts: state.toasts.filter((t) => t.id !== removedToast.id)
        }))
}))

export const GlobalToastList = () => {
    const toasts = useToastsStore((state) => state.toasts)
    const removeToast = useToastsStore((state) => state.removeToast)

    return <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={5000} />
}
