/* eslint-disable @typescript-eslint/no-explicit-any */
import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer, EuiStat, EuiText, EuiTitle } from '@elastic/eui'
import { useState } from 'react'

export const DetailStatHeader = () => {
    const [isLoading] = useState(false)

    return (
        <div>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>Number of views</h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>Number of registrations</h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>Number of completions</h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
                <EuiFlexItem>
                    <EuiPanel>
                        <EuiStat
                            title="--"
                            descriptionElement="div"
                            description={
                                <EuiTitle>
                                    <h3 style={{ textAlign: 'left' }}>Percentage of completions</h3>
                                </EuiTitle>
                            }
                            textAlign="right"
                            isLoading={isLoading}
                        >
                            <EuiText color="subdued">--%</EuiText>
                        </EuiStat>
                    </EuiPanel>
                </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
        </div>
    )
}
