export const NewUsersArray = [
    { g: 'New Users', x: '	2021-01-01	', y: 643 },
    { g: 'New Users', x: '	2021-01-02	', y: 1158 },
    { g: 'New Users', x: '	2021-01-03	', y: 1715 },
    { g: 'New Users', x: '	2021-01-04	', y: 2185 },
    { g: 'New Users', x: '	2021-01-05	', y: 2580 },
    { g: 'New Users', x: '	2021-01-06	', y: 2915 },
    { g: 'New Users', x: '	2021-01-07	', y: 3272 },
    { g: 'New Users', x: '	2021-01-08	', y: 3601 },
    { g: 'New Users', x: '	2021-01-09	', y: 3906 },
    { g: 'New Users', x: '	2021-01-10	', y: 4232 },
    { g: 'New Users', x: '	2021-01-11	', y: 4548 },
    { g: 'New Users', x: '	2021-01-12	', y: 4900 },
    { g: 'New Users', x: '	2021-01-13	', y: 5180 },
    { g: 'New Users', x: '	2021-01-14	', y: 5463 },
    { g: 'New Users', x: '	2021-01-15	', y: 5748 },
    { g: 'New Users', x: '	2021-01-16	', y: 6090 },
    { g: 'New Users', x: '	2021-01-17	', y: 6461 },
    { g: 'New Users', x: '	2021-01-18	', y: 6853 },
    { g: 'New Users', x: '	2021-01-19	', y: 7128 },
    { g: 'New Users', x: '	2021-01-20	', y: 7397 },
    { g: 'New Users', x: '	2021-01-21	', y: 7684 },
    { g: 'New Users', x: '	2021-01-22	', y: 7956 },
    { g: 'New Users', x: '	2021-01-23	', y: 8236 },
    { g: 'New Users', x: '	2021-01-24	', y: 8564 },
    { g: 'New Users', x: '	2021-01-25	', y: 8808 },
    { g: 'New Users', x: '	2021-01-26	', y: 8923 },
    { g: 'New Users', x: '	2021-01-27	', y: 9026 },
    { g: 'New Users', x: '	2021-01-28	', y: 9105 },
    { g: 'New Users', x: '	2021-01-29	', y: 9422 },
    { g: 'New Users', x: '	2021-01-30	', y: 9975 },
    { g: 'New Users', x: '	2021-01-31	', y: 10596 },
    { g: 'New Users', x: '	2021-02-01	', y: 11176 },
    { g: 'New Users', x: '	2021-02-02	', y: 11458 },
    { g: 'New Users', x: '	2021-02-03	', y: 11773 },
    { g: 'New Users', x: '	2021-02-04	', y: 12041 },
    { g: 'New Users', x: '	2021-02-05	', y: 12283 },
    { g: 'New Users', x: '	2021-02-06	', y: 12628 },
    { g: 'New Users', x: '	2021-02-07	', y: 13065 },
    { g: 'New Users', x: '	2021-02-08	', y: 13438 },
    { g: 'New Users', x: '	2021-02-09	', y: 13745 },
    { g: 'New Users', x: '	2021-02-10	', y: 14019 },
    { g: 'New Users', x: '	2021-02-11	', y: 14309 },
    { g: 'New Users', x: '	2021-02-12	', y: 14606 },
    { g: 'New Users', x: '	2021-02-13	', y: 14985 },
    { g: 'New Users', x: '	2021-02-14	', y: 15407 },
    { g: 'New Users', x: '	2021-02-15	', y: 15802 },
    { g: 'New Users', x: '	2021-02-16	', y: 16010 },
    { g: 'New Users', x: '	2021-02-17	', y: 16340 },
    { g: 'New Users', x: '	2021-02-18	', y: 16701 },
    { g: 'New Users', x: '	2021-02-19	', y: 17115 },
    { g: 'New Users', x: '	2021-02-20	', y: 17473 },
    { g: 'New Users', x: '	2021-02-21	', y: 17937 },
    { g: 'New Users', x: '	2021-02-22	', y: 18333 },
    { g: 'New Users', x: '	2021-02-23	', y: 18677 },
    { g: 'New Users', x: '	2021-02-24	', y: 19056 },
    { g: 'New Users', x: '	2021-02-25	', y: 19411 },
    { g: 'New Users', x: '	2021-02-26	', y: 19715 },
    { g: 'New Users', x: '	2021-02-27	', y: 20034 },
    { g: 'New Users', x: '	2021-02-28	', y: 20478 },
    { g: 'New Users', x: '	2021-03-01	', y: 21004 },
    { g: 'New Users', x: '	2021-03-02	', y: 21484 },
    { g: 'New Users', x: '	2021-03-03	', y: 21930 },
    { g: 'New Users', x: '	2021-03-04	', y: 22410 },
    { g: 'New Users', x: '	2021-03-05	', y: 22809 },
    { g: 'New Users', x: '	2021-03-06	', y: 23307 },
    { g: 'New Users', x: '	2021-03-07	', y: 23885 },
    { g: 'New Users', x: '	2021-03-08	', y: 24406 },
    { g: 'New Users', x: '	2021-03-09	', y: 24941 },
    { g: 'New Users', x: '	2021-03-10	', y: 25392 },
    { g: 'New Users', x: '	2021-03-11	', y: 25865 },
    { g: 'New Users', x: '	2021-03-12	', y: 26265 },
    { g: 'New Users', x: '	2021-03-13	', y: 26702 },
    { g: 'New Users', x: '	2021-03-14	', y: 27163 },
    { g: 'New Users', x: '	2021-03-15	', y: 27532 },
    { g: 'New Users', x: '	2021-03-16	', y: 27906 },
    { g: 'New Users', x: '	2021-03-17	', y: 28238 },
    { g: 'New Users', x: '	2021-03-18	', y: 28600 },
    { g: 'New Users', x: '	2021-03-19	', y: 28913 },
    { g: 'New Users', x: '	2021-03-20	', y: 29288 },
    { g: 'New Users', x: '	2021-03-21	', y: 29729 },
    { g: 'New Users', x: '	2021-03-22	', y: 30143 },
    { g: 'New Users', x: '	2021-03-23	', y: 30552 },
    { g: 'New Users', x: '	2021-03-24	', y: 30911 },
    { g: 'New Users', x: '	2021-03-25	', y: 31267 },
    { g: 'New Users', x: '	2021-03-26	', y: 31951 },
    { g: 'New Users', x: '	2021-03-27	', y: 32600 },
    { g: 'New Users', x: '	2021-03-28	', y: 33273 },
    { g: 'New Users', x: '	2021-03-29	', y: 33862 },
    { g: 'New Users', x: '	2021-03-30	', y: 34372 },
    { g: 'New Users', x: '	2021-03-31	', y: 34805 },
    { g: 'New Users', x: '	2021-04-01	', y: 35276 },
    { g: 'New Users', x: '	2021-04-02	', y: 35772 },
    { g: 'New Users', x: '	2021-04-03	', y: 36196 },
    { g: 'New Users', x: '	2021-04-04	', y: 36656 },
    { g: 'New Users', x: '	2021-04-05	', y: 37144 },
    { g: 'New Users', x: '	2021-04-06	', y: 37913 },
    { g: 'New Users', x: '	2021-04-07	', y: 38466 },
    { g: 'New Users', x: '	2021-04-08	', y: 39025 },
    { g: 'New Users', x: '	2021-04-09	', y: 39614 },
    { g: 'New Users', x: '	2021-04-10	', y: 40144 },
    { g: 'New Users', x: '	2021-04-11	', y: 40652 },
    { g: 'New Users', x: '	2021-04-12	', y: 41241 },
    { g: 'New Users', x: '	2021-04-13	', y: 41759 },
    { g: 'New Users', x: '	2021-04-14	', y: 42208 },
    { g: 'New Users', x: '	2021-04-15	', y: 42652 },
    { g: 'New Users', x: '	2021-04-16	', y: 43199 },
    { g: 'New Users', x: '	2021-04-17	', y: 43769 },
    { g: 'New Users', x: '	2021-04-18	', y: 44496 },
    { g: 'New Users', x: '	2021-04-19	', y: 45208 },
    { g: 'New Users', x: '	2021-04-20	', y: 45950 },
    { g: 'New Users', x: '	2021-04-21	', y: 46591 },
    { g: 'New Users', x: '	2021-04-22	', y: 47090 },
    { g: 'New Users', x: '	2021-04-23	', y: 47612 },
    { g: 'New Users', x: '	2021-04-24	', y: 48111 },
    { g: 'New Users', x: '	2021-04-25	', y: 48712 },
    { g: 'New Users', x: '	2021-04-26	', y: 49364 },
    { g: 'New Users', x: '	2021-04-27	', y: 49951 },
    { g: 'New Users', x: '	2021-04-28	', y: 50458 },
    { g: 'New Users', x: '	2021-04-29	', y: 50942 },
    { g: 'New Users', x: '	2021-04-30	', y: 51348 },
    { g: 'New Users', x: '	2021-05-01	', y: 51811 },
    { g: 'New Users', x: '	2021-05-02	', y: 52313 },
    { g: 'New Users', x: '	2021-05-03	', y: 52884 },
    { g: 'New Users', x: '	2021-05-04	', y: 53677 },
    { g: 'New Users', x: '	2021-05-05	', y: 54383 },
    { g: 'New Users', x: '	2021-05-06	', y: 54990 },
    { g: 'New Users', x: '	2021-05-07	', y: 55537 },
    { g: 'New Users', x: '	2021-05-08	', y: 56077 },
    { g: 'New Users', x: '	2021-05-09	', y: 56598 },
    { g: 'New Users', x: '	2021-05-10	', y: 57099 },
    { g: 'New Users', x: '	2021-05-11	', y: 57631 },
    { g: 'New Users', x: '	2021-05-12	', y: 58099 },
    { g: 'New Users', x: '	2021-05-13	', y: 58581 },
    { g: 'New Users', x: '	2021-05-14	', y: 59122 },
    { g: 'New Users', x: '	2021-05-15	', y: 59639 },
    { g: 'New Users', x: '	2021-05-16	', y: 60222 },
    { g: 'New Users', x: '	2021-05-17	', y: 60756 },
    { g: 'New Users', x: '	2021-05-18	', y: 61332 },
    { g: 'New Users', x: '	2021-05-19	', y: 61832 },
    { g: 'New Users', x: '	2021-05-20	', y: 62357 },
    { g: 'New Users', x: '	2021-05-21	', y: 62699 },
    { g: 'New Users', x: '	2021-05-22	', y: 63051 },
    { g: 'New Users', x: '	2021-05-23	', y: 63433 },
    { g: 'New Users', x: '	2021-05-24	', y: 63834 },
    { g: 'New Users', x: '	2021-05-25	', y: 64192 },
    { g: 'New Users', x: '	2021-05-26	', y: 64606 },
    { g: 'New Users', x: '	2021-05-27	', y: 65016 },
    { g: 'New Users', x: '	2021-05-28	', y: 65357 },
    { g: 'New Users', x: '	2021-05-29	', y: 65776 },
    { g: 'New Users', x: '	2021-05-30	', y: 66150 },
    { g: 'New Users', x: '	2021-05-31	', y: 66575 },
    { g: 'New Users', x: '	2021-06-01	', y: 67033 },
    { g: 'New Users', x: '	2021-06-02	', y: 67431 },
    { g: 'New Users', x: '	2021-06-03	', y: 67771 },
    { g: 'New Users', x: '	2021-06-04	', y: 68106 },
    { g: 'New Users', x: '	2021-06-05	', y: 68443 },
    { g: 'New Users', x: '	2021-06-06	', y: 68808 },
    { g: 'New Users', x: '	2021-06-07	', y: 69234 },
    { g: 'New Users', x: '	2021-06-08	', y: 69603 },
    { g: 'New Users', x: '	2021-06-09	', y: 69714 }
]
