export const RealRevenueArray = [
    { g: 'Revenue', x: '2021-01-01', y: 59563.69 },
    { g: 'Revenue', x: '2021-01-02', y: 96510.3 },
    { g: 'Revenue', x: '2021-01-03', y: 128387.29 },
    { g: 'Revenue', x: '2021-01-04', y: 155069.8 },
    { g: 'Revenue', x: '2021-01-05', y: 175548.64 },
    { g: 'Revenue', x: '2021-01-06', y: 193051.59 },
    { g: 'Revenue', x: '2021-01-07', y: 208053.3 },
    { g: 'Revenue', x: '2021-01-08', y: 229715.97 },
    { g: 'Revenue', x: '2021-01-09', y: 248531.48 },
    { g: 'Revenue', x: '2021-01-10', y: 267452.31 },
    { g: 'Revenue', x: '2021-01-11', y: 286889.67 },
    { g: 'Revenue', x: '2021-01-12', y: 305285.23 },
    { g: 'Revenue', x: '2021-01-13', y: 325656.18 },
    { g: 'Revenue', x: '2021-01-14', y: 341771.29 },
    { g: 'Revenue', x: '2021-01-15', y: 365013.41 },
    { g: 'Revenue', x: '2021-01-16', y: 385379.93 },
    { g: 'Revenue', x: '2021-01-17', y: 404943.22 },
    { g: 'Revenue', x: '2021-01-18', y: 430160.74 },
    { g: 'Revenue', x: '2021-01-19', y: 447121.57 },
    { g: 'Revenue', x: '2021-01-20', y: 461073.08 },
    { g: 'Revenue', x: '2021-01-21', y: 475321.55 },
    { g: 'Revenue', x: '2021-01-22', y: 494389.81 },
    { g: 'Revenue', x: '2021-01-23', y: 510532.07 },
    { g: 'Revenue', x: '2021-01-24', y: 528522.09 },
    { g: 'Revenue', x: '2021-01-25', y: 545968.33 },
    { g: 'Revenue', x: '2021-01-26', y: 558559.85 },
    { g: 'Revenue', x: '2021-01-27', y: 573959.1 },
    { g: 'Revenue', x: '2021-01-28', y: 584758.68 },
    { g: 'Revenue', x: '2021-01-29', y: 602989.77 },
    { g: 'Revenue', x: '2021-01-30', y: 623786.36 },
    { g: 'Revenue', x: '2021-01-31', y: 644551.12 },
    { g: 'Revenue', x: '2021-02-01', y: 676050.73 },
    { g: 'Revenue', x: '2021-02-02', y: 695375.34 },
    { g: 'Revenue', x: '2021-02-03', y: 712359.18 },
    { g: 'Revenue', x: '2021-02-04', y: 726368.47 },
    { g: 'Revenue', x: '2021-02-05', y: 746409.42 },
    { g: 'Revenue', x: '2021-02-06', y: 765037.49 },
    { g: 'Revenue', x: '2021-02-07', y: 782121.64 },
    { g: 'Revenue', x: '2021-02-08', y: 803962.22 },
    { g: 'Revenue', x: '2021-02-09', y: 820299.98 },
    { g: 'Revenue', x: '2021-02-10', y: 845832.68 },
    { g: 'Revenue', x: '2021-02-11', y: 861994.61 },
    { g: 'Revenue', x: '2021-02-12', y: 894114.16 },
    { g: 'Revenue', x: '2021-02-13', y: 913459.34 },
    { g: 'Revenue', x: '2021-02-14', y: 934212.17 },
    { g: 'Revenue', x: '2021-02-15', y: 962368.4 },
    { g: 'Revenue', x: '2021-02-16', y: 982654.54 },
    { g: 'Revenue', x: '2021-02-17', y: 1005957.3 },
    { g: 'Revenue', x: '2021-02-18', y: 1023229.84 },
    { g: 'Revenue', x: '2021-02-19', y: 1042648.98 },
    { g: 'Revenue', x: '2021-02-20', y: 1058734.53 },
    { g: 'Revenue', x: '2021-02-21', y: 1077234.39 },
    { g: 'Revenue', x: '2021-02-22', y: 1093351.45 },
    { g: 'Revenue', x: '2021-02-23', y: 1108968.94 },
    { g: 'Revenue', x: '2021-02-24', y: 1148727.2 },
    { g: 'Revenue', x: '2021-02-25', y: 1173840.66 },
    { g: 'Revenue', x: '2021-02-26', y: 1200221.7 },
    { g: 'Revenue', x: '2021-02-27', y: 1222556.2 },
    { g: 'Revenue', x: '2021-02-28', y: 1248846.77 },
    { g: 'Revenue', x: '2021-03-01', y: 1280668.24 },
    { g: 'Revenue', x: '2021-03-02', y: 1303218.2 },
    { g: 'Revenue', x: '2021-03-03', y: 1323893.84 },
    { g: 'Revenue', x: '2021-03-04', y: 1343846.7 },
    { g: 'Revenue', x: '2021-03-05', y: 1365629.08 },
    { g: 'Revenue', x: '2021-03-06', y: 1386420.09 },
    { g: 'Revenue', x: '2021-03-07', y: 1408329.76 },
    { g: 'Revenue', x: '2021-03-08', y: 1430142.34 },
    { g: 'Revenue', x: '2021-03-09', y: 1448534.51 },
    { g: 'Revenue', x: '2021-03-10', y: 1473253.43 },
    { g: 'Revenue', x: '2021-03-11', y: 1493609.68 },
    { g: 'Revenue', x: '2021-03-12', y: 1517498.74 },
    { g: 'Revenue', x: '2021-03-13', y: 1538656.68 },
    { g: 'Revenue', x: '2021-03-14', y: 1561670.7 },
    { g: 'Revenue', x: '2021-03-15', y: 1588937.11 },
    { g: 'Revenue', x: '2021-03-16', y: 1608278.25 },
    { g: 'Revenue', x: '2021-03-17', y: 1628294.49 },
    { g: 'Revenue', x: '2021-03-18', y: 1649730.55 },
    { g: 'Revenue', x: '2021-03-19', y: 1680362.43 },
    { g: 'Revenue', x: '2021-03-20', y: 1706489.93 },
    { g: 'Revenue', x: '2021-03-21', y: 1730864.39 },
    { g: 'Revenue', x: '2021-03-22', y: 1765944.13 },
    { g: 'Revenue', x: '2021-03-23', y: 1788870.37 },
    { g: 'Revenue', x: '2021-03-24', y: 1814894.19 },
    { g: 'Revenue', x: '2021-03-25', y: 1839152.75 },
    { g: 'Revenue', x: '2021-03-26', y: 1873584.41 },
    { g: 'Revenue', x: '2021-03-27', y: 1900524.17 },
    { g: 'Revenue', x: '2021-03-28', y: 1924703.58 },
    { g: 'Revenue', x: '2021-03-29', y: 1954413.19 },
    { g: 'Revenue', x: '2021-03-30', y: 1977574.73 },
    { g: 'Revenue', x: '2021-03-31', y: 1996853.77 },
    { g: 'Revenue', x: '2021-04-01', y: 2022169.64 },
    { g: 'Revenue', x: '2021-04-02', y: 2063870.84 },
    { g: 'Revenue', x: '2021-04-03', y: 2088530.94 },
    { g: 'Revenue', x: '2021-04-04', y: 2110553.99 },
    { g: 'Revenue', x: '2021-04-05', y: 2140205.72 },
    { g: 'Revenue', x: '2021-04-06', y: 2162810.78 },
    { g: 'Revenue', x: '2021-04-07', y: 2192538.41 },
    { g: 'Revenue', x: '2021-04-08', y: 2213156.79 },
    { g: 'Revenue', x: '2021-04-09', y: 2238834.03 },
    { g: 'Revenue', x: '2021-04-10', y: 2260200.11 },
    { g: 'Revenue', x: '2021-04-11', y: 2279095.09 },
    { g: 'Revenue', x: '2021-04-12', y: 2302945.33 },
    { g: 'Revenue', x: '2021-04-13', y: 2321001.41 },
    { g: 'Revenue', x: '2021-04-14', y: 2337194.52 },
    { g: 'Revenue', x: '2021-04-15', y: 2353653.49 },
    { g: 'Revenue', x: '2021-04-16', y: 2381843.32 },
    { g: 'Revenue', x: '2021-04-17', y: 2401235.44 },
    { g: 'Revenue', x: '2021-04-18', y: 2422934.72 },
    { g: 'Revenue', x: '2021-04-19', y: 2450786.53 },
    { g: 'Revenue', x: '2021-04-20', y: 2473058.04 },
    { g: 'Revenue', x: '2021-04-21', y: 2488869.77 },
    { g: 'Revenue', x: '2021-04-22', y: 2504434.68 },
    { g: 'Revenue', x: '2021-04-23', y: 2546408.9 },
    { g: 'Revenue', x: '2021-04-24', y: 2577083.59 },
    { g: 'Revenue', x: '2021-04-25', y: 2601145.58 },
    { g: 'Revenue', x: '2021-04-26', y: 2631633.26 },
    { g: 'Revenue', x: '2021-04-27', y: 2655670.17 },
    { g: 'Revenue', x: '2021-04-28', y: 2678827.74 },
    { g: 'Revenue', x: '2021-04-29', y: 2694816.77 },
    { g: 'Revenue', x: '2021-04-30', y: 2718872.45 },
    { g: 'Revenue', x: '2021-05-01', y: 2740324.29 },
    { g: 'Revenue', x: '2021-05-02', y: 2760617.56 },
    { g: 'Revenue', x: '2021-05-03', y: 2783083.67 },
    { g: 'Revenue', x: '2021-05-04', y: 2800684.26 },
    { g: 'Revenue', x: '2021-05-05', y: 2823378.1 },
    { g: 'Revenue', x: '2021-05-06', y: 2840531.1 },
    { g: 'Revenue', x: '2021-05-07', y: 2862903.46 },
    { g: 'Revenue', x: '2021-05-08', y: 2880712.65 },
    { g: 'Revenue', x: '2021-05-09', y: 2896636.12 },
    { g: 'Revenue', x: '2021-05-10', y: 2918600.68 },
    { g: 'Revenue', x: '2021-05-11', y: 2936250.95 },
    { g: 'Revenue', x: '2021-05-12', y: 2950266.03 },
    { g: 'Revenue', x: '2021-05-13', y: 2962941.21 },
    { g: 'Revenue', x: '2021-05-14', y: 2988667.89 },
    { g: 'Revenue', x: '2021-05-15', y: 3007751.74 },
    { g: 'Revenue', x: '2021-05-16', y: 3024187.16 },
    { g: 'Revenue', x: '2021-05-17', y: 3043266.18 },
    { g: 'Revenue', x: '2021-05-18', y: 3063096.52 },
    { g: 'Revenue', x: '2021-05-19', y: 3078970.47 },
    { g: 'Revenue', x: '2021-05-20', y: 3092950.53 },
    { g: 'Revenue', x: '2021-05-21', y: 3110309.2 },
    { g: 'Revenue', x: '2021-05-22', y: 3125675.27 },
    { g: 'Revenue', x: '2021-05-23', y: 3139821.77 },
    { g: 'Revenue', x: '2021-05-24', y: 3161819.34 },
    { g: 'Revenue', x: '2021-05-25', y: 3181092.41 },
    { g: 'Revenue', x: '2021-05-26', y: 3235657.45 },
    { g: 'Revenue', x: '2021-05-27', y: 3266499.53 },
    { g: 'Revenue', x: '2021-05-28', y: 3322032.27 },
    { g: 'Revenue', x: '2021-05-29', y: 3351111.32 },
    { g: 'Revenue', x: '2021-05-30', y: 3373737.73 },
    { g: 'Revenue', x: '2021-05-31', y: 3408846.79 },
    { g: 'Revenue', x: '2021-06-01', y: 3445113.59 },
    { g: 'Revenue', x: '2021-06-02', y: 3485762.21 },
    { g: 'Revenue', x: '2021-06-03', y: 3516213.29 },
    { g: 'Revenue', x: '2021-06-04', y: 3543894.18 },
    { g: 'Revenue', x: '2021-06-05', y: 3565185.55 },
    { g: 'Revenue', x: '2021-06-06', y: 3582004.4 },
    { g: 'Revenue', x: '2021-06-07', y: 3610904.27 },
    { g: 'Revenue', x: '2021-06-08', y: 3633357.93 },
    { g: 'Revenue', x: '2021-06-09', y: 3661032.27 },
    { g: 'Revenue', x: '2021-06-10', y: 3680622.52 },
    { g: 'Revenue', x: '2021-06-11', y: 3717443.39 },
    { g: 'Revenue', x: '2021-06-12', y: 3742175.48 },
    { g: 'Revenue', x: '2021-06-13', y: 3761907.79 },
    { g: 'Revenue', x: '2021-06-14', y: 3793192.42 },
    { g: 'Revenue', x: '2021-06-15', y: 3818152.96 },
    { g: 'Revenue', x: '2021-06-16', y: 3839780 },
    { g: 'Revenue', x: '2021-06-17', y: 3861320.91 },
    { g: 'Revenue', x: '2021-06-18', y: 3882134.27 },
    { g: 'Revenue', x: '2021-06-19', y: 3900079.03 },
    { g: 'Revenue', x: '2021-06-20', y: 3918854.21 },
    { g: 'Revenue', x: '2021-06-21', y: 3943752.9 },
    { g: 'Revenue', x: '2021-06-22', y: 3974880.48 },
    { g: 'Revenue', x: '2021-06-23', y: 4000851.01 },
    { g: 'Revenue', x: '2021-06-24', y: 4017492.16 },
    { g: 'Revenue', x: '2021-06-25', y: 4037352.41 },
    { g: 'Revenue', x: '2021-06-26', y: 4054122.78 },
    { g: 'Revenue', x: '2021-06-27', y: 4070807.43 },
    { g: 'Revenue', x: '2021-06-28', y: 4092091.33 },
    { g: 'Revenue', x: '2021-06-29', y: 4112337.95 },
    { g: 'Revenue', x: '2021-06-30', y: 4138938.95 },
    { g: 'Revenue', x: '2021-07-01', y: 4165776.15 },
    { g: 'Revenue', x: '2021-07-02', y: 4192443.7 },
    { g: 'Revenue', x: '2021-07-03', y: 4214986.62 },
    { g: 'Revenue', x: '2021-07-04', y: 4233780.17 },
    { g: 'Revenue', x: '2021-07-05', y: 4259299.95 },
    { g: 'Revenue', x: '2021-07-06', y: 4280188.77 },
    { g: 'Revenue', x: '2021-07-07', y: 4304429.36 },
    { g: 'Revenue', x: '2021-07-08', y: 4323376.78 },
    { g: 'Revenue', x: '2021-07-09', y: 4352385 },
    { g: 'Revenue', x: '2021-07-10', y: 4375492.27 },
    { g: 'Revenue', x: '2021-07-11', y: 4397412.88 },
    { g: 'Revenue', x: '2021-07-12', y: 4426106.12 },
    { g: 'Revenue', x: '2021-07-13', y: 4450530.07 },
    { g: 'Revenue', x: '2021-07-14', y: 4469570.85 },
    { g: 'Revenue', x: '2021-07-15', y: 4487875.37 },
    { g: 'Revenue', x: '2021-07-16', y: 4506584.2 },
    { g: 'Revenue', x: '2021-07-17', y: 4520578.09 },
    { g: 'Revenue', x: '2021-07-18', y: 4533806.98 },
    { g: 'Revenue', x: '2021-07-19', y: 4550231.84 },
    { g: 'Revenue', x: '2021-07-20', y: 4567240.31 },
    { g: 'Revenue', x: '2021-07-21', y: 4585312.89 },
    { g: 'Revenue', x: '2021-07-22', y: 4602483.25 },
    { g: 'Revenue', x: '2021-07-23', y: 4623495.07 },
    { g: 'Revenue', x: '2021-07-24', y: 4641419.63 },
    { g: 'Revenue', x: '2021-07-25', y: 4654809.75 },
    { g: 'Revenue', x: '2021-07-26', y: 4677894.13 },
    { g: 'Revenue', x: '2021-07-27', y: 4696903.97 },
    { g: 'Revenue', x: '2021-07-28', y: 4722153.35 },
    { g: 'Revenue', x: '2021-07-29', y: 4742527.37 },
    { g: 'Revenue', x: '2021-07-30', y: 4764752.98 },
    { g: 'Revenue', x: '2021-07-31', y: 4789631.78 },
    { g: 'Revenue', x: '2021-08-01', y: 4807481.92 },
    { g: 'Revenue', x: '2021-08-02', y: 4826238.8 },
    { g: 'Revenue', x: '2021-08-03', y: 4846053.77 },
    { g: 'Revenue', x: '2021-08-04', y: 4867563.93 },
    { g: 'Revenue', x: '2021-08-05', y: 4884840.08 },
    { g: 'Revenue', x: '2021-08-06', y: 4901943.63 },
    { g: 'Revenue', x: '2021-08-07', y: 4918285.8 },
    { g: 'Revenue', x: '2021-08-08', y: 4932657.54 },
    { g: 'Revenue', x: '2021-08-09', y: 4949421.47 },
    { g: 'Revenue', x: '2021-08-10', y: 4964935.89 },
    { g: 'Revenue', x: '2021-08-11', y: 4978898.1 },
    { g: 'Revenue', x: '2021-08-12', y: 4993652.2 },
    { g: 'Revenue', x: '2021-08-13', y: 5011870.7 },
    { g: 'Revenue', x: '2021-08-14', y: 5027586.54 },
    { g: 'Revenue', x: '2021-08-15', y: 5042478.43 },
    { g: 'Revenue', x: '2021-08-16', y: 5061808.72 },
    { g: 'Revenue', x: '2021-08-17', y: 5074815.39 },
    { g: 'Revenue', x: '2021-08-18', y: 5090167.35 },
    { g: 'Revenue', x: '2021-08-19', y: 5103621.47 },
    { g: 'Revenue', x: '2021-08-20', y: 5120251.44 },
    { g: 'Revenue', x: '2021-08-21', y: 5134735.56 },
    { g: 'Revenue', x: '2021-08-22', y: 5153354.05 },
    { g: 'Revenue', x: '2021-08-23', y: 5180522.96 },
    { g: 'Revenue', x: '2021-08-24', y: 5195843.84 },
    { g: 'Revenue', x: '2021-08-25', y: 5212256.39 },
    { g: 'Revenue', x: '2021-08-26', y: 5230048.76 },
    { g: 'Revenue', x: '2021-08-27', y: 5255769.8 },
    { g: 'Revenue', x: '2021-08-28', y: 5274015.45 },
    { g: 'Revenue', x: '2021-08-29', y: 5289905.1 },
    { g: 'Revenue', x: '2021-08-30', y: 5306175.18 },
    { g: 'Revenue', x: '2021-08-31', y: 5318720.45 },
    { g: 'Revenue', x: '2021-09-01', y: 5334036.34 },
    { g: 'Revenue', x: '2021-09-02', y: 5349483.69 },
    { g: 'Revenue', x: '2021-09-03', y: 5367970.26 },
    { g: 'Revenue', x: '2021-09-04', y: 5382490.04 },
    { g: 'Revenue', x: '2021-09-05', y: 5397798.56 },
    { g: 'Revenue', x: '2021-09-06', y: 5419337.97 },
    { g: 'Revenue', x: '2021-09-07', y: 5433341.68 },
    { g: 'Revenue', x: '2021-09-08', y: 5449555.94 },
    { g: 'Revenue', x: '2021-09-09', y: 5463052.62 },
    { g: 'Revenue', x: '2021-09-10', y: 5484144.13 },
    { g: 'Revenue', x: '2021-09-11', y: 5498204.6 },
    { g: 'Revenue', x: '2021-09-12', y: 5516362.87 },
    { g: 'Revenue', x: '2021-09-13', y: 5534620.81 },
    { g: 'Revenue', x: '2021-09-14', y: 5546851.32 },
    { g: 'Revenue', x: '2021-09-15', y: 5559275.26 },
    { g: 'Revenue', x: '2021-09-16', y: 5571148.16 },
    { g: 'Revenue', x: '2021-09-17', y: 5584380.99 },
    { g: 'Revenue', x: '2021-09-18', y: 5595201.4 },
    { g: 'Revenue', x: '2021-09-19', y: 5604855.59 },
    { g: 'Revenue', x: '2021-09-20', y: 5618410.56 },
    { g: 'Revenue', x: '2021-09-21', y: 5625952.63 },
    { g: 'Revenue', x: '2021-09-22', y: 5633319.78 },
    { g: 'Revenue', x: '2021-09-23', y: 5641911.92 },
    { g: 'Revenue', x: '2021-09-24', y: 5670761.89 },
    { g: 'Revenue', x: '2021-09-25', y: 5697379.82 },
    { g: 'Revenue', x: '2021-09-26', y: 5719298.47 },
    { g: 'Revenue', x: '2021-09-27', y: 5740465.38 },
    { g: 'Revenue', x: '2021-09-28', y: 5756938.35 },
    { g: 'Revenue', x: '2021-09-29', y: 5783647.23 },
    { g: 'Revenue', x: '2021-09-30', y: null },
    { g: 'Revenue', x: '2021-10-01', y: null },
    { g: 'Revenue', x: '2021-10-02', y: null },
    { g: 'Revenue', x: '2021-10-03', y: null },
    { g: 'Revenue', x: '2021-10-04', y: null },
    { g: 'Revenue', x: '2021-10-05', y: null },
    { g: 'Revenue', x: '2021-10-06', y: null },
    { g: 'Revenue', x: '2021-10-07', y: null },
    { g: 'Revenue', x: '2021-10-08', y: null },
    { g: 'Revenue', x: '2021-10-09', y: null },
    { g: 'Revenue', x: '2021-10-10', y: null },
    { g: 'Revenue', x: '2021-10-11', y: null },
    { g: 'Revenue', x: '2021-10-12', y: null },
    { g: 'Revenue', x: '2021-10-13', y: null },
    { g: 'Revenue', x: '2021-10-14', y: null },
    { g: 'Revenue', x: '2021-10-15', y: null },
    { g: 'Revenue', x: '2021-10-16', y: null },
    { g: 'Revenue', x: '2021-10-17', y: null },
    { g: 'Revenue', x: '2021-10-18', y: null },
    { g: 'Revenue', x: '2021-10-19', y: null },
    { g: 'Revenue', x: '2021-10-20', y: null },
    { g: 'Revenue', x: '2021-10-21', y: null },
    { g: 'Revenue', x: '2021-10-22', y: null },
    { g: 'Revenue', x: '2021-10-23', y: null },
    { g: 'Revenue', x: '2021-10-24', y: null },
    { g: 'Revenue', x: '2021-10-25', y: null },
    { g: 'Revenue', x: '2021-10-26', y: null },
    { g: 'Revenue', x: '2021-10-27', y: null },
    { g: 'Revenue', x: '2021-10-28', y: null },
    { g: 'Revenue', x: '2021-10-29', y: null },
    { g: 'Revenue', x: '2021-10-30', y: null },
    { g: 'Revenue', x: '2021-10-31', y: null },
    { g: 'Revenue', x: '2021-11-01', y: null },
    { g: 'Revenue', x: '2021-11-02', y: null },
    { g: 'Revenue', x: '2021-11-03', y: null },
    { g: 'Revenue', x: '2021-11-04', y: null },
    { g: 'Revenue', x: '2021-11-05', y: null },
    { g: 'Revenue', x: '2021-11-06', y: null },
    { g: 'Revenue', x: '2021-11-07', y: null },
    { g: 'Revenue', x: '2021-11-08', y: null },
    { g: 'Revenue', x: '2021-11-09', y: null },
    { g: 'Revenue', x: '2021-11-10', y: null },
    { g: 'Revenue', x: '2021-11-11', y: null },
    { g: 'Revenue', x: '2021-11-12', y: null },
    { g: 'Revenue', x: '2021-11-13', y: null },
    { g: 'Revenue', x: '2021-11-14', y: null },
    { g: 'Revenue', x: '2021-11-15', y: null },
    { g: 'Revenue', x: '2021-11-16', y: null },
    { g: 'Revenue', x: '2021-11-17', y: null },
    { g: 'Revenue', x: '2021-11-18', y: null },
    { g: 'Revenue', x: '2021-11-19', y: null },
    { g: 'Revenue', x: '2021-11-20', y: null },
    { g: 'Revenue', x: '2021-11-21', y: null },
    { g: 'Revenue', x: '2021-11-22', y: null },
    { g: 'Revenue', x: '2021-11-23', y: null },
    { g: 'Revenue', x: '2021-11-24', y: null },
    { g: 'Revenue', x: '2021-11-25', y: null },
    { g: 'Revenue', x: '2021-11-26', y: null },
    { g: 'Revenue', x: '2021-11-27', y: null },
    { g: 'Revenue', x: '2021-11-28', y: null },
    { g: 'Revenue', x: '2021-11-29', y: null },
    { g: 'Revenue', x: '2021-11-30', y: null },
    { g: 'Revenue', x: '2021-12-01', y: null },
    { g: 'Revenue', x: '2021-12-02', y: null },
    { g: 'Revenue', x: '2021-12-03', y: null },
    { g: 'Revenue', x: '2021-12-04', y: null },
    { g: 'Revenue', x: '2021-12-05', y: null },
    { g: 'Revenue', x: '2021-12-06', y: null },
    { g: 'Revenue', x: '2021-12-07', y: null },
    { g: 'Revenue', x: '2021-12-08', y: null },
    { g: 'Revenue', x: '2021-12-09', y: null },
    { g: 'Revenue', x: '2021-12-10', y: null },
    { g: 'Revenue', x: '2021-12-11', y: null },
    { g: 'Revenue', x: '2021-12-12', y: null },
    { g: 'Revenue', x: '2021-12-13', y: null },
    { g: 'Revenue', x: '2021-12-14', y: null },
    { g: 'Revenue', x: '2021-12-15', y: null },
    { g: 'Revenue', x: '2021-12-16', y: null },
    { g: 'Revenue', x: '2021-12-17', y: null },
    { g: 'Revenue', x: '2021-12-18', y: null },
    { g: 'Revenue', x: '2021-12-19', y: null },
    { g: 'Revenue', x: '2021-12-20', y: null },
    { g: 'Revenue', x: '2021-12-21', y: null },
    { g: 'Revenue', x: '2021-12-22', y: null },
    { g: 'Revenue', x: '2021-12-23', y: null },
    { g: 'Revenue', x: '2021-12-24', y: null },
    { g: 'Revenue', x: '2021-12-25', y: null },
    { g: 'Revenue', x: '2021-12-26', y: null },
    { g: 'Revenue', x: '2021-12-27', y: null },
    { g: 'Revenue', x: '2021-12-28', y: null },
    { g: 'Revenue', x: '2021-12-29', y: null },
    { g: 'Revenue', x: '2021-12-30', y: null },
    { g: 'Revenue', x: '2021-12-31', y: null },

    { g: 'FitMetric', x: '2021-01-01', y: 33973 },
    { g: 'FitMetric', x: '2021-01-02', y: 67946 },
    { g: 'FitMetric', x: '2021-01-03', y: 101919 },
    { g: 'FitMetric', x: '2021-01-04', y: 135892 },
    { g: 'FitMetric', x: '2021-01-05', y: 169865 },
    { g: 'FitMetric', x: '2021-01-06', y: 203838 },
    { g: 'FitMetric', x: '2021-01-07', y: 237811 },
    { g: 'FitMetric', x: '2021-01-08', y: 271784 },
    { g: 'FitMetric', x: '2021-01-09', y: 305757 },
    { g: 'FitMetric', x: '2021-01-10', y: 339730 },
    { g: 'FitMetric', x: '2021-01-11', y: 373703 },
    { g: 'FitMetric', x: '2021-01-12', y: 407676 },
    { g: 'FitMetric', x: '2021-01-13', y: 441649 },
    { g: 'FitMetric', x: '2021-01-14', y: 475622 },
    { g: 'FitMetric', x: '2021-01-15', y: 509595 },
    { g: 'FitMetric', x: '2021-01-16', y: 543568 },
    { g: 'FitMetric', x: '2021-01-17', y: 577541 },
    { g: 'FitMetric', x: '2021-01-18', y: 611514 },
    { g: 'FitMetric', x: '2021-01-19', y: 645487 },
    { g: 'FitMetric', x: '2021-01-20', y: 679460 },
    { g: 'FitMetric', x: '2021-01-21', y: 713433 },
    { g: 'FitMetric', x: '2021-01-22', y: 747406 },
    { g: 'FitMetric', x: '2021-01-23', y: 781379 },
    { g: 'FitMetric', x: '2021-01-24', y: 815352 },
    { g: 'FitMetric', x: '2021-01-25', y: 849325 },
    { g: 'FitMetric', x: '2021-01-26', y: 883298 },
    { g: 'FitMetric', x: '2021-01-27', y: 917271 },
    { g: 'FitMetric', x: '2021-01-28', y: 951244 },
    { g: 'FitMetric', x: '2021-01-29', y: 985217 },
    { g: 'FitMetric', x: '2021-01-30', y: 1019190 },
    { g: 'FitMetric', x: '2021-01-31', y: 1053163 },
    { g: 'FitMetric', x: '2021-02-01', y: 1087136 },
    { g: 'FitMetric', x: '2021-02-02', y: 1121109 },
    { g: 'FitMetric', x: '2021-02-03', y: 1155082 },
    { g: 'FitMetric', x: '2021-02-04', y: 1189055 },
    { g: 'FitMetric', x: '2021-02-05', y: 1223028 },
    { g: 'FitMetric', x: '2021-02-06', y: 1257001 },
    { g: 'FitMetric', x: '2021-02-07', y: 1290974 },
    { g: 'FitMetric', x: '2021-02-08', y: 1324947 },
    { g: 'FitMetric', x: '2021-02-09', y: 1358920 },
    { g: 'FitMetric', x: '2021-02-10', y: 1392893 },
    { g: 'FitMetric', x: '2021-02-11', y: 1426866 },
    { g: 'FitMetric', x: '2021-02-12', y: 1460839 },
    { g: 'FitMetric', x: '2021-02-13', y: 1494812 },
    { g: 'FitMetric', x: '2021-02-14', y: 1528785 },
    { g: 'FitMetric', x: '2021-02-15', y: 1562758 },
    { g: 'FitMetric', x: '2021-02-16', y: 1596731 },
    { g: 'FitMetric', x: '2021-02-17', y: 1630704 },
    { g: 'FitMetric', x: '2021-02-18', y: 1664677 },
    { g: 'FitMetric', x: '2021-02-19', y: 1698650 },
    { g: 'FitMetric', x: '2021-02-20', y: 1732623 },
    { g: 'FitMetric', x: '2021-02-21', y: 1766596 },
    { g: 'FitMetric', x: '2021-02-22', y: 1800569 },
    { g: 'FitMetric', x: '2021-02-23', y: 1834542 },
    { g: 'FitMetric', x: '2021-02-24', y: 1868515 },
    { g: 'FitMetric', x: '2021-02-25', y: 1902488 },
    { g: 'FitMetric', x: '2021-02-26', y: 1936461 },
    { g: 'FitMetric', x: '2021-02-27', y: 1970434 },
    { g: 'FitMetric', x: '2021-02-28', y: 2004407 },
    { g: 'FitMetric', x: '2021-03-01', y: 2038380 },
    { g: 'FitMetric', x: '2021-03-02', y: 2072353 },
    { g: 'FitMetric', x: '2021-03-03', y: 2106326 },
    { g: 'FitMetric', x: '2021-03-04', y: 2140299 },
    { g: 'FitMetric', x: '2021-03-05', y: 2174272 },
    { g: 'FitMetric', x: '2021-03-06', y: 2208245 },
    { g: 'FitMetric', x: '2021-03-07', y: 2242218 },
    { g: 'FitMetric', x: '2021-03-08', y: 2276191 },
    { g: 'FitMetric', x: '2021-03-09', y: 2310164 },
    { g: 'FitMetric', x: '2021-03-10', y: 2344137 },
    { g: 'FitMetric', x: '2021-03-11', y: 2378110 },
    { g: 'FitMetric', x: '2021-03-12', y: 2412083 },
    { g: 'FitMetric', x: '2021-03-13', y: 2446056 },
    { g: 'FitMetric', x: '2021-03-14', y: 2480029 },
    { g: 'FitMetric', x: '2021-03-15', y: 2514002 },
    { g: 'FitMetric', x: '2021-03-16', y: 2547975 },
    { g: 'FitMetric', x: '2021-03-17', y: 2581948 },
    { g: 'FitMetric', x: '2021-03-18', y: 2615921 },
    { g: 'FitMetric', x: '2021-03-19', y: 2649894 },
    { g: 'FitMetric', x: '2021-03-20', y: 2683867 },
    { g: 'FitMetric', x: '2021-03-21', y: 2717840 },
    { g: 'FitMetric', x: '2021-03-22', y: 2751813 },
    { g: 'FitMetric', x: '2021-03-23', y: 2785786 },
    { g: 'FitMetric', x: '2021-03-24', y: 2819759 },
    { g: 'FitMetric', x: '2021-03-25', y: 2853732 },
    { g: 'FitMetric', x: '2021-03-26', y: 2887705 },
    { g: 'FitMetric', x: '2021-03-27', y: 2921678 },
    { g: 'FitMetric', x: '2021-03-28', y: 2955651 },
    { g: 'FitMetric', x: '2021-03-29', y: 2989624 },
    { g: 'FitMetric', x: '2021-03-30', y: 3023597 },
    { g: 'FitMetric', x: '2021-03-31', y: 3057570 },
    { g: 'FitMetric', x: '2021-04-01', y: 3091543 },
    { g: 'FitMetric', x: '2021-04-02', y: 3125516 },
    { g: 'FitMetric', x: '2021-04-03', y: 3159489 },
    { g: 'FitMetric', x: '2021-04-04', y: 3193462 },
    { g: 'FitMetric', x: '2021-04-05', y: 3227435 },
    { g: 'FitMetric', x: '2021-04-06', y: 3261408 },
    { g: 'FitMetric', x: '2021-04-07', y: 3295381 },
    { g: 'FitMetric', x: '2021-04-08', y: 3329354 },
    { g: 'FitMetric', x: '2021-04-09', y: 3363327 },
    { g: 'FitMetric', x: '2021-04-10', y: 3397300 },
    { g: 'FitMetric', x: '2021-04-11', y: 3431273 },
    { g: 'FitMetric', x: '2021-04-12', y: 3465246 },
    { g: 'FitMetric', x: '2021-04-13', y: 3499219 },
    { g: 'FitMetric', x: '2021-04-14', y: 3533192 },
    { g: 'FitMetric', x: '2021-04-15', y: 3567165 },
    { g: 'FitMetric', x: '2021-04-16', y: 3601138 },
    { g: 'FitMetric', x: '2021-04-17', y: 3635111 },
    { g: 'FitMetric', x: '2021-04-18', y: 3669084 },
    { g: 'FitMetric', x: '2021-04-19', y: 3703057 },
    { g: 'FitMetric', x: '2021-04-20', y: 3737030 },
    { g: 'FitMetric', x: '2021-04-21', y: 3771003 },
    { g: 'FitMetric', x: '2021-04-22', y: 3804976 },
    { g: 'FitMetric', x: '2021-04-23', y: 3838949 },
    { g: 'FitMetric', x: '2021-04-24', y: 3872922 },
    { g: 'FitMetric', x: '2021-04-25', y: 3906895 },
    { g: 'FitMetric', x: '2021-04-26', y: 3940868 },
    { g: 'FitMetric', x: '2021-04-27', y: 3974841 },
    { g: 'FitMetric', x: '2021-04-28', y: 4008814 },
    { g: 'FitMetric', x: '2021-04-29', y: 4042787 },
    { g: 'FitMetric', x: '2021-04-30', y: 4076760 },
    { g: 'FitMetric', x: '2021-05-01', y: 4110733 },
    { g: 'FitMetric', x: '2021-05-02', y: 4144706 },
    { g: 'FitMetric', x: '2021-05-03', y: 4178679 },
    { g: 'FitMetric', x: '2021-05-04', y: 4212652 },
    { g: 'FitMetric', x: '2021-05-05', y: 4246625 },
    { g: 'FitMetric', x: '2021-05-06', y: 4280598 },
    { g: 'FitMetric', x: '2021-05-07', y: 4314571 },
    { g: 'FitMetric', x: '2021-05-08', y: 4348544 },
    { g: 'FitMetric', x: '2021-05-09', y: 4382517 },
    { g: 'FitMetric', x: '2021-05-10', y: 4416490 },
    { g: 'FitMetric', x: '2021-05-11', y: 4450463 },
    { g: 'FitMetric', x: '2021-05-12', y: 4484436 },
    { g: 'FitMetric', x: '2021-05-13', y: 4518409 },
    { g: 'FitMetric', x: '2021-05-14', y: 4552382 },
    { g: 'FitMetric', x: '2021-05-15', y: 4586355 },
    { g: 'FitMetric', x: '2021-05-16', y: 4620328 },
    { g: 'FitMetric', x: '2021-05-17', y: 4654301 },
    { g: 'FitMetric', x: '2021-05-18', y: 4688274 },
    { g: 'FitMetric', x: '2021-05-19', y: 4722247 },
    { g: 'FitMetric', x: '2021-05-20', y: 4756220 },
    { g: 'FitMetric', x: '2021-05-21', y: 4790193 },
    { g: 'FitMetric', x: '2021-05-22', y: 4824166 },
    { g: 'FitMetric', x: '2021-05-23', y: 4858139 },
    { g: 'FitMetric', x: '2021-05-24', y: 4892112 },
    { g: 'FitMetric', x: '2021-05-25', y: 4926085 },
    { g: 'FitMetric', x: '2021-05-26', y: 4960058 },
    { g: 'FitMetric', x: '2021-05-27', y: 4994031 },
    { g: 'FitMetric', x: '2021-05-28', y: 5028004 },
    { g: 'FitMetric', x: '2021-05-29', y: 5061977 },
    { g: 'FitMetric', x: '2021-05-30', y: 5095950 },
    { g: 'FitMetric', x: '2021-05-31', y: 5129923 },
    { g: 'FitMetric', x: '2021-06-01', y: 5163896 },
    { g: 'FitMetric', x: '2021-06-02', y: 5197869 },
    { g: 'FitMetric', x: '2021-06-03', y: 5231842 },
    { g: 'FitMetric', x: '2021-06-04', y: 5265815 },
    { g: 'FitMetric', x: '2021-06-05', y: 5299788 },
    { g: 'FitMetric', x: '2021-06-06', y: 5333761 },
    { g: 'FitMetric', x: '2021-06-07', y: 5367734 },
    { g: 'FitMetric', x: '2021-06-08', y: 5401707 },
    { g: 'FitMetric', x: '2021-06-09', y: 5435680 },
    { g: 'FitMetric', x: '2021-06-10', y: 5469653 },
    { g: 'FitMetric', x: '2021-06-11', y: 5503626 },
    { g: 'FitMetric', x: '2021-06-12', y: 5537599 },
    { g: 'FitMetric', x: '2021-06-13', y: 5571572 },
    { g: 'FitMetric', x: '2021-06-14', y: 5605545 },
    { g: 'FitMetric', x: '2021-06-15', y: 5639518 },
    { g: 'FitMetric', x: '2021-06-16', y: 5673491 },
    { g: 'FitMetric', x: '2021-06-17', y: 5707464 },
    { g: 'FitMetric', x: '2021-06-18', y: 5741437 },
    { g: 'FitMetric', x: '2021-06-19', y: 5775410 },
    { g: 'FitMetric', x: '2021-06-20', y: 5809383 },
    { g: 'FitMetric', x: '2021-06-21', y: 5843356 },
    { g: 'FitMetric', x: '2021-06-22', y: 5877329 },
    { g: 'FitMetric', x: '2021-06-23', y: 5911302 },
    { g: 'FitMetric', x: '2021-06-24', y: 5945275 },
    { g: 'FitMetric', x: '2021-06-25', y: 5979248 },
    { g: 'FitMetric', x: '2021-06-26', y: 6013221 },
    { g: 'FitMetric', x: '2021-06-27', y: 6047194 },
    { g: 'FitMetric', x: '2021-06-28', y: 6081167 },
    { g: 'FitMetric', x: '2021-06-29', y: 6115140 },
    { g: 'FitMetric', x: '2021-06-30', y: 6149113 },
    { g: 'FitMetric', x: '2021-07-01', y: 6183086 },
    { g: 'FitMetric', x: '2021-07-02', y: 6217059 },
    { g: 'FitMetric', x: '2021-07-03', y: 6251032 },
    { g: 'FitMetric', x: '2021-07-04', y: 6285005 },
    { g: 'FitMetric', x: '2021-07-05', y: 6318978 },
    { g: 'FitMetric', x: '2021-07-06', y: 6352951 },
    { g: 'FitMetric', x: '2021-07-07', y: 6386924 },
    { g: 'FitMetric', x: '2021-07-08', y: 6420897 },
    { g: 'FitMetric', x: '2021-07-09', y: 6454870 },
    { g: 'FitMetric', x: '2021-07-10', y: 6488843 },
    { g: 'FitMetric', x: '2021-07-11', y: 6522816 },
    { g: 'FitMetric', x: '2021-07-12', y: 6556789 },
    { g: 'FitMetric', x: '2021-07-13', y: 6590762 },
    { g: 'FitMetric', x: '2021-07-14', y: 6624735 },
    { g: 'FitMetric', x: '2021-07-15', y: 6658708 },
    { g: 'FitMetric', x: '2021-07-16', y: 6692681 },
    { g: 'FitMetric', x: '2021-07-17', y: 6726654 },
    { g: 'FitMetric', x: '2021-07-18', y: 6760627 },
    { g: 'FitMetric', x: '2021-07-19', y: 6794600 },
    { g: 'FitMetric', x: '2021-07-20', y: 6828573 },
    { g: 'FitMetric', x: '2021-07-21', y: 6862546 },
    { g: 'FitMetric', x: '2021-07-22', y: 6896519 },
    { g: 'FitMetric', x: '2021-07-23', y: 6930492 },
    { g: 'FitMetric', x: '2021-07-24', y: 6964465 },
    { g: 'FitMetric', x: '2021-07-25', y: 6998438 },
    { g: 'FitMetric', x: '2021-07-26', y: 7032411 },
    { g: 'FitMetric', x: '2021-07-27', y: 7066384 },
    { g: 'FitMetric', x: '2021-07-28', y: 7100357 },
    { g: 'FitMetric', x: '2021-07-29', y: 7134330 },
    { g: 'FitMetric', x: '2021-07-30', y: 7168303 },
    { g: 'FitMetric', x: '2021-07-31', y: 7202276 },
    { g: 'FitMetric', x: '2021-08-01', y: 7236249 },
    { g: 'FitMetric', x: '2021-08-02', y: 7270222 },
    { g: 'FitMetric', x: '2021-08-03', y: 7304195 },
    { g: 'FitMetric', x: '2021-08-04', y: 7338168 },
    { g: 'FitMetric', x: '2021-08-05', y: 7372141 },
    { g: 'FitMetric', x: '2021-08-06', y: 7406114 },
    { g: 'FitMetric', x: '2021-08-07', y: 7440087 },
    { g: 'FitMetric', x: '2021-08-08', y: 7474060 },
    { g: 'FitMetric', x: '2021-08-09', y: 7508033 },
    { g: 'FitMetric', x: '2021-08-10', y: 7542006 },
    { g: 'FitMetric', x: '2021-08-11', y: 7575979 },
    { g: 'FitMetric', x: '2021-08-12', y: 7609952 },
    { g: 'FitMetric', x: '2021-08-13', y: 7643925 },
    { g: 'FitMetric', x: '2021-08-14', y: 7677898 },
    { g: 'FitMetric', x: '2021-08-15', y: 7711871 },
    { g: 'FitMetric', x: '2021-08-16', y: 7745844 },
    { g: 'FitMetric', x: '2021-08-17', y: 7779817 },
    { g: 'FitMetric', x: '2021-08-18', y: 7813790 },
    { g: 'FitMetric', x: '2021-08-19', y: 7847763 },
    { g: 'FitMetric', x: '2021-08-20', y: 7881736 },
    { g: 'FitMetric', x: '2021-08-21', y: 7915709 },
    { g: 'FitMetric', x: '2021-08-22', y: 7949682 },
    { g: 'FitMetric', x: '2021-08-23', y: 7983655 },
    { g: 'FitMetric', x: '2021-08-24', y: 8017628 },
    { g: 'FitMetric', x: '2021-08-25', y: 8051601 },
    { g: 'FitMetric', x: '2021-08-26', y: 8085574 },
    { g: 'FitMetric', x: '2021-08-27', y: 8119547 },
    { g: 'FitMetric', x: '2021-08-28', y: 8153520 },
    { g: 'FitMetric', x: '2021-08-29', y: 8187493 },
    { g: 'FitMetric', x: '2021-08-30', y: 8221466 },
    { g: 'FitMetric', x: '2021-08-31', y: 8255439 },
    { g: 'FitMetric', x: '2021-09-01', y: 8289412 },
    { g: 'FitMetric', x: '2021-09-02', y: 8323385 },
    { g: 'FitMetric', x: '2021-09-03', y: 8357358 },
    { g: 'FitMetric', x: '2021-09-04', y: 8391331 },
    { g: 'FitMetric', x: '2021-09-05', y: 8425304 },
    { g: 'FitMetric', x: '2021-09-06', y: 8459277 },
    { g: 'FitMetric', x: '2021-09-07', y: 8493250 },
    { g: 'FitMetric', x: '2021-09-08', y: 8527223 },
    { g: 'FitMetric', x: '2021-09-09', y: 8561196 },
    { g: 'FitMetric', x: '2021-09-10', y: 8595169 },
    { g: 'FitMetric', x: '2021-09-11', y: 8629142 },
    { g: 'FitMetric', x: '2021-09-12', y: 8663115 },
    { g: 'FitMetric', x: '2021-09-13', y: 8697088 },
    { g: 'FitMetric', x: '2021-09-14', y: 8731061 },
    { g: 'FitMetric', x: '2021-09-15', y: 8765034 },
    { g: 'FitMetric', x: '2021-09-16', y: 8799007 },
    { g: 'FitMetric', x: '2021-09-17', y: 8832980 },
    { g: 'FitMetric', x: '2021-09-18', y: 8866953 },
    { g: 'FitMetric', x: '2021-09-19', y: 8900926 },
    { g: 'FitMetric', x: '2021-09-20', y: 8934899 },
    { g: 'FitMetric', x: '2021-09-21', y: 8968872 },
    { g: 'FitMetric', x: '2021-09-22', y: 9002845 },
    { g: 'FitMetric', x: '2021-09-23', y: 9036818 },
    { g: 'FitMetric', x: '2021-09-24', y: 9070791 },
    { g: 'FitMetric', x: '2021-09-25', y: 9104764 },
    { g: 'FitMetric', x: '2021-09-26', y: 9138737 },
    { g: 'FitMetric', x: '2021-09-27', y: 9172710 },
    { g: 'FitMetric', x: '2021-09-28', y: 9206683 },
    { g: 'FitMetric', x: '2021-09-29', y: 9240656 },
    { g: 'FitMetric', x: '2021-09-30', y: 9274629 },
    { g: 'FitMetric', x: '2021-10-01', y: 9308602 },
    { g: 'FitMetric', x: '2021-10-02', y: 9342575 },
    { g: 'FitMetric', x: '2021-10-03', y: 9376548 },
    { g: 'FitMetric', x: '2021-10-04', y: 9410521 },
    { g: 'FitMetric', x: '2021-10-05', y: 9444494 },
    { g: 'FitMetric', x: '2021-10-06', y: 9478467 },
    { g: 'FitMetric', x: '2021-10-07', y: 9512440 },
    { g: 'FitMetric', x: '2021-10-08', y: 9546413 },
    { g: 'FitMetric', x: '2021-10-09', y: 9580386 },
    { g: 'FitMetric', x: '2021-10-10', y: 9614359 },
    { g: 'FitMetric', x: '2021-10-11', y: 9648332 },
    { g: 'FitMetric', x: '2021-10-12', y: 9682305 },
    { g: 'FitMetric', x: '2021-10-13', y: 9716278 },
    { g: 'FitMetric', x: '2021-10-14', y: 9750251 },
    { g: 'FitMetric', x: '2021-10-15', y: 9784224 },
    { g: 'FitMetric', x: '2021-10-16', y: 9818197 },
    { g: 'FitMetric', x: '2021-10-17', y: 9852170 },
    { g: 'FitMetric', x: '2021-10-18', y: 9886143 },
    { g: 'FitMetric', x: '2021-10-19', y: 9920116 },
    { g: 'FitMetric', x: '2021-10-20', y: 9954089 },
    { g: 'FitMetric', x: '2021-10-21', y: 9988062 },
    { g: 'FitMetric', x: '2021-10-22', y: 10022035 },
    { g: 'FitMetric', x: '2021-10-23', y: 10056008 },
    { g: 'FitMetric', x: '2021-10-24', y: 10089981 },
    { g: 'FitMetric', x: '2021-10-25', y: 10123954 },
    { g: 'FitMetric', x: '2021-10-26', y: 10157927 },
    { g: 'FitMetric', x: '2021-10-27', y: 10191900 },
    { g: 'FitMetric', x: '2021-10-28', y: 10225873 },
    { g: 'FitMetric', x: '2021-10-29', y: 10259846 },
    { g: 'FitMetric', x: '2021-10-30', y: 10293819 },
    { g: 'FitMetric', x: '2021-10-31', y: 10327792 },
    { g: 'FitMetric', x: '2021-11-01', y: 10361765 },
    { g: 'FitMetric', x: '2021-11-02', y: 10395738 },
    { g: 'FitMetric', x: '2021-11-03', y: 10429711 },
    { g: 'FitMetric', x: '2021-11-04', y: 10463684 },
    { g: 'FitMetric', x: '2021-11-05', y: 10497657 },
    { g: 'FitMetric', x: '2021-11-06', y: 10531630 },
    { g: 'FitMetric', x: '2021-11-07', y: 10565603 },
    { g: 'FitMetric', x: '2021-11-08', y: 10599576 },
    { g: 'FitMetric', x: '2021-11-09', y: 10633549 },
    { g: 'FitMetric', x: '2021-11-10', y: 10667522 },
    { g: 'FitMetric', x: '2021-11-11', y: 10701495 },
    { g: 'FitMetric', x: '2021-11-12', y: 10735468 },
    { g: 'FitMetric', x: '2021-11-13', y: 10769441 },
    { g: 'FitMetric', x: '2021-11-14', y: 10803414 },
    { g: 'FitMetric', x: '2021-11-15', y: 10837387 },
    { g: 'FitMetric', x: '2021-11-16', y: 10871360 },
    { g: 'FitMetric', x: '2021-11-17', y: 10905333 },
    { g: 'FitMetric', x: '2021-11-18', y: 10939306 },
    { g: 'FitMetric', x: '2021-11-19', y: 10973279 },
    { g: 'FitMetric', x: '2021-11-20', y: 11007252 },
    { g: 'FitMetric', x: '2021-11-21', y: 11041225 },
    { g: 'FitMetric', x: '2021-11-22', y: 11075198 },
    { g: 'FitMetric', x: '2021-11-23', y: 11109171 },
    { g: 'FitMetric', x: '2021-11-24', y: 11143144 },
    { g: 'FitMetric', x: '2021-11-25', y: 11177117 },
    { g: 'FitMetric', x: '2021-11-26', y: 11211090 },
    { g: 'FitMetric', x: '2021-11-27', y: 11245063 },
    { g: 'FitMetric', x: '2021-11-28', y: 11279036 },
    { g: 'FitMetric', x: '2021-11-29', y: 11313009 },
    { g: 'FitMetric', x: '2021-11-30', y: 11346982 },
    { g: 'FitMetric', x: '2021-12-01', y: 11380955 },
    { g: 'FitMetric', x: '2021-12-02', y: 11414928 },
    { g: 'FitMetric', x: '2021-12-03', y: 11448901 },
    { g: 'FitMetric', x: '2021-12-04', y: 11482874 },
    { g: 'FitMetric', x: '2021-12-05', y: 11516847 },
    { g: 'FitMetric', x: '2021-12-06', y: 11550820 },
    { g: 'FitMetric', x: '2021-12-07', y: 11584793 },
    { g: 'FitMetric', x: '2021-12-08', y: 11618766 },
    { g: 'FitMetric', x: '2021-12-09', y: 11652739 },
    { g: 'FitMetric', x: '2021-12-10', y: 11686712 },
    { g: 'FitMetric', x: '2021-12-11', y: 11720685 },
    { g: 'FitMetric', x: '2021-12-12', y: 11754658 },
    { g: 'FitMetric', x: '2021-12-13', y: 11788631 },
    { g: 'FitMetric', x: '2021-12-14', y: 11822604 },
    { g: 'FitMetric', x: '2021-12-15', y: 11856577 },
    { g: 'FitMetric', x: '2021-12-16', y: 11890550 },
    { g: 'FitMetric', x: '2021-12-17', y: 11924523 },
    { g: 'FitMetric', x: '2021-12-18', y: 11958496 },
    { g: 'FitMetric', x: '2021-12-19', y: 11992469 },
    { g: 'FitMetric', x: '2021-12-20', y: 12026442 },
    { g: 'FitMetric', x: '2021-12-21', y: 12060415 },
    { g: 'FitMetric', x: '2021-12-22', y: 12094388 },
    { g: 'FitMetric', x: '2021-12-23', y: 12128361 },
    { g: 'FitMetric', x: '2021-12-24', y: 12162334 },
    { g: 'FitMetric', x: '2021-12-25', y: 12196307 },
    { g: 'FitMetric', x: '2021-12-26', y: 12230280 },
    { g: 'FitMetric', x: '2021-12-27', y: 12264253 },
    { g: 'FitMetric', x: '2021-12-28', y: 12298226 },
    { g: 'FitMetric', x: '2021-12-29', y: 12332199 },
    { g: 'FitMetric', x: '2021-12-30', y: 12366172 },
    { g: 'FitMetric', x: '2021-12-31', y: 12400145 },

    { g: 'Revised Goal', x: '2021-01-01', y: null },
    { g: 'Revised Goal', x: '2021-01-02', y: null },
    { g: 'Revised Goal', x: '2021-01-03', y: null },
    { g: 'Revised Goal', x: '2021-01-04', y: null },
    { g: 'Revised Goal', x: '2021-01-05', y: null },
    { g: 'Revised Goal', x: '2021-01-06', y: null },
    { g: 'Revised Goal', x: '2021-01-07', y: null },
    { g: 'Revised Goal', x: '2021-01-08', y: null },
    { g: 'Revised Goal', x: '2021-01-09', y: null },
    { g: 'Revised Goal', x: '2021-01-10', y: null },
    { g: 'Revised Goal', x: '2021-01-11', y: null },
    { g: 'Revised Goal', x: '2021-01-12', y: null },
    { g: 'Revised Goal', x: '2021-01-13', y: null },
    { g: 'Revised Goal', x: '2021-01-14', y: null },
    { g: 'Revised Goal', x: '2021-01-15', y: null },
    { g: 'Revised Goal', x: '2021-01-16', y: null },
    { g: 'Revised Goal', x: '2021-01-17', y: null },
    { g: 'Revised Goal', x: '2021-01-18', y: null },
    { g: 'Revised Goal', x: '2021-01-19', y: null },
    { g: 'Revised Goal', x: '2021-01-20', y: null },
    { g: 'Revised Goal', x: '2021-01-21', y: null },
    { g: 'Revised Goal', x: '2021-01-22', y: null },
    { g: 'Revised Goal', x: '2021-01-23', y: null },
    { g: 'Revised Goal', x: '2021-01-24', y: null },
    { g: 'Revised Goal', x: '2021-01-25', y: null },
    { g: 'Revised Goal', x: '2021-01-26', y: null },
    { g: 'Revised Goal', x: '2021-01-27', y: null },
    { g: 'Revised Goal', x: '2021-01-28', y: null },
    { g: 'Revised Goal', x: '2021-01-29', y: null },
    { g: 'Revised Goal', x: '2021-01-30', y: null },
    { g: 'Revised Goal', x: '2021-01-31', y: null },
    { g: 'Revised Goal', x: '2021-02-01', y: null },
    { g: 'Revised Goal', x: '2021-02-02', y: null },
    { g: 'Revised Goal', x: '2021-02-03', y: null },
    { g: 'Revised Goal', x: '2021-02-04', y: null },
    { g: 'Revised Goal', x: '2021-02-05', y: null },
    { g: 'Revised Goal', x: '2021-02-06', y: null },
    { g: 'Revised Goal', x: '2021-02-07', y: null },
    { g: 'Revised Goal', x: '2021-02-08', y: null },
    { g: 'Revised Goal', x: '2021-02-09', y: null },
    { g: 'Revised Goal', x: '2021-02-10', y: null },
    { g: 'Revised Goal', x: '2021-02-11', y: null },
    { g: 'Revised Goal', x: '2021-02-12', y: null },
    { g: 'Revised Goal', x: '2021-02-13', y: null },
    { g: 'Revised Goal', x: '2021-02-14', y: null },
    { g: 'Revised Goal', x: '2021-02-15', y: null },
    { g: 'Revised Goal', x: '2021-02-16', y: null },
    { g: 'Revised Goal', x: '2021-02-17', y: null },
    { g: 'Revised Goal', x: '2021-02-18', y: null },
    { g: 'Revised Goal', x: '2021-02-19', y: null },
    { g: 'Revised Goal', x: '2021-02-20', y: null },
    { g: 'Revised Goal', x: '2021-02-21', y: null },
    { g: 'Revised Goal', x: '2021-02-22', y: null },
    { g: 'Revised Goal', x: '2021-02-23', y: null },
    { g: 'Revised Goal', x: '2021-02-24', y: null },
    { g: 'Revised Goal', x: '2021-02-25', y: null },
    { g: 'Revised Goal', x: '2021-02-26', y: null },
    { g: 'Revised Goal', x: '2021-02-27', y: null },
    { g: 'Revised Goal', x: '2021-02-28', y: null },
    { g: 'Revised Goal', x: '2021-03-01', y: null },
    { g: 'Revised Goal', x: '2021-03-02', y: null },
    { g: 'Revised Goal', x: '2021-03-03', y: null },
    { g: 'Revised Goal', x: '2021-03-04', y: null },
    { g: 'Revised Goal', x: '2021-03-05', y: null },
    { g: 'Revised Goal', x: '2021-03-06', y: null },
    { g: 'Revised Goal', x: '2021-03-07', y: null },
    { g: 'Revised Goal', x: '2021-03-08', y: null },
    { g: 'Revised Goal', x: '2021-03-09', y: null },
    { g: 'Revised Goal', x: '2021-03-10', y: null },
    { g: 'Revised Goal', x: '2021-03-11', y: null },
    { g: 'Revised Goal', x: '2021-03-12', y: null },
    { g: 'Revised Goal', x: '2021-03-13', y: null },
    { g: 'Revised Goal', x: '2021-03-14', y: null },
    { g: 'Revised Goal', x: '2021-03-15', y: null },
    { g: 'Revised Goal', x: '2021-03-16', y: null },
    { g: 'Revised Goal', x: '2021-03-17', y: null },
    { g: 'Revised Goal', x: '2021-03-18', y: null },
    { g: 'Revised Goal', x: '2021-03-19', y: null },
    { g: 'Revised Goal', x: '2021-03-20', y: null },
    { g: 'Revised Goal', x: '2021-03-21', y: null },
    { g: 'Revised Goal', x: '2021-03-22', y: null },
    { g: 'Revised Goal', x: '2021-03-23', y: null },
    { g: 'Revised Goal', x: '2021-03-24', y: null },
    { g: 'Revised Goal', x: '2021-03-25', y: null },
    { g: 'Revised Goal', x: '2021-03-26', y: null },
    { g: 'Revised Goal', x: '2021-03-27', y: null },
    { g: 'Revised Goal', x: '2021-03-28', y: null },
    { g: 'Revised Goal', x: '2021-03-29', y: null },
    { g: 'Revised Goal', x: '2021-03-30', y: null },
    { g: 'Revised Goal', x: '2021-03-31', y: null },
    { g: 'Revised Goal', x: '2021-04-01', y: null },
    { g: 'Revised Goal', x: '2021-04-02', y: null },
    { g: 'Revised Goal', x: '2021-04-03', y: null },
    { g: 'Revised Goal', x: '2021-04-04', y: null },
    { g: 'Revised Goal', x: '2021-04-05', y: null },
    { g: 'Revised Goal', x: '2021-04-06', y: null },
    { g: 'Revised Goal', x: '2021-04-07', y: null },
    { g: 'Revised Goal', x: '2021-04-08', y: null },
    { g: 'Revised Goal', x: '2021-04-09', y: null },
    { g: 'Revised Goal', x: '2021-04-10', y: null },
    { g: 'Revised Goal', x: '2021-04-11', y: null },
    { g: 'Revised Goal', x: '2021-04-12', y: null },
    { g: 'Revised Goal', x: '2021-04-13', y: null },
    { g: 'Revised Goal', x: '2021-04-14', y: null },
    { g: 'Revised Goal', x: '2021-04-15', y: null },
    { g: 'Revised Goal', x: '2021-04-16', y: null },
    { g: 'Revised Goal', x: '2021-04-17', y: null },
    { g: 'Revised Goal', x: '2021-04-18', y: null },
    { g: 'Revised Goal', x: '2021-04-19', y: null },
    { g: 'Revised Goal', x: '2021-04-20', y: null },
    { g: 'Revised Goal', x: '2021-04-21', y: null },
    { g: 'Revised Goal', x: '2021-04-22', y: null },
    { g: 'Revised Goal', x: '2021-04-23', y: null },
    { g: 'Revised Goal', x: '2021-04-24', y: null },
    { g: 'Revised Goal', x: '2021-04-25', y: null },
    { g: 'Revised Goal', x: '2021-04-26', y: null },
    { g: 'Revised Goal', x: '2021-04-27', y: null },
    { g: 'Revised Goal', x: '2021-04-28', y: null },
    { g: 'Revised Goal', x: '2021-04-29', y: null },
    { g: 'Revised Goal', x: '2021-04-30', y: null },
    { g: 'Revised Goal', x: '2021-05-01', y: null },
    { g: 'Revised Goal', x: '2021-05-02', y: null },
    { g: 'Revised Goal', x: '2021-05-03', y: null },
    { g: 'Revised Goal', x: '2021-05-04', y: null },
    { g: 'Revised Goal', x: '2021-05-05', y: null },
    { g: 'Revised Goal', x: '2021-05-06', y: null },
    { g: 'Revised Goal', x: '2021-05-07', y: null },
    { g: 'Revised Goal', x: '2021-05-08', y: null },
    { g: 'Revised Goal', x: '2021-05-09', y: null },
    { g: 'Revised Goal', x: '2021-05-10', y: null },
    { g: 'Revised Goal', x: '2021-05-11', y: null },
    { g: 'Revised Goal', x: '2021-05-12', y: null },
    { g: 'Revised Goal', x: '2021-05-13', y: null },
    { g: 'Revised Goal', x: '2021-05-14', y: null },
    { g: 'Revised Goal', x: '2021-05-15', y: null },
    { g: 'Revised Goal', x: '2021-05-16', y: null },
    { g: 'Revised Goal', x: '2021-05-17', y: null },
    { g: 'Revised Goal', x: '2021-05-18', y: null },
    { g: 'Revised Goal', x: '2021-05-19', y: null },
    { g: 'Revised Goal', x: '2021-05-20', y: null },
    { g: 'Revised Goal', x: '2021-05-21', y: null },
    { g: 'Revised Goal', x: '2021-05-22', y: null },
    { g: 'Revised Goal', x: '2021-05-23', y: null },
    { g: 'Revised Goal', x: '2021-05-24', y: null },
    { g: 'Revised Goal', x: '2021-05-25', y: null },
    { g: 'Revised Goal', x: '2021-05-26', y: null },
    { g: 'Revised Goal', x: '2021-05-27', y: null },
    { g: 'Revised Goal', x: '2021-05-28', y: null },
    { g: 'Revised Goal', x: '2021-05-29', y: null },
    { g: 'Revised Goal', x: '2021-05-30', y: null },
    { g: 'Revised Goal', x: '2021-05-31', y: null },
    { g: 'Revised Goal', x: '2021-06-01', y: null },
    { g: 'Revised Goal', x: '2021-06-02', y: null },
    { g: 'Revised Goal', x: '2021-06-03', y: null },
    { g: 'Revised Goal', x: '2021-06-04', y: null },
    { g: 'Revised Goal', x: '2021-06-05', y: null },
    { g: 'Revised Goal', x: '2021-06-06', y: null },
    { g: 'Revised Goal', x: '2021-06-07', y: null },
    { g: 'Revised Goal', x: '2021-06-08', y: null },
    { g: 'Revised Goal', x: '2021-06-09', y: null },
    { g: 'Revised Goal', x: '2021-06-10', y: null },
    { g: 'Revised Goal', x: '2021-06-11', y: null },
    { g: 'Revised Goal', x: '2021-06-12', y: null },
    { g: 'Revised Goal', x: '2021-06-13', y: null },
    { g: 'Revised Goal', x: '2021-06-14', y: null },
    { g: 'Revised Goal', x: '2021-06-15', y: null },
    { g: 'Revised Goal', x: '2021-06-16', y: null },
    { g: 'Revised Goal', x: '2021-06-17', y: null },
    { g: 'Revised Goal', x: '2021-06-18', y: null },
    { g: 'Revised Goal', x: '2021-06-19', y: null },
    { g: 'Revised Goal', x: '2021-06-20', y: null },
    { g: 'Revised Goal', x: '2021-06-21', y: null },
    { g: 'Revised Goal', x: '2021-06-22', y: null },
    { g: 'Revised Goal', x: '2021-06-23', y: null },
    { g: 'Revised Goal', x: '2021-06-24', y: null },
    { g: 'Revised Goal', x: '2021-06-25', y: null },
    { g: 'Revised Goal', x: '2021-06-26', y: null },
    { g: 'Revised Goal', x: '2021-06-27', y: null },
    { g: 'Revised Goal', x: '2021-06-28', y: null },
    { g: 'Revised Goal', x: '2021-06-29', y: null },
    { g: 'Revised Goal', x: '2021-06-30', y: null },
    { g: 'Revised Goal', x: '2021-07-01', y: null },
    { g: 'Revised Goal', x: '2021-07-02', y: null },
    { g: 'Revised Goal', x: '2021-07-03', y: null },
    { g: 'Revised Goal', x: '2021-07-04', y: null },
    { g: 'Revised Goal', x: '2021-07-05', y: null },
    { g: 'Revised Goal', x: '2021-07-06', y: null },
    { g: 'Revised Goal', x: '2021-07-07', y: null },
    { g: 'Revised Goal', x: '2021-07-08', y: null },
    { g: 'Revised Goal', x: '2021-07-09', y: null },
    { g: 'Revised Goal', x: '2021-07-10', y: null },
    { g: 'Revised Goal', x: '2021-07-11', y: null },
    { g: 'Revised Goal', x: '2021-07-12', y: null },
    { g: 'Revised Goal', x: '2021-07-13', y: null },
    { g: 'Revised Goal', x: '2021-07-14', y: null },
    { g: 'Revised Goal', x: '2021-07-15', y: null },
    { g: 'Revised Goal', x: '2021-07-16', y: null },
    { g: 'Revised Goal', x: '2021-07-17', y: null },
    { g: 'Revised Goal', x: '2021-07-18', y: null },
    { g: 'Revised Goal', x: '2021-07-19', y: null },
    { g: 'Revised Goal', x: '2021-07-20', y: null },
    { g: 'Revised Goal', x: '2021-07-21', y: null },
    { g: 'Revised Goal', x: '2021-07-22', y: null },
    { g: 'Revised Goal', x: '2021-07-23', y: null },
    { g: 'Revised Goal', x: '2021-07-24', y: null },
    { g: 'Revised Goal', x: '2021-07-25', y: null },
    { g: 'Revised Goal', x: '2021-07-26', y: null },
    { g: 'Revised Goal', x: '2021-07-27', y: null },
    { g: 'Revised Goal', x: '2021-07-28', y: null },
    { g: 'Revised Goal', x: '2021-07-29', y: null },
    { g: 'Revised Goal', x: '2021-07-30', y: null },
    { g: 'Revised Goal', x: '2021-07-31', y: null },
    { g: 'Revised Goal', x: '2021-08-01', y: null },
    { g: 'Revised Goal', x: '2021-08-02', y: null },
    { g: 'Revised Goal', x: '2021-08-03', y: null },
    { g: 'Revised Goal', x: '2021-08-04', y: null },
    { g: 'Revised Goal', x: '2021-08-05', y: null },
    { g: 'Revised Goal', x: '2021-08-06', y: null },
    { g: 'Revised Goal', x: '2021-08-07', y: null },
    { g: 'Revised Goal', x: '2021-08-08', y: null },
    { g: 'Revised Goal', x: '2021-08-09', y: null },
    { g: 'Revised Goal', x: '2021-08-10', y: null },
    { g: 'Revised Goal', x: '2021-08-11', y: null },
    { g: 'Revised Goal', x: '2021-08-12', y: null },
    { g: 'Revised Goal', x: '2021-08-13', y: null },
    { g: 'Revised Goal', x: '2021-08-14', y: null },
    { g: 'Revised Goal', x: '2021-08-15', y: null },
    { g: 'Revised Goal', x: '2021-08-16', y: null },
    { g: 'Revised Goal', x: '2021-08-17', y: null },
    { g: 'Revised Goal', x: '2021-08-18', y: null },
    { g: 'Revised Goal', x: '2021-08-19', y: null },
    { g: 'Revised Goal', x: '2021-08-20', y: null },
    { g: 'Revised Goal', x: '2021-08-21', y: null },
    { g: 'Revised Goal', x: '2021-08-22', y: null },
    { g: 'Revised Goal', x: '2021-08-23', y: null },
    { g: 'Revised Goal', x: '2021-08-24', y: null },
    { g: 'Revised Goal', x: '2021-08-25', y: null },
    { g: 'Revised Goal', x: '2021-08-26', y: null },
    { g: 'Revised Goal', x: '2021-08-27', y: null },
    { g: 'Revised Goal', x: '2021-08-28', y: null },
    { g: 'Revised Goal', x: '2021-08-29', y: null },
    { g: 'Revised Goal', x: '2021-08-30', y: null },
    { g: 'Revised Goal', x: '2021-08-31', y: null },
    { g: 'Revised Goal', x: '2021-09-01', y: null },
    { g: 'Revised Goal', x: '2021-09-02', y: null },
    { g: 'Revised Goal', x: '2021-09-03', y: null },
    { g: 'Revised Goal', x: '2021-09-04', y: null },
    { g: 'Revised Goal', x: '2021-09-05', y: null },
    { g: 'Revised Goal', x: '2021-09-06', y: null },
    { g: 'Revised Goal', x: '2021-09-07', y: null },
    { g: 'Revised Goal', x: '2021-09-08', y: null },
    { g: 'Revised Goal', x: '2021-09-09', y: null },
    { g: 'Revised Goal', x: '2021-09-10', y: null },
    { g: 'Revised Goal', x: '2021-09-11', y: null },
    { g: 'Revised Goal', x: '2021-09-12', y: null },
    { g: 'Revised Goal', x: '2021-09-13', y: null },
    { g: 'Revised Goal', x: '2021-09-14', y: null },
    { g: 'Revised Goal', x: '2021-09-15', y: null },
    { g: 'Revised Goal', x: '2021-09-16', y: null },
    { g: 'Revised Goal', x: '2021-09-17', y: null },
    { g: 'Revised Goal', x: '2021-09-18', y: null },
    { g: 'Revised Goal', x: '2021-09-19', y: null },
    { g: 'Revised Goal', x: '2021-09-20', y: null },
    { g: 'Revised Goal', x: '2021-09-21', y: null },
    { g: 'Revised Goal', x: '2021-09-22', y: 5663320 },
    { g: 'Revised Goal', x: '2021-09-23', y: 5693320 },
    { g: 'Revised Goal', x: '2021-09-24', y: 5723320 },
    { g: 'Revised Goal', x: '2021-09-25', y: 5753320 },
    { g: 'Revised Goal', x: '2021-09-26', y: 5783320 },
    { g: 'Revised Goal', x: '2021-09-27', y: 5813320 },
    { g: 'Revised Goal', x: '2021-09-28', y: 5843320 },
    { g: 'Revised Goal', x: '2021-09-29', y: 5873320 },
    { g: 'Revised Goal', x: '2021-09-30', y: 5903320 },
    { g: 'Revised Goal', x: '2021-10-01', y: 5933320 },
    { g: 'Revised Goal', x: '2021-10-02', y: 5963320 },
    { g: 'Revised Goal', x: '2021-10-03', y: 5993320 },
    { g: 'Revised Goal', x: '2021-10-04', y: 6023320 },
    { g: 'Revised Goal', x: '2021-10-05', y: 6053320 },
    { g: 'Revised Goal', x: '2021-10-06', y: 6083320 },
    { g: 'Revised Goal', x: '2021-10-07', y: 6113320 },
    { g: 'Revised Goal', x: '2021-10-08', y: 6143320 },
    { g: 'Revised Goal', x: '2021-10-09', y: 6173320 },
    { g: 'Revised Goal', x: '2021-10-10', y: 6203320 },
    { g: 'Revised Goal', x: '2021-10-11', y: 6233320 },
    { g: 'Revised Goal', x: '2021-10-12', y: 6263320 },
    { g: 'Revised Goal', x: '2021-10-13', y: 6293320 },
    { g: 'Revised Goal', x: '2021-10-14', y: 6323320 },
    { g: 'Revised Goal', x: '2021-10-15', y: 6353320 },
    { g: 'Revised Goal', x: '2021-10-16', y: 6383320 },
    { g: 'Revised Goal', x: '2021-10-17', y: 6413320 },
    { g: 'Revised Goal', x: '2021-10-18', y: 6443320 },
    { g: 'Revised Goal', x: '2021-10-19', y: 6473320 },
    { g: 'Revised Goal', x: '2021-10-20', y: 6503320 },
    { g: 'Revised Goal', x: '2021-10-21', y: 6533320 },
    { g: 'Revised Goal', x: '2021-10-22', y: 6563320 },
    { g: 'Revised Goal', x: '2021-10-23', y: 6593320 },
    { g: 'Revised Goal', x: '2021-10-24', y: 6623320 },
    { g: 'Revised Goal', x: '2021-10-25', y: 6653320 },
    { g: 'Revised Goal', x: '2021-10-26', y: 6683320 },
    { g: 'Revised Goal', x: '2021-10-27', y: 6713320 },
    { g: 'Revised Goal', x: '2021-10-28', y: 6743320 },
    { g: 'Revised Goal', x: '2021-10-29', y: 6773320 },
    { g: 'Revised Goal', x: '2021-10-30', y: 6803320 },
    { g: 'Revised Goal', x: '2021-10-31', y: 6833320 },
    { g: 'Revised Goal', x: '2021-11-01', y: 6863320 },
    { g: 'Revised Goal', x: '2021-11-02', y: 6893320 },
    { g: 'Revised Goal', x: '2021-11-03', y: 6923320 },
    { g: 'Revised Goal', x: '2021-11-04', y: 6953320 },
    { g: 'Revised Goal', x: '2021-11-05', y: 6983320 },
    { g: 'Revised Goal', x: '2021-11-06', y: 7013320 },
    { g: 'Revised Goal', x: '2021-11-07', y: 7043320 },
    { g: 'Revised Goal', x: '2021-11-08', y: 7073320 },
    { g: 'Revised Goal', x: '2021-11-09', y: 7103320 },
    { g: 'Revised Goal', x: '2021-11-10', y: 7133320 },
    { g: 'Revised Goal', x: '2021-11-11', y: 7163320 },
    { g: 'Revised Goal', x: '2021-11-12', y: 7193320 },
    { g: 'Revised Goal', x: '2021-11-13', y: 7223320 },
    { g: 'Revised Goal', x: '2021-11-14', y: 7253320 },
    { g: 'Revised Goal', x: '2021-11-15', y: 7283320 },
    { g: 'Revised Goal', x: '2021-11-16', y: 7313320 },
    { g: 'Revised Goal', x: '2021-11-17', y: 7343320 },
    { g: 'Revised Goal', x: '2021-11-18', y: 7373320 },
    { g: 'Revised Goal', x: '2021-11-19', y: 7403320 },
    { g: 'Revised Goal', x: '2021-11-20', y: 7433320 },
    { g: 'Revised Goal', x: '2021-11-21', y: 7463320 },
    { g: 'Revised Goal', x: '2021-11-22', y: 7493320 },
    { g: 'Revised Goal', x: '2021-11-23', y: 7523320 },
    { g: 'Revised Goal', x: '2021-11-24', y: 7553320 },
    { g: 'Revised Goal', x: '2021-11-25', y: 7583320 },
    { g: 'Revised Goal', x: '2021-11-26', y: 7613320 },
    { g: 'Revised Goal', x: '2021-11-27', y: 7643320 },
    { g: 'Revised Goal', x: '2021-11-28', y: 7673320 },
    { g: 'Revised Goal', x: '2021-11-29', y: 7703320 },
    { g: 'Revised Goal', x: '2021-11-30', y: 7733320 },
    { g: 'Revised Goal', x: '2021-12-01', y: 7763320 },
    { g: 'Revised Goal', x: '2021-12-02', y: 7793320 },
    { g: 'Revised Goal', x: '2021-12-03', y: 7823320 },
    { g: 'Revised Goal', x: '2021-12-04', y: 7853320 },
    { g: 'Revised Goal', x: '2021-12-05', y: 7883320 },
    { g: 'Revised Goal', x: '2021-12-06', y: 7913320 },
    { g: 'Revised Goal', x: '2021-12-07', y: 7943320 },
    { g: 'Revised Goal', x: '2021-12-08', y: 7973320 },
    { g: 'Revised Goal', x: '2021-12-09', y: 8003320 },
    { g: 'Revised Goal', x: '2021-12-10', y: 8033320 },
    { g: 'Revised Goal', x: '2021-12-11', y: 8063320 },
    { g: 'Revised Goal', x: '2021-12-12', y: 8093320 },
    { g: 'Revised Goal', x: '2021-12-13', y: 8123320 },
    { g: 'Revised Goal', x: '2021-12-14', y: 8153320 },
    { g: 'Revised Goal', x: '2021-12-15', y: 8183320 },
    { g: 'Revised Goal', x: '2021-12-16', y: 8213320 },
    { g: 'Revised Goal', x: '2021-12-17', y: 8243320 },
    { g: 'Revised Goal', x: '2021-12-18', y: 8273320 },
    { g: 'Revised Goal', x: '2021-12-19', y: 8303320 },
    { g: 'Revised Goal', x: '2021-12-20', y: 8333320 },
    { g: 'Revised Goal', x: '2021-12-21', y: 8363320 },
    { g: 'Revised Goal', x: '2021-12-22', y: 8393320 },
    { g: 'Revised Goal', x: '2021-12-23', y: 8423320 },
    { g: 'Revised Goal', x: '2021-12-24', y: 8453320 },
    { g: 'Revised Goal', x: '2021-12-25', y: 8483320 },
    { g: 'Revised Goal', x: '2021-12-26', y: 8513320 },
    { g: 'Revised Goal', x: '2021-12-27', y: 8543320 },
    { g: 'Revised Goal', x: '2021-12-28', y: 8573320 },
    { g: 'Revised Goal', x: '2021-12-29', y: 8603320 },
    { g: 'Revised Goal', x: '2021-12-30', y: 8633320 },
    { g: 'Revised Goal', x: '2021-12-31', y: 8663320 }
]
