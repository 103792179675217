import { ENDPOINTS } from 'common/constants/endpoints'
import axios from '../common/axios'

export const fetchTileChallenge = async (challengedId?: number) => {
    const tileChallengeRes = await axios.get(`${ENDPOINTS.TILE_API}/${challengedId}`)

    if (tileChallengeRes.status !== 200 || tileChallengeRes.data?.hasError) {
        throw new Error(tileChallengeRes.data.message)
    }

    return tileChallengeRes.data
}
