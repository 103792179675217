import {
    EuiText,
    EuiBadge,
    EuiSelectableTemplateSitewide,
    EuiSelectableTemplateSitewideOption,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLink,
    EuiButtonEmpty
} from '@elastic/eui'
import React, { useState, useEffect } from 'react'
import algoliasearch from 'algoliasearch'

const client = algoliasearch('9097GBVBOA', 'cefad2f8c76d5060d3de84a4e2eb6b1e')
const index = client.initIndex('dev_admin')

const SitewideSearchbar = () => {
    const [searchValue, setSearchValue] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [searchRef, setSearchRef] = useState<HTMLInputElement | null>(null)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [hits, setHits] = useState<any>([])
    const searchValueExists = searchValue && searchValue.length

    useEffect(() => {
        if (searchValue.length) {
            setLoading(true)

            index
                .search(searchValue)
                .then(({ hits }) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    if (hits.length) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const mappedHits = hits.map((item: any) => {
                            if (item.algoliaType === 'challenge') {
                                return {
                                    label: item.name,
                                    meta: [
                                        {
                                            text: 'Challenge',
                                            type: 'article'
                                        },
                                        {
                                            text: item.challengeUrl,
                                            highlightSearchString: true
                                        },
                                        {
                                            text: `${item.challengeId}`,
                                            highlightSearchString: true
                                        }
                                    ],
                                    url: `${item.challengeId}`
                                }
                            }
                        })
                        setHits(mappedHits)
                    } else {
                        setHits([])
                    }
                    setLoading(false)
                })
                .catch((e) => {
                    console.error(e)
                    setHits([])
                    setLoading(false)
                })
        } else {
            setHits([])
        }
    }, [searchValue])

    /**
     * Timeout to simulate loading (only on key command+k)
     */
    // let searchTimeout
    // const startSearchTimeout = () => {
    //     searchTimeout = setTimeout(() => {
    //         // Simulate a remotely-executed search.
    //         setLoading(false)
    //     }, 400)
    // }
    // clearTimeout(searchTimeout)
    // startSearchTimeout()

    /**
     * Take the first 5 options and simulate recently viewed
     */
    // const recents = searchData.slice(0, 5)
    // const recentsWithIcon: EuiSelectableTemplateSitewideOption[] = recents.map((recent) => {
    //     return {
    //         ...recent,
    //         icon: {
    //             type: 'clock',
    //             color: 'subdued'
    //         }
    //     }
    // })

    /**
     * Hook up the keyboard shortcut for command+k to initiate focus into search input
     */
    useEffect(() => {
        window.addEventListener('keydown', onWindowKeyDown)

        return function cleanup() {
            window.removeEventListener('resize', onWindowKeyDown)
        }
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onWindowKeyDown = (e: any) => {
        if (e.metaKey && e.key.toLowerCase() === 'k') {
            window.addEventListener('keyup', onWindowKeyUp)
        }
    }

    const onWindowKeyUp = () => {
        searchRef && searchRef.focus()
        // setLoading(true)
        window.removeEventListener('keyup', onWindowKeyUp)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onKeyUpCapture = (e: any) => {
        setSearchValue(e.currentTarget.value)
    }

    /**
     * Do something with the selection based on the found option with `checked: on`
     */
    const onChange = (updatedOptions: EuiSelectableTemplateSitewideOption[]) => {
        const clickedItem = updatedOptions.find((option) => option.checked === 'on')
        if (!clickedItem) return
    }

    return (
        <EuiSelectableTemplateSitewide
            isPreFiltered={true}
            isLoading={isLoading}
            onChange={onChange}
            options={hits.length ? hits : []}
            searchProps={{
                append: '⌘K',
                onKeyUpCapture: onKeyUpCapture,
                className: 'customSearchClass',
                inputRef: setSearchRef,
                compressed: true,
                placeholder: 'Search Yes.Fit'
            }}
            listProps={{
                className: 'customListClass'
            }}
            popoverProps={{
                className: 'customPopoverClass'
            }}
            popoverButton={
                <EuiButtonEmpty size="s" color="ghost" iconType="search">
                    Search
                </EuiButtonEmpty>
            }
            popoverButtonBreakpoints={['xs', 's']}
            popoverFooter={
                <EuiText color="subdued" size="xs">
                    <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false} wrap>
                        <EuiFlexItem grow={false}>
                            {searchValueExists && <EuiLink>View more results</EuiLink>}
                        </EuiFlexItem>
                        <EuiFlexItem />
                        <EuiFlexItem grow={false}>Quickly search using</EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiBadge>Command + K</EuiBadge>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiText>
            }
        />
    )
}

/**
 * The options object
 */
// const searchData: EuiSelectableTemplateSitewideOption[] = [
//     {
//         label: 'Let Freedom Ring 2021 5k',
//         meta: [
//             // {
//             //     text: 'Dashboard',
//             //     type: 'application',
//             //     highlightSearchString: true
//             // },
//             {
//                 text: 'R/C/E',
//                 type: 'article'
//             },
//             {
//                 text: 'freedom-2021-5k-virtual-race',
//                 highlightSearchString: true
//             },
//             {
//                 text: '177',
//                 highlightSearchString: true
//             }
//         ],
//         url: 'welcome-dashboards'
//     },
//     {
//         label: '[Flights] Flight Count and Average Ticket Price over the course of several years maybe even decades',
//         avatar: {
//             name: 'Default Space'
//         },
//         meta: [
//             {
//                 text: 'Visualization',
//                 type: 'application'
//             }
//         ]
//     },
//     {
//         label: '[Flights] Global Flight Dashboard',
//         avatar: {
//             name: 'Hello World'
//         },
//         meta: [
//             {
//                 text: 'Dashboard',
//                 type: 'application',
//                 highlightSearchString: true
//             }
//         ]
//     },
//     {
//         label: '[Logs] Host, Visits and Bytes Table',
//         meta: [
//             {
//                 text: 'TSVB visualization',
//                 type: 'application'
//             }
//         ]
//     },
//     {
//         label: '[Flights] Flight Log',
//         avatar: {
//             name: 'Hello World'
//         },
//         meta: [
//             {
//                 text: 'Discover',
//                 type: 'application'
//             }
//         ]
//     },
//     {
//         label: 'Dashboards',
//         url: 'dashboards',
//         icon: {
//             type: 'logoKibana'
//         }
//     },
//     {
//         label: 'Generate HAR Archive of Network Timings/Details for Kibana requests',
//         meta: [
//             {
//                 text: 'Article',
//                 type: 'article'
//             },
//             {
//                 text: 'https://discuss.elastic.co/t/generate-har-archive-of-network-timings',
//                 highlightSearchString: true
//             }
//         ]
//     },
//     {
//         label: '[Logs] Web Traffic',
//         url: 'dashboard-logs-web-traffic',
//         meta: [
//             {
//                 text: 'Dashboard',
//                 type: 'application',
//                 highlightSearchString: true
//             }
//         ]
//     },
//     {
//         label: 'Databoard analytics',
//         title: 'Databoard analytics; Dashboard; Deployment: Flights Data',
//         meta: [
//             {
//                 text: 'Dashboard',
//                 type: 'application'
//             },
//             {
//                 text: 'Flights Data',
//                 type: 'deployment'
//             }
//         ]
//     },
//     {
//         label: 'Primary logs',
//         avatar: {
//             name: 'Another'
//         },
//         meta: [
//             {
//                 text: 'Flights Data',
//                 type: 'deployment'
//             }
//         ]
//     },
//     {
//         label: 'SIEM',
//         icon: {
//             type: 'logoSecurity'
//         },
//         meta: [
//             {
//                 text: 'personal-databoard',
//                 type: 'deployment'
//             }
//         ]
//     },
//     {
//         label: 'Dev tools',
//         url: 'dev-tools-console',
//         meta: [
//             {
//                 text: 'Management application',
//                 type: 'application'
//             }
//         ]
//     },
//     {
//         label: 'Billing',
//         icon: {
//             type: 'user'
//         },
//         meta: [
//             {
//                 text: 'Account',
//                 type: 'platform'
//             }
//         ]
//     },
//     {
//         label: 'Maps',
//         url: 'maps',
//         icon: { type: 'logoKibana' },
//         meta: [
//             {
//                 text: 'Analyze application',
//                 type: 'application'
//             }
//         ],
//         space: 'Hello World'
//     },
//     {
//         label: 'Kibana monitoring with MB',
//         searchableLabel: 'Kibana monitoring with MB; Case no. 00508173',
//         meta: [
//             {
//                 text: 'Case',
//                 type: 'case'
//             },
//             {
//                 text: '00508173'
//             }
//         ]
//     },
//     {
//         label: 'My support tickets',
//         icon: {
//             type: 'help'
//         },
//         meta: [
//             {
//                 text: 'Support',
//                 type: 'platform'
//             }
//         ]
//     },
//     {
//         label: 'Totally custom',
//         searchableLabel: 'Totally custom with pink metadata',
//         icon: {
//             type: 'alert',
//             color: 'accent'
//         },
//         meta: [
//             {
//                 text: 'I have a custom type',
//                 type: 'PINK'
//             }
//         ]
//     }
// ]

export default SitewideSearchbar
