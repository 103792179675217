import { fetchChallengeDetail, fetchChallengePricing, fetchKeywordSections } from 'API/fetchChallengeDetail'
import { useQuery } from 'react-query'

export function useChallengeDetails(challengeId?: number) {
    const challenge = useQuery(['challenge', challengeId], () => fetchChallengeDetail(challengeId), {
        enabled: !!challengeId,
        refetchOnWindowFocus: false
    })

    return challenge
}

export function useChallengePricing(challengeId?: number) {
    const challengePricing = useQuery(['challenge pricing', challengeId], () => fetchChallengePricing(challengeId), {
        enabled: !!challengeId,
        refetchOnWindowFocus: false
    })

    return challengePricing
}

export function useKeywordSections(type?: number) {
    const keywordSections = useQuery(['keyword sections', type], () => fetchKeywordSections(type), {
        enabled: !!type,
        refetchOnWindowFocus: false
    })

    return keywordSections
}
