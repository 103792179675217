import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { useChallengeDetails } from 'hooks/useChallengeDetails'
import { FC } from 'react'
import { DetailStatHeader } from '../components/Detail/DetailStatHeader'

interface ExerciseDetailOverviewProps extends RouteComponentProps {
    challengeId?: number
}

export const ExerciseDetailOverviewPage: FC<ExerciseDetailOverviewProps> = ({ challengeId }) => {
    console.log(challengeId)
    const { data, status } = useChallengeDetails(challengeId || undefined)

    return (
        <AdminPageLayout
            headerProps={{
                challengeId: challengeId || undefined,
                isLoading: status === 'loading',
                isError: status === 'error',
                pageTitle: data?.challenge?.name || null,
                isPrivate: data?.challenge?.isPrivateChallenge ?? true,
                eventType: EVENT_TYPE.EXERCISES,
                onSaveClicked: null
            }}
        >
            <DetailStatHeader />
        </AdminPageLayout>
    )
}
