import axios from 'axios'
import moment from 'moment'

export const fetchRegistrationsByRef = async () => {
    const res = await axios.get(`https://old-tree-7434.fly.dev/registrations/ref`)

    const parsedRegistrations = res.data?.registrations?.map(
        (view: { day: moment.MomentInput; page_referrer: any; count: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: view.page_referrer,
                y: view.count
            }
        }
    )

    return { registrations: parsedRegistrations }
}
