import axios from 'axios'
import moment from 'moment'

export const fetchSingleRaceJoins = async (challengeId: any) => {
    const res = await axios.get(`https://old-tree-7434.fly.dev/all-joins/${challengeId}`)

    const parsedWebJoins = res.data?.allWebViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '🕸',
                y: view.count
            }
        }
    )

    const parsedMobileJoins = res.data?.allMobileViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '📱',
                y: view.count
            }
        }
    )

    const parsedWebJoinsNewUsers = res.data?.allWebViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '🕸 - reg.date = join.date',
                y: view.new_users
            }
        }
    )

    const parsedMobileJoinsNewUsers = res.data?.allMobileViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '📱 - reg.date = join.date',
                y: view.new_users
            }
        }
    )

    const parsedWebJoinsExistingUsers = res.data?.allWebViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '🕸 - reg.date < join.date',
                y: view.existing_users
            }
        }
    )

    const parsedMobileJoinsExistingUsers = res.data?.allMobileViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '📱 - reg.date < join.date',
                y: view.existing_users
            }
        }
    )

    const parsedWebJoinsFreeOrder = res.data?.allWebViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '🕸 - w/ pay = 0',
                y: view.zero_total
            }
        }
    )

    const parsedWebJoinsPaidOrder = res.data?.allWebViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '🕸 - w/ pay > 0',
                y: view.nonzero_total
            }
        }
    )

    const parsedMobileJoinsFreeOrder = res.data?.allMobileViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '📱 - w/ pay = 0',
                y: view.zero_total
            }
        }
    )

    const parsedMobileJoinsPaidOrder = res.data?.allMobileViews?.map(
        (view: {
            day: moment.MomentInput
            count: any
            new_users: any
            existing_users: any
            zero_total: any
            nonzero_total: any
        }) => {
            return {
                x: moment(view.day).toISOString(),
                g: '📱 - w/ pay > 0',
                y: view.nonzero_total
            }
        }
    )

    return {
        views: [...parsedWebJoins, ...parsedMobileJoins],
        parsedWebJoins,
        parsedMobileJoins,
        parsedWebJoinsNewUsers,
        parsedMobileJoinsNewUsers,
        parsedWebJoinsExistingUsers,
        parsedMobileJoinsExistingUsers,
        parsedWebJoinsFreeOrder,
        parsedWebJoinsPaidOrder,
        parsedMobileJoinsFreeOrder,
        parsedMobileJoinsPaidOrder
    }
}
