import { v4 as uuidv4 } from 'uuid'

import { fetchTileChallenge } from 'API/fetchTileChallenge'
import { ENDPOINTS } from 'common/constants/endpoints'
import axios from '../../common/axios'

export const updateTileChallenge = async (tileChallenge: any) => {
    const updateTC = await axios.post(`${ENDPOINTS.TILE_API}`, {
        ...tileChallenge
    })

    if (updateTC.status !== 200 || updateTC.data?.hasError) {
        throw new Error(updateTC.data.message)
    }

    return updateTC.data
}
