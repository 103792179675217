import axios from 'axios'
import moment from 'moment'

export const fetchGoogleAdCampDaily = async () => {
    const res = await axios.get('https://old-tree-7434.fly.dev/google-ads/campaign-perf-daily')

    const parsedReports = res.data?.data?.map((view: { day: moment.MomentInput; round: any; name: any }) => {
        return {
            x: moment(view.day).toISOString(),
            g: view.name,
            y: view.round
        }
    })

    return { reports: parsedReports }
}
