import { ENDPOINTS } from 'common/constants/endpoints'
import axios from '../common/axios'

export const fetchUserJoinChallenge = async (challengedId?: number) => {
    const challengeDetailRes = await axios.get(`${ENDPOINTS.JOIN_CHALLENGE_API}/${challengedId}`)

    if (challengeDetailRes.status !== 200 || challengeDetailRes.data?.hasError) {
        throw new Error(challengeDetailRes.data.message)
    }

    return challengeDetailRes.data
}
