/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    EuiButtonIcon,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiEmptyPrompt,
    EuiPanel,
    EuiLoadingSpinner,
    EuiSpacer,
    EuiFlexItem,
    EuiFlexGroup,
    EuiTitle,
    EuiButton,
    htmlIdGenerator,
    EuiProgress
} from '@elastic/eui'
import { RouteComponentProps } from '@reach/router'
import { mockFetchExerciseLanes } from 'API/mockFetchExerciseLanes'
import React, { FC, useEffect } from 'react'
import { useQuery, useQueryClient, QueryClient } from 'react-query'
import { LaneManager } from './LaneManager'
import create from 'zustand'
import { EditLanePopover } from './EditLanePopover'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { CreateLaneFlyout } from './CreateLaneFlyout'
import { EVENT_TYPE } from 'common/constants/eventType'
import axios from 'common/axios'
import { ManageItemsFlyout } from './ManageItemsFlyout'

type ChallengePrioritizePageProps = RouteComponentProps

interface LaneState {
    lanes: Array<any>
    setLanes: (newLanes: Array<any>) => void
    updateLaneItems: (laneIdx: number, items: Array<any>) => void
    isFlyoutVisible: boolean
    toggleFlyout: () => void
}

export const useLaneStore = create<LaneState>((set) => ({
    lanes: [],
    isFlyoutVisible: false,
    toggleFlyout: () => set((state) => ({ isFlyoutVisible: !state.isFlyoutVisible })),
    setLanes: (newLanes) => set(() => ({ lanes: newLanes })),
    updateLaneItems: (laneIdx, items) =>
        set((state) => {
            const updatedLanes = state.lanes
            updatedLanes[laneIdx].challengeLanes = items.map((item, idx) => {
                return { ...item, position: idx + 1 }
            })

            axios.post('/api/v1/admin/DiscoverEventList', updatedLanes[laneIdx])

            return {
                lanes: updatedLanes
            }
        })
}))

const mapEventIdToString = (eventType: number) => {
    switch (eventType) {
        case EVENT_TYPE.EXERCISES:
            return 'Exercises'
        case EVENT_TYPE.CHALLENGE:
            return 'Challenges'
        case EVENT_TYPE.SWAG:
            return 'Products'
        case EVENT_TYPE.RACES:
            return 'Races'
        default:
            return 'Challenges'
    }
}

export const LaneAdmin = ({ eventType }: { eventType: any }) => {
    const queryClient = useQueryClient()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { data, status, isFetching } = useQuery(
        ['lanes', eventType],
        () => mockFetchExerciseLanes({ eventType: eventType }),
        {
            staleTime: Infinity
        }
    )
    const lanes = useLaneStore((state) => state.lanes)
    const setLanes = useLaneStore((state) => state.setLanes)
    const updateLaneItems = useLaneStore((state) => state.updateLaneItems)
    const toggleFlyout = useLaneStore((state) => state.toggleFlyout)

    useEffect(() => {
        console.log({ lanes, data })
        const sortedParentLanes = data?.lanes?.sort((a: any, b: any) => a.position - b.position)
        const sortedLanes = sortedParentLanes?.map((lane: any) => {
            const sortedChallengeLanes = lane.challengeLanes?.sort((a: any, b: any) => a.position - b.position)
            return {
                ...lane,
                challengeLanes: sortedChallengeLanes ?? []
            }
        })
        setLanes(sortedLanes || [])
    }, [data])

    const lists: any = {
        COMPLEX_DROPPABLE_PARENT: lanes
    }

    const actions: any = {
        COMPLEX_DROPPABLE_PARENT: setLanes
    }

    const onDragEnd: any = ({ source, destination }: any) => {
        if (source && destination) {
            if (source.droppableId === destination.droppableId) {
                const items = euiDragDropReorder(lists[destination.droppableId], source.index, destination.index)

                actions[destination.droppableId](items)

                axios
                    .put(
                        '/api/v1/admin/DiscoverEventList',
                        items.map((lane: any, idx: any) => {
                            return {
                                ...lane,
                                position: idx + 1
                            }
                        })
                    )
                    .then(() => {
                        queryClient.invalidateQueries(['lanes', eventType])
                    })

                console.log('here LANE position changed')
            }
        }
    }

    return (
        <>
            {status === 'loading' ? (
                <>
                    <EuiSpacer size="xl" />
                    <EuiEmptyPrompt icon={<EuiLoadingSpinner size="xl" />} title={<h2>Loading Lanes...</h2>} />
                </>
            ) : status === 'error' ? (
                <>
                    <EuiSpacer size="xl" />
                    <EuiEmptyPrompt
                        iconType="alert"
                        iconColor="danger"
                        title={<h2>Error loading Lanes</h2>}
                        body={<p>There was an error loading lanes. Contact your administrator for help.</p>}
                    />
                </>
            ) : (
                <>
                    <EuiPanel style={{ position: 'relative' }} hasShadow={false} paddingSize="m">
                        {isFetching && <EuiProgress size="xs" color="primary" position="absolute" />}

                        <EuiFlexGroup justifyContent="flexEnd">
                            <EuiFlexItem>
                                <EuiFlexGroup alignItems="center">
                                    <EuiFlexItem grow={false}>
                                        <EuiTitle size="s">
                                            <h3>{mapEventIdToString(eventType)} Prioritization</h3>
                                        </EuiTitle>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton onClick={() => toggleFlyout()} iconType="plus">
                                    Create Lane
                                </EuiButton>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiPanel>

                    <EuiDragDropContext onDragEnd={onDragEnd}>
                        <EuiDroppable
                            droppableId="COMPLEX_DROPPABLE_PARENT"
                            type="MACRO"
                            direction="vertical"
                            spacing="l"
                        >
                            {lanes.map((lane: any, idx: number) => {
                                const id = htmlIdGenerator()()
                                return (
                                    <EuiDraggable
                                        customDragHandle={true}
                                        key={`${idx}-${id}`}
                                        index={idx}
                                        draggableId={`COMPLEX_DRAGGABLE_${id}`}
                                        spacing="l"
                                        style={{ flex: '1 0 25%' }}
                                        disableInteractiveElementBlocking // Allows button to be drag handle
                                    >
                                        {(provided) => (
                                            <EuiPanel color="plain" paddingSize="m">
                                                {/* <EuiButtonIcon
                                                iconType="grab"
                                                aria-label="Drag Handle"
                                                display="base"
                                                {...provided.dragHandleProps}
                                            /> */}

                                                <EuiFlexGroup alignItems="center" gutterSize="none" wrap={false}>
                                                    <EuiFlexGroup
                                                        gutterSize="s"
                                                        alignItems="center"
                                                        wrap={false}
                                                        responsive={false}
                                                    >
                                                        <EuiFlexItem grow={false}>
                                                            <EuiButtonIcon
                                                                iconType="grab"
                                                                aria-label="Drag Handle"
                                                                display="base"
                                                                {...provided.dragHandleProps}
                                                            />
                                                        </EuiFlexItem>
                                                        <EuiFlexItem grow={false}>
                                                            <EuiTitle size="s">
                                                                <h3>{lane.name}</h3>
                                                            </EuiTitle>
                                                        </EuiFlexItem>
                                                    </EuiFlexGroup>
                                                    <EuiFlexGroup
                                                        gutterSize="none"
                                                        alignItems="center"
                                                        justifyContent="flexEnd"
                                                        wrap={false}
                                                        responsive={false}
                                                    >
                                                        <EuiFlexItem grow={false} style={{ marginRight: 10 }}>
                                                            <EditLanePopover
                                                                eventType={eventType}
                                                                lane={lane}
                                                                idx={idx}
                                                            />
                                                        </EuiFlexItem>
                                                        <EuiFlexItem grow={false}>
                                                            <ManageItemsFlyout eventType={eventType} laneIdx={idx} />
                                                        </EuiFlexItem>
                                                    </EuiFlexGroup>
                                                </EuiFlexGroup>

                                                <LaneManager
                                                    eventType={eventType}
                                                    key={idx}
                                                    laneIndex={idx}
                                                    setLaneItems={(items: Array<any>) => updateLaneItems(idx, items)}
                                                    items={lane.challengeLanes ?? []}
                                                />
                                            </EuiPanel>
                                        )}
                                    </EuiDraggable>
                                )
                            })}
                        </EuiDroppable>
                    </EuiDragDropContext>
                </>
            )}

            <CreateLaneFlyout eventType={eventType} />
        </>
    )
}
