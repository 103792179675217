import { ENDPOINTS } from 'common/constants/endpoints'
import axios from '../common/axios'

export const fetchChallengeDetail = async (challengedId?: number) => {
    const challengeDetailRes = await axios.get(`${ENDPOINTS.CHALLENGE_DETAILS_API}/${challengedId}`)

    if (challengeDetailRes.status !== 200 || challengeDetailRes.data?.hasError) {
        throw new Error(challengeDetailRes.data.message)
    }

    return challengeDetailRes.data
}

export const fetchChallengePricing = async (challengedId?: number) => {
    const challengePricingRes = await axios.get(`${ENDPOINTS.CHALLENGE_PRICING_API}/${challengedId}`)

    if (challengePricingRes.status !== 200 || challengePricingRes.data?.hasError) {
        throw new Error(challengePricingRes.data.message)
    }

    return challengePricingRes.data
}

export const fetchKeywordSections = async (type?: number) => {
    const keywordSectionsResp = await axios.get(`${ENDPOINTS.KEYWORD_SECTIONS_API}/${type}`)

    if (keywordSectionsResp.status !== 200 || keywordSectionsResp.data?.hasError) {
        throw new Error(keywordSectionsResp.data.message)
    }

    return keywordSectionsResp.data
}
