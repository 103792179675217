import axios from 'axios'
import moment from 'moment'

// https://old-tree-7434.fly.dev

export const fetchWebUTMViews = async () => {
    const res = await axios.get('https://old-tree-7434.fly.dev/web-utm-source-views')

    const parsedWebViews =
        res.data?.allWebViews
            ?.map((view: { day: moment.MomentInput; product_id: any; count: any; context_campaign_source: any }) => {
                return {
                    x: moment(view.day).toISOString(),
                    g: view.context_campaign_source ? view.context_campaign_source : 'no_utm_source',
                    y: view.count
                }
            })
            .filter((i: any) => i.y > 40) ?? []

    return { webViews: parsedWebViews }
}
