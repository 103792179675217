import { EuiButton, EuiPageHeader } from '@elastic/eui'
import { useMatch, useNavigate } from '@reach/router'
import { FC, useEffect } from 'react'

type ExercisesPageHeaderProps = {
    onCreateClicked: () => void
}

export const ExercisesPageHeader: FC<ExercisesPageHeaderProps> = ({ onCreateClicked }) => {
    const navigate = useNavigate()
    const matchIndex = useMatch('/exercises')
    const matchManage = useMatch('/exercises/manage')
    const matchPrioritize = useMatch('/exercises/prioritize')

    useEffect(() => {
        console.info('RENDER: ExercisesPageHeader')
    })

    if (!matchIndex && !matchManage && !matchPrioritize) {
        return null
    }

    return (
        <EuiPageHeader
            restrictWidth={false}
            pageTitle="Exercises"
            tabs={[
                {
                    label: 'Overview',
                    onClick: () => navigate('/exercises'),
                    isSelected: matchIndex !== null
                },
                {
                    label: 'Manage',
                    onClick: () => navigate('/exercises/manage'),
                    isSelected: matchManage !== null
                },
                {
                    label: 'Prioritize',
                    onClick: () => navigate('/exercises/prioritize'),
                    isSelected: matchPrioritize !== null
                }
            ]}
            rightSideItems={[
                <EuiButton onClick={onCreateClicked} key="0" iconType="plus" fill>
                    Create Exercise
                </EuiButton>
            ]}
        />
    )
}
