import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
    headers: {
        post: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        common: {
            Accept: 'application/json; charset=utf-8',
            Platform: 'web-admin',
            'App-Version': process.env.REACT_APP_VERSION
        }
    }
})

instance.interceptors.request.use((config) => {
    // console.log(`Request: ${config.method} ${config.url}`)
    const token = localStorage.getItem('yf-admin-token') || null
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
})

export default instance
