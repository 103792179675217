import { ENDPOINTS } from '../common/constants/endpoints'
import axios from '../common/axios'

export interface IPostChallengeImageParams {
    id: number
    key: string
    challengeId: number
    image: File
}
export interface IChallengeImage {
    altText: string | null
    isDirty: boolean
    isDelete: boolean
    key: string
    challengeImageId: number
    challengeId: number
    challengeImageTypeId: number
    challengeImageUrl: string
    challengeImageContentType: string
    createTS: string
    createUserId: number
    muxAssetId: number
    muxAsset: MuxAsset
    filename: string
    challengeImageType: string
    isSpecial: boolean
    isAddon: boolean
}
export interface MuxAsset {
    muxAssetId: number
    createTS: string
    assetId: string
    playbackId: string
    status: string
    duration: number
    properties: string
    retryCount: number
    createUserId: number
    isErrorSent: boolean
    thumbnailUrl: string
    url: string
}

export interface IChallengeImageData {
    hasError: boolean
    message: string
    error: string
    challengeId: number
    images: IChallengeImage[]
}

export const fetchChallengeImages = async (challengeId?: number) => {
    const challengeImagesRes = await axios.get(`${ENDPOINTS.CHALLENGE_IMAGES_API}/${challengeId}`)

    if (challengeImagesRes.status !== 200 || challengeImagesRes.data?.hasError) {
        throw new Error(challengeImagesRes.data.message)
    }

    return challengeImagesRes.data
}

export const postChallengeImage = async (params: IPostChallengeImageParams) => {
    const challengeImagesRes = await axios.get(`${ENDPOINTS.CHALLENGE_IMAGES_API}`, {
        params: params
    })

    if (challengeImagesRes.status !== 200 || challengeImagesRes.data?.hasError) {
        throw new Error(challengeImagesRes.data.message)
    }

    return challengeImagesRes.data
}
