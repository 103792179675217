import { EuiEmptyPrompt, EuiLoadingSpinner } from '@elastic/eui'
import { RouteComponentProps } from '@reach/router'
import { fetchTileChallenge } from 'API/fetchTileChallenge'
import { IChallenge, IPlan, IPricing, updateChallenge, updateChallengePricing } from 'API/updateChallenge'
import { EVENT_TYPE } from 'common/constants/eventType'
import { useToastsStore } from 'components/GlobalToastList'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { MediaPanel } from 'components/MediaPanel'
import { Form, Formik, FormikProps, FormikValues } from 'formik'
import _ from 'lodash'
import moment from 'moment'
import { FC, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useChallengeDetails, useChallengePricing, useKeywordSections } from '../../../hooks/useChallengeDetails'
import FormConfig from '../components/Manage/FormConfig'
import General from '../components/Manage/General'
import KeywordSections from '../components/Manage/KeywordSections'
import Pricing from '../components/Manage/Pricing'
const { initialValues, validationSchema } = FormConfig
// import Trainers from '../components/Manage/Trainers'
// import Merch from '../components/Manage/Merch'
// import Trainers from '../components/Manage/Trainers'
interface ExerciseDetailsPageProps extends RouteComponentProps {
    challengeId?: number
}

export const ExerciseDetailPage: FC<ExerciseDetailsPageProps> = ({ challengeId }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formRef = useRef<any>()
    const { data: challengeDetails, status: challengeDetailsStatus } = useChallengeDetails(challengeId)
    const { data: challengePricing } = useChallengePricing(challengeId)
    const { data: keywordSections } = useKeywordSections(4)
    const tileChallengeQuery = useQuery(['tileChallenge', challengeId], () => fetchTileChallenge(challengeId))
    const addToast = useToastsStore((state) => state.addToast)

    // useEffect(() => {
    //     async function fetchTD() {
    //         const tileData = await fetchTileChallenge(challengeId)
    //         console.log({ tileData })
    //     }

    //     fetchTD()
    // }, [])

    useEffect(() => {
        console.log({ tileChallengeQuery })
    })

    return (
        <AdminPageLayout
            headerProps={{
                challengeId: challengeId || undefined,
                eventType: EVENT_TYPE.EXERCISES,
                isError: challengeDetailsStatus === 'error',
                isLoading: challengeDetailsStatus === 'loading',
                pageTitle: challengeDetails?.challenge?.name || null,
                isPrivate: challengeDetails?.challenge?.isPrivateChallenge || null,
                onSaveClicked: formRef?.current?.handleSubmit,
                onVisibilityChange: formRef?.current?.setFieldValue
            }}
        >
            <Formik
                innerRef={formRef}
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValues(challengeDetails?.challenge, challengePricing)}
                onSubmit={async (values) => {
                    const updatedChallenge: IChallenge = _.cloneDeep(challengeDetails.challenge)
                    updatedChallenge.challengeUrl = values.challengeUrl
                    updatedChallenge.endDate = moment(values.endDate).toISOString()
                    updatedChallenge.isExercise = true
                    updatedChallenge.keywords = values.keywords
                    updatedChallenge.maxNumberOfRegistrations = values.maxRegistrations
                    updatedChallenge.fundraisingMessage = values.message
                    updatedChallenge.metaDescription = values.metaDescription
                    updatedChallenge.metaKeywords = values.metaKeywords
                    updatedChallenge.name = values.title
                    updatedChallenge.personalizeKeywords = values.personalizeKeywords
                    updatedChallenge.registrationCloseDate = moment(values.endDate).toISOString()
                    updatedChallenge.sortWeight = values.sortWeight
                    updatedChallenge.startDate = moment(values.startDate).toISOString()
                    updatedChallenge.startDate = moment(values.startDate).toISOString()
                    updatedChallenge.isPrivateChallenge = values.isPrivate
                    // updatedChallenge.challengeEnabled = values.challengeEnabled
                    // updatedChallenge.challengeSpecific = values.challengeSpecific
                    // updatedChallenge.classEnabled = values.classEnabled
                    // updatedChallenge.featuredMerch = values.featuredMerch
                    // updatedChallenge.relatedTrainers = values.relatedTrainers

                    const updatedChallengePricing: IPricing = _.cloneDeep(challengePricing)
                    if (values.pricing['CYFActive']) {
                        const result = updatedChallengePricing['plans']
                            .filter((plan) => plan.isGroupPlan === false)
                            .map((plan) => ({ ...plan, isSelected: true, untilTS: values.exclusiveUntil }))
                        updatedChallengePricing['plans'] = result as IPlan[]
                    }

                    if (values.pricing['CYFGroup']) {
                        const result = updatedChallengePricing['plans']
                            .filter((plan) => plan.isGroupPlan === true)
                            .map((plan) => ({ ...plan, isSelected: true, untilTS: values.exclusiveUntil }))
                        updatedChallengePricing['plans'] = result as IPlan[]
                    }

                    if (values.pricing['FreeMembership']) {
                        const result = updatedChallengePricing['plans'].map((plan) => ({ ...plan, isSelected: false }))
                        updatedChallengePricing['plans'] = result as IPlan[]
                    }

                    try {
                        await updateChallenge(updatedChallenge)
                            .then(() => updateChallengePricing(updatedChallengePricing))
                            .then(() =>
                                addToast({
                                    title: 'Exercise successfully saved',
                                    id: challengeDetails.challengeId,
                                    color: 'success'
                                })
                            )
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    } catch (er: any) {
                        addToast({
                            color: 'danger',
                            id: challengeDetails.challengeId,
                            title: `There was an error saving this exercise.`,
                            text: <p>{er.message}</p>
                        })
                    }
                }}
            >
                {({ isValid, handleSubmit, values, setFieldValue }: FormikProps<FormikValues>) => (
                    <>
                        {console.log('valid', isValid)}
                        {challengeDetailsStatus === 'loading' ? (
                            <EuiEmptyPrompt
                                icon={<EuiLoadingSpinner size="xl" />}
                                title={<h2>Loading Challenge Details...</h2>}
                            />
                        ) : challengeDetailsStatus === 'error' ? (
                            <EuiEmptyPrompt
                                iconType="alert"
                                iconColor="danger"
                                title={<h2>Error loading challenge</h2>}
                                body={
                                    <p>
                                        There was an error loading the challenge details. Contact your administrator for
                                        help.
                                    </p>
                                }
                            />
                        ) : (
                            <Form>
                                <General />
                                <MediaPanel
                                    challengeId={challengeDetails?.challenge.challengeId}
                                    eventType={EVENT_TYPE.EXERCISES}
                                />
                                <Pricing />
                                {/* <Merch /> */}
                                {/* <Trainers /> */}
                                <KeywordSections keywordSections={keywordSections} />
                            </Form>
                        )}
                    </>
                )}
            </Formik>
        </AdminPageLayout>
    )
}
