export const ENDPOINTS = {
    LOGIN_API: '/api/v1/login',
    REFRESH_API: '/api/v1/login/refresh',
    CHALLENGE_LIST_API: '/api/v1/admin/ChallengeList',
    CHALLENGE_DETAILS_API: '/api/v1/admin/ChallengeDetails',
    CHALLENGE_PRICING_API: '/api/v1/admin/ChallengeStripePlans',
    IMAGE_TYPES_API: '/api/v1/admin/ChallengeImageTypes',
    CHALLENGE_IMAGES_API: '/api/v1/admin/ChallengeImages',
    MEDIA_LIBRARY_API: '/api/v1/admin/MediaLibrary',
    JOIN_CHALLENGE_API: '/api/v1/JoinChallenge',
    EXERCISE_LIST_API: '/api/v1/admin/ChallengeList',
    KEYWORD_SECTIONS_API: 'api/v1/admin/KeywordSections',
    TILE_API: '/api/v1/admin/AdminTileChallenge',
    SIGNED_URL_API: '/api/v1/admin/medialibrary/getsignedurl'

    // EXERCISE_LIST_API: '/api/v1/admin/EventList?type=4',
}
