import {
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyout,
    EuiTitle,
    EuiSpacer,
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyoutFooter,
    EuiFieldText,
    EuiDescribedFormGroup
} from '@elastic/eui'
import { ImageType } from 'API/fetchChallengeImageTypes'
import { AssetUpload } from './AssetUpload'
import { useState } from 'react'
import { IFileModel, fetchSignedUrl, putNewMedia, postChallengeMedia } from 'API/mediaLibrary'
import { useMutation, useQuery } from 'react-query'
import { fetchTileChallenge } from 'API/fetchTileChallenge'
import { updateTileChallenge } from 'API/tiles/updateTileChallenge'
import { EVENT_TYPE } from 'common/constants/eventType'
import { useEffect } from 'react'

interface IAssetFlyoutProps {
    eventType: number
    imageTypeToEdit: ImageType
    challengeId: number
    onClose?: () => void
    refetchImages: () => void
}

export interface IAssetUploadProps {
    eventType?: IAssetFlyoutProps['eventType']
    setFile: React.Dispatch<React.SetStateAction<File | null>>
    file: File | null
    isDisabled: boolean
}

async function handleFileUpload(params: { fileModel: IFileModel; challengeId: number; imageTypeToEdit: ImageType }) {
    const res = await fetchSignedUrl(params.fileModel.name, params.fileModel.type, params.challengeId)
        .then(async (data) => {
            console.log('url signed ✅', data.url)
            params.fileModel.s3URL = data.url
            await putNewMedia(data.url, params.fileModel)
        })
        .then((resp: unknown) => {
            console.log('s3 success ✅', resp)
            params.fileModel = Object.assign(params.fileModel, {
                s3URL: params.fileModel.s3URL?.split('?')[0] || null,
                challengeImageType: params.imageTypeToEdit.description || null,
                challengeImageTypeId: params.imageTypeToEdit.value || null
            })
        })
        .then(() => {
            return postChallengeMedia({
                challengeId: params.challengeId,
                fileModel: params.fileModel
            })
        })

    return res
}

export const AddAssetFlyout = ({
    imageTypeToEdit,
    eventType,
    challengeId,
    onClose = () => null,
    refetchImages
}: IAssetFlyoutProps) => {
    const [file, setFile] = useState<File | null>(null)
    const tileChallengeQuery = useQuery(['tileChallenge', challengeId], () => fetchTileChallenge(challengeId))
    const uploadFileMutation = useMutation(handleFileUpload, {
        onSuccess: (data) => {
            console.log({ data, tileChallengeQuery, imageTypeToEdit, eventType })
            if (
                tileChallengeQuery.data?.challenge?.tiles?.length &&
                eventType === EVENT_TYPE.EXERCISES &&
                imageTypeToEdit.value === 12
            ) {
                updateTileChallenge({
                    ...tileChallengeQuery.data.challenge,
                    tiles: [
                        {
                            ...tileChallengeQuery.data.challenge.tiles[0],
                            challengeVideoId: data.images[data.images.length - 1].challengeImageId
                        }
                    ]
                })
            }
            refetchImages()
            setFile(null)
            onClose()
        }
    })
    const [altText, setAltText] = useState('')

    const onUploadPressed = () => {
        const fileModel: IFileModel = Object.assign(file, {
            preview: URL.createObjectURL(file),
            uploading: false,
            error: null,
            processing: true,
            s3URL: null,
            altText: altText
        })

        uploadFileMutation.mutate({
            fileModel,
            challengeId,
            imageTypeToEdit
        })
    }

    useEffect(() => {
        console.log('render', file)
    })

    return (
        <EuiFlyout size="m" ownFocus onClose={() => onClose()} aria-labelledby="flyoutTitle">
            <EuiFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2 id="flyoutTitle">Add {imageTypeToEdit.description}</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <AssetUpload setFile={setFile} file={file} isDisabled={uploadFileMutation.isLoading} />
                <EuiSpacer size="xl" />
                <EuiDescribedFormGroup
                    fullWidth
                    descriptionFlexItemProps={{
                        style: {
                            maxWidth: '300px'
                        }
                    }}
                    title={<h3>Alt text</h3>}
                    description="Set the alt text for the media file."
                >
                    <EuiFieldText value={altText} onChange={(e) => setAltText(e.target.value)} />
                </EuiDescribedFormGroup>
                <EuiSpacer size="xl" />
            </EuiFlyoutBody>
            <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty iconType="cross" onClick={() => onClose()} flush="left">
                            Cancel
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton
                            isLoading={uploadFileMutation.isLoading}
                            disabled={uploadFileMutation.isLoading || !file || !altText.length}
                            onClick={() => onUploadPressed()}
                            fill
                        >
                            Upload
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlyoutFooter>
        </EuiFlyout>
    )
}
