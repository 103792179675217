import { useEffect, useState } from 'react'
import { RouteComponentProps, Router } from '@reach/router'
import { QueryClient, QueryClientProvider } from 'react-query'

import { DashboardIndexPage } from './pages/dashboard/index'
import { LoginPage } from './pages/Login'
import { ChallengeOverviewPage } from './pages/challenges/OverviewPage'
import { ChallengeManagePage } from 'pages/challenges/ManagePage'
import { ChallengePrioritizePage } from 'pages/challenges/PrioritizePage'
import { NotFoundPage } from 'pages/notfound'
import { ChallengeDetailPage } from 'pages/challenges/DetailPage'
import { startMirage } from 'mirage.dev'
import { ChallengeDetailOverviewPage } from 'pages/challenges/DetailOverviewPage'
import { ExerciseDetailPage } from 'pages/exercises/DetailPages/DetailPage'
import { ExerciseDetailOverviewPage } from 'pages/exercises/DetailPages/DetailOverviewPage'
import { ProductsOverviewPage } from 'pages/products/OverviewPage'
import { ProductManagePage } from './pages/products/ManagePage'
import { ProductPrioritizePage } from 'pages/products/PrioritizePage'
import { RacesOverviewPage } from 'pages/races/OverviewPage'
import { RaceManagePage } from 'pages/races/ManagePage'
import { RacesPrioritizePage } from 'pages/races/PrioritizePage'
import { ExerciseOverviewPage } from 'pages/exercises/OverviewPage'
import { ExerciseManagePage } from 'pages/exercises/ManagePage'
import { ExercisePrioritizePage } from 'pages/exercises/PrioritizePage'
// import { ExercisePrioritizePage } from 'pages/exercises/PrioritizePage'

// TODO: disable in prod
// startMirage()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Challenges = (props: RouteComponentProps & { children: React.ReactNode }) => <div>{props.children}</div>

const Exercises = (props: RouteComponentProps & { children: React.ReactNode }) => <div>{props.children}</div>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Products = (props: RouteComponentProps & { children: any }) => <div>{props.children}</div>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Races = (props: RouteComponentProps & { children: any }) => <div>{props.children}</div>
// eslint-disable-next-line @typescript-eslint/no-explicit-any

const RedirectLogin = (props: RouteComponentProps) => {
    useEffect(() => {
        if (!localStorage.getItem('yf-admin-token')) {
            props.navigate && props.navigate('/login')
        }
    }, [])
    return null
}

const queryClient = new QueryClient()

function App() {
    const [loggedIn, setLoggedIn] = useState<boolean>(false)

    useEffect(() => {
        if (localStorage.getItem('yf-admin-token')) {
            setLoggedIn(true)
        } else {
            setLoggedIn(false)
        }
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <Router>
                <LoginPage setLoggedIn={setLoggedIn} path="/login" />

                {loggedIn ? (
                    <>
                        <DashboardIndexPage path="/" />

                        <Challenges path="challenges">
                            <ChallengeOverviewPage path="/" />
                            <ChallengeManagePage path="manage" />
                            <ChallengePrioritizePage path="/prioritize" />
                            <ChallengeDetailPage path="/:challengeId" />
                            <ChallengeDetailOverviewPage path="/:challengeId/overview" />
                        </Challenges>

                        <Exercises path="exercises">
                            <ExerciseOverviewPage path="/" />
                            <ExerciseManagePage path="manage" />
                            <ExercisePrioritizePage path="prioritize" />
                            <ExerciseDetailPage path=":challengeId" />
                            <ExerciseDetailOverviewPage path=":challengeId/overview" />
                        </Exercises>
                        <Products path="products">
                            <ProductsOverviewPage path="/" />
                            <ProductManagePage path="/manage" />
                            <ProductPrioritizePage path="prioritize" />
                        </Products>

                        <Races path="races">
                            <RacesOverviewPage path="/" />
                            <RaceManagePage path="/manage" />
                            <RacesPrioritizePage path="prioritize" />
                        </Races>
                        <NotFoundPage default />
                    </>
                ) : (
                    <RedirectLogin default />
                )}
            </Router>
        </QueryClientProvider>
    )
}

export default App
