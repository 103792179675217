import { EuiButton, EuiContextMenuItem, EuiContextMenuPanel, EuiIcon, EuiPopover } from '@elastic/eui'
import { useEffect, useState } from 'react'

interface IDetailPopoverProps {
    isPrivate: boolean
    disabled: boolean
    loading: boolean
    onVisibilityChange: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const DetailPopover = ({ isPrivate, onVisibilityChange, disabled, loading }: IDetailPopoverProps) => {
    const [isPopoverOpen, setPopover] = useState(false)
    const [isPrivateChallenge, setPrivateChallenge] = useState(isPrivate)

    useEffect(() => {
        setPrivateChallenge(isPrivate)
    }, [isPrivate])

    const onButtonClick = () => {
        setPopover(!isPopoverOpen)
    }

    const closePopover = () => {
        setPopover(false)
    }

    const handleVisibility = (status: string) => {
        if (status === 'private') {
            onVisibilityChange('isPrivate', true)
            setPrivateChallenge(true)
        } else if (status === 'public') {
            onVisibilityChange('isPrivate', false)
            setPrivateChallenge(false)
        }
    }

    const items = () => {
        if (isPrivateChallenge)
            return [
                <EuiContextMenuItem
                    key="publish"
                    color=""
                    icon={<EuiIcon type="eye" size="m" color="primary" />}
                    onClick={() => handleVisibility('public')}
                >
                    Publish
                </EuiContextMenuItem>,
                <EuiContextMenuItem
                    key="delete"
                    icon={<EuiIcon type="trash" size="m" color="danger" />}
                    color=""
                    onClick={closePopover}
                >
                    Delete
                </EuiContextMenuItem>
            ]
        else
            return [
                <EuiContextMenuItem key="publish" color="" onClick={() => handleVisibility('private')}>
                    Private
                </EuiContextMenuItem>
            ]
    }
    const button = (
        <EuiButton disabled={disabled || loading} iconType="arrowDown" iconSide="right" onClick={onButtonClick}>
            {isPrivateChallenge ? <b>PRIVATE</b> : <b>PUBLIC</b>}
        </EuiButton>
    )

    return (
        <EuiPopover
            id="smallContextMenuExample"
            button={button}
            isOpen={isPopoverOpen}
            closePopover={closePopover}
            panelPaddingSize="none"
            anchorPosition="downLeft"
        >
            <EuiContextMenuPanel size="s" items={items()} />
        </EuiPopover>
    )
}
