import { RouteComponentProps } from '@reach/router'
import { EuiEmptyPrompt, EuiButton } from '@elastic/eui'
import React, { FC } from 'react'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'

type NotFoundPage = RouteComponentProps

export const NotFoundPage: FC<NotFoundPage> = ({ navigate }) => {
    return (
        <AdminPageLayout>
            <h1>404</h1>
            <EuiEmptyPrompt
                iconType="alert"
                iconColor="danger"
                title={<h2>404</h2>}
                body={<p>This page does not exist. Contact your administrator for help.</p>}
                actions={[
                    <EuiButton key="0" color="primary" fill onClick={() => navigate && navigate('/')}>
                        Dashboard Home
                    </EuiButton>
                ]}
            />
        </AdminPageLayout>
    )
}
