import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { StatHeader } from 'components/StatHeader'
import { FC } from 'react'

type ExercisesOverviewPageProps = RouteComponentProps

export const ExerciseOverviewPage: FC<ExercisesOverviewPageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.EXERCISES }}>
            <StatHeader eventType={EVENT_TYPE.EXERCISES} />
        </AdminPageLayout>
    )
}
