import { fetchChallengeImages, postChallengeImage, IPostChallengeImageParams } from 'API/challengeImages'
import { useQuery } from 'react-query'

export function useChallengeImages(challengeId?: number) {
    const challengeImages = useQuery(['challengeImages', challengeId], () => fetchChallengeImages(challengeId), {
        enabled: !!challengeId,
        refetchOnWindowFocus: false
    })

    return challengeImages
}

export function useAddChallengeImage(params: IPostChallengeImageParams) {
    const challengeImages = useQuery(['addChallengeImage', params.challengeId], () => postChallengeImage(params), {
        enabled: !!params.challengeId
    })

    return challengeImages
}
