import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { FC } from 'react'
import { StatHeader } from 'components/StatHeader'

type ChallengeOverviewPageProps = RouteComponentProps

export const ChallengeOverviewPage: FC<ChallengeOverviewPageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.CHALLENGE }}>
            <StatHeader eventType={EVENT_TYPE.CHALLENGE} />
        </AdminPageLayout>
    )
}
