import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { StatHeader } from 'components/StatHeader'
import ConstructionModal from 'hooks/useConstructionModal'
import { FC, useRef } from 'react'

type ProductsOverviewPageProps = RouteComponentProps

export const ProductsOverviewPage: FC<ProductsOverviewPageProps> = () => {
    const modal = useRef<any>()

    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.SWAG }}>
            <StatHeader eventType={EVENT_TYPE.SWAG} />
        </AdminPageLayout>
    )
}
