import { EuiPageHeader, EuiButton, EuiIcon, EuiText } from '@elastic/eui'
import { useMatch, useNavigate } from '@reach/router'
import React, { FC } from 'react'
import { DetailPopover } from './DetailPopover'

type ChallengeDetailPageHeaderProps = {
    pageTitle?: string
    isPrivate?: boolean
    isLoading: boolean
    onSaveClicked: () => void
    challengeId: number | null
}

export const ChallengeDetailPageHeader: FC<ChallengeDetailPageHeaderProps> = ({
    pageTitle,
    isPrivate,
    isLoading,
    onSaveClicked,
    challengeId
}) => {
    const navigate = useNavigate()
    const matchOverview = useMatch('/challenges/:challengeId/overview')
    const matchEdit = useMatch('/challenges/:challengeId')

    if (matchEdit?.challengeId === 'manage') return null

    if (matchEdit?.challengeId === 'prioritize') return null

    if (!matchEdit && !matchOverview) return null

    return (
        <EuiPageHeader
            restrictWidth={false}
            pageTitle={isLoading ? 'Loading...' : pageTitle}
            tabs={
                isPrivate
                    ? [
                          {
                              label: 'Overview',
                              isSelected: matchOverview !== null,
                              disabled: true
                          },
                          {
                              label: 'Edit',
                              onClick: () => navigate(`/challenges/${challengeId}`),
                              isSelected: matchEdit !== null
                          },
                          {
                              label: 'Preview',
                              disabled: true
                          }
                      ]
                    : [
                          {
                              label: 'Overview',
                              onClick: () => navigate(`/challenges/${challengeId}/overview`),
                              isSelected: matchOverview !== null
                          },
                          {
                              label: 'Edit',
                              onClick: () => navigate(`/challenges/${challengeId}`),
                              isSelected: matchEdit !== null
                          },
                          {
                              label: 'Preview',
                              disabled: true
                          }
                      ]
            }
            rightSideGroupProps={{
                justifyContent: 'center',
                alignItems: 'center'
            }}
            rightSideItems={[
                <DetailPopover key="0" />,
                <EuiButton disabled={isLoading} key="1" fill onClick={onSaveClicked}>
                    Save
                </EuiButton>,
                <div key="2" style={{ display: 'flex', alignItems: 'center' }}>
                    {isPrivate != null && (
                        <>
                            <EuiIcon style={{ marginRight: '5px' }} type="eye"></EuiIcon>
                            <EuiText size="s" color="subdued" style={{ marginRight: '5px' }}>
                                Visibility:{' '}
                            </EuiText>
                            <EuiText size="s" color="default">
                                {isPrivate ? <b>PRIVATE</b> : <b>PUBLIC</b>}
                            </EuiText>
                        </>
                    )}
                </div>
            ]}
        />
    )
}
