import axios from 'axios'
import moment from 'moment'

export const fetchRegistrations = async () => {
    const res = await axios.get(`https://old-tree-7434.fly.dev/registrations`)

    const parsedRegistrations = res.data?.registrations?.map(
        (view: { day: moment.MomentInput; web_registrations: any; mobile_registrations: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: 'registrations',
                y: view.web_registrations + view.mobile_registrations
            }
        }
    )

    const parsedWebRegistrations = res.data?.registrations?.map(
        (view: { day: moment.MomentInput; web_registrations: any; mobile_registrations: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: 'web_registrations',
                y: view.web_registrations
            }
        }
    )

    const parsedMobileRegistrations = res.data?.registrations?.map(
        (view: { day: moment.MomentInput; web_registrations: any; mobile_registrations: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: 'mobile_registrations',
                y: view.mobile_registrations
            }
        }
    )

    return { registrations: [...parsedRegistrations, ...parsedMobileRegistrations, ...parsedWebRegistrations] }
}
