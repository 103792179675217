import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { EventListDataGrid } from 'components/EventListDataGrid'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { FC } from 'react'

type RaceManagePageProps = RouteComponentProps

export const RaceManagePage: FC<RaceManagePageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.RACES }}>
            <EventListDataGrid eventType={EVENT_TYPE.RACES} />
        </AdminPageLayout>
    )
}
