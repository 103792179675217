import { EuiButton, EuiIcon, EuiPageHeader, EuiText } from '@elastic/eui'
import { useMatch, useNavigate } from '@reach/router'
import { FC } from 'react'
import { DetailPopover } from './DetailPopover'

type ExerciseDetailPageHeaderProps = {
    pageTitle?: string
    isPrivate: boolean
    isLoading: boolean
    isError: boolean
    onSaveClicked: () => void
    challengeId: number | null
    onVisibilityChange: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const ExerciseDetailPageHeader: FC<ExerciseDetailPageHeaderProps> = ({
    pageTitle,
    isPrivate,
    isLoading,
    onSaveClicked,
    challengeId,
    onVisibilityChange,
    isError
}) => {
    const navigate = useNavigate()
    const matchOverview = useMatch('/exercises/:challengeId/overview')
    const matchEdit = useMatch('/exercises/:challengeId')

    if (matchEdit?.challengeId === 'manage') return null

    if (matchEdit?.challengeId === 'prioritize') return null

    if (!matchEdit && !matchOverview) return null

    return (
        <EuiPageHeader
            restrictWidth={false}
            pageTitle={isLoading ? 'Loading...' : pageTitle ?? ' '}
            tabs={
                isPrivate
                    ? [
                          {
                              label: 'Overview',
                              isSelected: matchOverview !== null,
                              disabled: true
                          },
                          {
                              label: 'Edit',
                              onClick: () => navigate(`/exercises/${challengeId}`),
                              isSelected: matchEdit !== null
                          },
                          {
                              label: 'Preview',
                              disabled: true
                          }
                      ]
                    : [
                          {
                              label: 'Overview',
                              onClick: () => navigate(`/exercises/${challengeId}/overview`),
                              isSelected: matchOverview !== null
                          },
                          {
                              label: 'Edit',
                              onClick: () => navigate(`/exercises/${challengeId}`),
                              isSelected: matchEdit !== null
                          },
                          {
                              label: 'Preview',
                              disabled: true
                          }
                      ]
            }
            rightSideGroupProps={{
                justifyContent: 'center',
                alignItems: 'center'
            }}
            rightSideItems={[
                <DetailPopover
                    loading={isLoading}
                    disabled={isError}
                    key="0"
                    isPrivate={isPrivate}
                    onVisibilityChange={onVisibilityChange}
                />,
                <EuiButton disabled={isError || isLoading} key="1" fill onClick={onSaveClicked}>
                    Save
                </EuiButton>
            ]}
        />
    )
}
