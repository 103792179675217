import { EuiSpacer } from '@elastic/eui'
import { RouteComponentProps } from '@reach/router'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import ConstructionModal from 'hooks/useConstructionModal'
import { FC, ReactNode, useRef } from 'react'
import { GoogleAdPanel } from './components/Panels/GoogleAdPanel'
import { MAUPanel } from './components/Panels/MAUPanel'
import { NewUsersPanel } from './components/Panels/NewUsersPanel'
import { RevenuePanel } from './components/Panels/RevenuePanel'

interface DashboardIndexPageProps extends RouteComponentProps {
    children?: ReactNode
}
export const DashboardIndexPage: FC<DashboardIndexPageProps> = () => {
    return (
        <AdminPageLayout>
            <NewUsersPanel />
            <EuiSpacer size="l" />
            <GoogleAdPanel />
            <EuiSpacer size="l" />
            <RevenuePanel />
            {/* <EuiSpacer size="l" /> */}
            {/* <MAUPanel /> */}
        </AdminPageLayout>
    )
}
