import axios from 'axios'
import moment from 'moment'

export const fetchLPRegistrations = async () => {
    const res = await axios.get(`https://old-tree-7434.fly.dev/lpregistrations`)

    console.log({ res })

    const parsedRegistrations = res.data?.registrations?.map(
        (view: { day: moment.MomentInput; context_page_url: any; count: any }) => {
            return {
                x: moment(view.day).toISOString(),
                g: view.context_page_url ?? '',
                y: view.count
            }
        }
    )

    return { registrations: parsedRegistrations }
}
