import axios from 'axios'
import { fetchChallengeList } from 'hooks/useChallengeList'
import moment from 'moment'

export const fetchAllRaceViews = async () => {
    const res = await axios.get('https://old-tree-7434.fly.dev/all-views')
    const challengeListRes = await fetchChallengeList()

    console.log({ challengeListRes })

    const parsedWebViews =
        res.data?.allWebViews
            ?.map((view: { day: moment.MomentInput; product_id: any; count: any }) => {
                return {
                    x: moment(view.day).toISOString(),
                    g:
                        challengeListRes?.challenges?.find((i) => i.challengeId === Number(view.product_id))?.name ??
                        view.product_id,
                    y: view.count
                }
            })
            .filter((i: any) => i.y > 20) ?? []

    const parsedMobileViews =
        res.data?.allMobileViews
            ?.map((view: { day: moment.MomentInput; product_id: any; count: any }) => {
                return {
                    x: moment(view.day).toISOString(),
                    g:
                        challengeListRes?.challenges?.find((i) => i.challengeId === Number(view.product_id))?.name ??
                        view.product_id,
                    y: view.count
                }
            })
            .filter((i: any) => i.y > 20) ?? []

    return { webViews: parsedWebViews, mobileViews: parsedMobileViews }
}
