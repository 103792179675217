import axios from '../common/axios'
import { ENDPOINTS } from '../common/constants/endpoints'
import { CreateEventFlyoutProps } from 'components/CreateEventFlyout'
import { createIntialTile } from './tiles/createInitialTile'
import { EVENT_TYPE } from 'common/constants/eventType'

type CreateChallengeParams = {
    name: string
    challengeUrl: string
    eventType: CreateEventFlyoutProps['eventType']
}

const emptyChallenge = {
    name: '', // required
    challengeUrl: '', // required
    challengeActivityTypes: [], // default val
    addonDiscount: 0, // default val
    fundraisingMessage: '<p><br></p>', // default val
    isPrivateChallenge: true, // default val
    initialIsCyf: false, // default val
    isCyf: false, // default val
    maxNumberOfRegistrations: 0, // default val
    price: 0, // default val
    sortWeight: 6000, // default val
    topMediaBlock: '', // default val
    registrationCloseDate: null,
    activityTypes: null,
    challengeId: null,
    cyfUntilTS: null,
    endDate: null,
    initialCyfUntilTS: null,
    registrationClosedDate: null,
    startDate: null,
    isExercise: false
}

export const createChallenge = async (params: CreateChallengeParams) => {
    const createChallengeRes = await axios.post(`${ENDPOINTS.CHALLENGE_DETAILS_API}`, {
        ...emptyChallenge,
        name: params.name,
        challengeUrl: params.challengeUrl,
        isExercise: params.eventType === EVENT_TYPE.EXERCISES,
        is30Day: params.eventType === EVENT_TYPE.CHALLENGE,
        isSwag: params.eventType === EVENT_TYPE.SWAG
    })

    if (createChallengeRes.status !== 200 || createChallengeRes.data?.hasError) {
        throw new Error(createChallengeRes.data.message)
    }

    if (params.eventType === EVENT_TYPE.EXERCISES) {
        await createIntialTile(createChallengeRes.data.challenge.challengeId)
    }

    return createChallengeRes.data.challenge
}
