import { useQuery, UseQueryResult } from 'react-query'
import axios from '../common/axios'
import { ENDPOINTS } from '../common/constants/endpoints'

interface UserResponse {
    email: string
    firstName: string
    lastName: string
    athleteId: number
    userId: number
    userName: string
    facebookUserId: null
    appleId: null
    googleUserId: null
    dateCreated: string
    city: string
    state: string
    zip: string
    gender: number
    dob: string
    registered: boolean
    timeZone: string
    fbAccessToken: null
    token: Token
    accessToken: string
    isAuthenticated: boolean
    isAdmin: boolean
    isSuperAdmin: boolean
    hasError: boolean
    message: null
    intercomUserHash: string
    intercomUserHashAndroid: string
    intercomUserHashIOS: string
    redirectUrl: null
    imgProfileUrl: string
    voucherifyReferralCode: null
    stripeSubscriptionId: null
    stripePlanName: null
    useStagingCodepush: boolean
    hasRaces: boolean
    emailForPixel: string
    firstNameForPixel: string
    lastNameForPixel: string
    genderForPixel: string
    cityForPixel: string
    stateForPixel: string
    zipForPixel: string
    dobForPixel: string
}

interface Token {
    access_token: string
    expires_in: number
}

const fetchUserProfile = async () => {
    const userResponse = await axios.get(ENDPOINTS.REFRESH_API)

    if (userResponse.status !== 200 || userResponse.data?.hasError) {
        throw new Error(userResponse.data.message)
    }

    localStorage.setItem('admin:profile', JSON.stringify(userResponse.data))

    // const data: UserResponse = typehole.t(userResponse.data)
    const data: UserResponse = userResponse.data

    return data
}

export function useUserProfile() {
    const user: UseQueryResult<UserResponse, unknown> = useQuery('admin:profile', fetchUserProfile, {
        // if localstorage value does not exist, force re-fetch
        initialDataUpdatedAt: localStorage.getItem('admin:profile')
            ? new Date().getTime()
            : new Date().getTime() - 900000,
        initialData: JSON.parse(localStorage.getItem('admin:profile') || 'null'),
        staleTime: 900000 // 15min
    })

    return user
}
