import {
    EuiPanel,
    EuiFieldText,
    EuiDescribedFormGroup,
    EuiFormRow,
    EuiTextArea,
    EuiSpacer,
    EuiFieldNumber,
    EuiFlexGroup,
    EuiFlexItem,
    EuiDatePicker,
    EuiEmptyPrompt,
    EuiLoadingSpinner
} from '@elastic/eui'
import { Formik, Form, Field, FieldProps } from 'formik'
import * as Yup from 'yup'
import 'react-quill/dist/quill.core.css'
import ReactQuill from 'react-quill'
import { RouteComponentProps } from '@reach/router'
import moment, { Moment } from 'moment'
import React, { FC, useCallback, useEffect, useRef } from 'react'
import { useChallengeDetails } from '../../hooks/useChallengeDetails'
import { AWSPersonalComboBox } from './components/Detail/AWSPersonalComboBox'
import { MetaKeywordsComboBox } from './components/Detail/MetaKeywordsComboBox'
import { MediaPanel } from '../../components/MediaPanel'
import slugify from 'slugify'
import _ from 'lodash'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { fetchTileChallenge } from 'API/fetchTileChallenge'
import { EVENT_TYPE } from 'common/constants/eventType'
import { IChallenge, updateChallenge } from 'API/updateChallenge'
import { useToastsStore } from 'components/GlobalToastList'

interface ChallengeDetailPageProps extends RouteComponentProps {
    challengeId?: number
}

// addonDiscount: 0
// branchLink: null
// challengeActivityTypes: []
// challengeId: 574
// challengeUrl: "test-dev-4"
// createTS: "2021-06-07T01:10:23.17"
// createUserId: 4
// editTS: null
// editUserId: 0
// endDate: null
// fundraisingMessage: "<p><br></p>"
// hideDevices: false
// isAddonOptional: false
// isHowFarCanYouGo: false
// isPrivateChallenge: true
// isSendNow: false
// isSwag: false
// logo: null
// maxNumberOfRegistrations: 0
// metaDescription: null
// metaKeywords: null
// name: "test dev 4"
// personalizeKeywords: null
// price: 0
// registrationCloseDate: null
// sortWeight: 6000
// startDate: null
// timeZone: null
// topMediaBlock: ""
// totalDistance: 0

const CreateChallengeSchema = Yup.object().shape({
    addonDiscount: Yup.number().required(),
    branchLink: Yup.string().nullable(),
    challengeActivityTypes: Yup.array().required(),
    challengeId: Yup.number().required(),
    challengeUrl: Yup.string().max(60, 'URL must be less than 60 chars').required('Challenge URL is required'),
    createTS: Yup.string().optional(),
    createUserId: Yup.number().required(),
    editTS: Yup.string().nullable(),
    editUserId: Yup.number().required(),
    endDate: Yup.string().nullable(),
    fundraisingMessage: Yup.string().required(),
    hideDevices: Yup.boolean().required(),
    isAddonOptional: Yup.boolean().required(),
    isHowFarCanYouGo: Yup.boolean().required(),
    isPrivateChallenge: Yup.boolean().required(),
    isSendNow: Yup.boolean().required(),
    isSwag: Yup.boolean().required(),
    logo: Yup.string().nullable(),
    maxNumberOfRegistrations: Yup.number().required(),
    metaDescription: Yup.string().max(500).nullable(),
    metaKeywords: Yup.string().nullable(),
    name: Yup.string().max(60, 'Name must be less than 60 chars').required('Challenge name is required'),
    personalizeKeywords: Yup.string().nullable(),
    price: Yup.number().required(),
    registrationCloseDate: Yup.string().nullable(),
    sortWeight: Yup.number().required(),
    startDate: Yup.string().nullable(),
    timeZone: Yup.string().nullable(),
    topMediaBlock: Yup.string().optional(),
    totalDistance: Yup.number().required()
})

export const ChallengeDetailPage: FC<ChallengeDetailPageProps> = ({ challengeId }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formRef = useRef<any>()
    const { data, status } = useChallengeDetails(challengeId || undefined)
    const addToast = useToastsStore((state) => state.addToast)

    useEffect(() => {
        async function fetchTD() {
            const tileData = await fetchTileChallenge(challengeId)
            console.log({ tileData })
        }

        fetchTD()
    }, [])

    const onSaveClicked = useCallback(() => {
        formRef?.current?.handleSubmit()
    }, [formRef])

    return (
        <AdminPageLayout
            headerProps={{
                eventType: EVENT_TYPE.CHALLENGE,
                challengeId: challengeId || undefined,
                isLoading: status === 'loading',
                pageTitle: data?.challenge?.name || null,
                isPrivate: data?.challenge?.isPrivateChallenge ?? true,
                onSaveClicked: onSaveClicked
            }}
            // CustomPageHeader={(props: MatchRenderProps<unknown>) => (
            //     <ChallengeDetailPageHeader
            //         challengeId={challengeId || null}
            //         onSaveClicked={formRef?.current ? formRef?.current?.handleSubmit : () => null}
            //         isLoading={status === 'loading'}
            //         pageTitle={data?.challenge?.name || null}
            //         isPrivate={data?.challenge?.isPrivateChallenge ?? true}
            //         {...props}
            //     />
            // )}
        >
            {status === 'loading' ? (
                <EuiEmptyPrompt icon={<EuiLoadingSpinner size="xl" />} title={<h2>Loading Challenge Details...</h2>} />
            ) : status === 'error' ? (
                <EuiEmptyPrompt
                    iconType="alert"
                    iconColor="danger"
                    title={<h2>Error loading challenge</h2>}
                    body={<p>There was an error loading the challenge details. Contact your administrator for help.</p>}
                />
            ) : (
                <>
                    <Formik
                        innerRef={formRef}
                        validationSchema={CreateChallengeSchema}
                        initialValues={{
                            addonDiscount: data?.challenge?.addonDiscount,
                            branchLink: data?.challenge?.branchLink,
                            challengeActivityTypes: data?.challenge?.challengeActivityTypes,
                            challengeId: data?.challenge?.challengeId,
                            challengeUrl: data?.challenge?.challengeUrl,
                            createTS: data?.challenge?.createTS,
                            createUserId: data?.challenge?.createUserId,
                            editTS: data?.challenge?.editTS,
                            editUserId: data?.challenge?.editUserId,
                            endDate: data?.challenge?.endDate,
                            fundraisingMessage: data?.challenge?.fundraisingMessage,
                            hideDevices: data?.challenge?.hideDevices,
                            isAddonOptional: data?.challenge?.isAddonOptional,
                            isHowFarCanYouGo: data?.challenge?.isHowFarCanYouGo,
                            isPrivateChallenge: data?.challenge?.isPrivateChallenge,
                            isSendNow: data?.challenge?.isSendNow,
                            isSwag: data?.challenge?.isSwag,
                            logo: data?.challenge?.logo,
                            maxNumberOfRegistrations: data?.challenge?.maxNumberOfRegistrations,
                            metaDescription: data?.challenge?.metaDescription,
                            metaKeywords: data?.challenge?.metaKeywords,
                            name: data?.challenge?.name,
                            personalizeKeywords: data?.challenge?.personalizeKeywords,
                            price: data?.challenge?.price,
                            registrationCloseDate: data?.challenge?.registrationCloseDate,
                            sortWeight: data?.challenge?.sortWeight,
                            startDate: data?.challenge?.startDate,
                            timeZone: data?.challenge?.timeZone,
                            topMediaBlock: data?.challenge?.topMediaBlock,
                            totalDistance: data?.challenge?.totalDistance
                        }}
                        isInitialValid={true}
                        enableReinitialize
                        onSubmit={async (values) => {
                            console.log({ values })
                            const updatedChallenge: IChallenge = _.cloneDeep(data.challenge)
                            updatedChallenge.challengeUrl = values.challengeUrl
                            updatedChallenge.endDate = moment(values.endDate).toISOString()
                            updatedChallenge.maxNumberOfRegistrations = values.maxNumberOfRegistrations
                            updatedChallenge.fundraisingMessage = values.fundraisingMessage
                            updatedChallenge.metaDescription = values.metaDescription
                            updatedChallenge.metaKeywords = values.metaKeywords
                            updatedChallenge.name = values.name
                            updatedChallenge.personalizeKeywords = values.personalizeKeywords
                            updatedChallenge.registrationCloseDate = moment(values.endDate).toISOString()
                            updatedChallenge.sortWeight = values.sortWeight
                            updatedChallenge.startDate = moment(values.startDate).toISOString()
                            updatedChallenge.startDate = moment(values.startDate).toISOString()
                            updatedChallenge.isPrivateChallenge = values.isPrivateChallenge

                            try {
                                await updateChallenge(updatedChallenge).then(() =>
                                    addToast({
                                        title: 'Event successfully saved',
                                        id: data.challengeId,
                                        color: 'success'
                                    })
                                )
                            } catch (er) {
                                addToast({
                                    color: 'danger',
                                    id: data.challengeId,
                                    title: `There was an error saving this Event.`,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    text: <p>{(er as any).message}</p>
                                })
                            }
                        }}
                    >
                        {({ errors, touched, isSubmitting, setFieldValue, handleBlur, values, handleChange }) => (
                            <EuiPanel paddingSize="l" hasShadow={true}>
                                <EuiDescribedFormGroup
                                    fullWidth
                                    descriptionFlexItemProps={{
                                        style: {
                                            maxWidth: '300px'
                                        }
                                    }}
                                    title={<h3>General Info</h3>}
                                    description="This information is all required prior to publishing the exercise."
                                >
                                    <Form>
                                        <EuiFormRow
                                            fullWidth
                                            label="Title"
                                            isInvalid={touched.name && errors.name ? true : false}
                                            error={errors.name}
                                        >
                                            <Field name="name">
                                                {({ field }: FieldProps) => (
                                                    <EuiFieldText
                                                        fullWidth
                                                        disabled={isSubmitting}
                                                        {...field}
                                                        autoComplete="off"
                                                        onChange={(e) => {
                                                            field.onChange(e)
                                                            setFieldValue(
                                                                'challengeUrl',
                                                                slugify(e.target.value, {
                                                                    lower: true,
                                                                    strict: true
                                                                })
                                                            )
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </EuiFormRow>

                                        <EuiSpacer size="xl" />

                                        <EuiFormRow
                                            fullWidth
                                            label="Challenge URL"
                                            isInvalid={touched.challengeUrl && errors.challengeUrl ? true : false}
                                            error={errors.challengeUrl}
                                        >
                                            <Field
                                                as={EuiFieldText}
                                                name="challengeUrl"
                                                aria-label="url"
                                                onBlur={handleBlur('challengeUrl')}
                                            />
                                        </EuiFormRow>

                                        <EuiSpacer size="xl" />

                                        <EuiFormRow
                                            fullWidth
                                            label="Meta Description"
                                            isInvalid={touched.metaDescription && errors.metaDescription ? true : false}
                                            error={errors.metaDescription}
                                            helpText={
                                                <span>
                                                    This is an SEO meta description that will be provided to you.
                                                </span>
                                            }
                                        >
                                            <Field
                                                as={EuiTextArea}
                                                isInvalid={
                                                    touched.metaDescription && errors.metaDescription ? true : false
                                                }
                                                name="metaDescription"
                                                placeholder="Write a description for the exercise here."
                                                onBlur={handleBlur('metaDescription')}
                                            />
                                        </EuiFormRow>

                                        <EuiSpacer size="xl" />

                                        <EuiFormRow
                                            label="Message"
                                            hasChildLabel={false}
                                            fullWidth
                                            isInvalid={
                                                touched.fundraisingMessage && errors.fundraisingMessage ? true : false
                                            }
                                            error={errors.fundraisingMessage}
                                        >
                                            {/* <EuiTextArea fullWidth name="message" /> */}
                                            <ReactQuill
                                                // aria-label="Exercise Message form"
                                                value={values.fundraisingMessage}
                                                onChange={handleChange('fundraisingMessage')}
                                                className="euiTextArea euiTextArea--resizeVertical euiTextArea--fullWidth"
                                            />
                                        </EuiFormRow>

                                        <EuiSpacer size="xl" />

                                        <EuiFormRow
                                            isInvalid={touched.metaKeywords && errors.metaKeywords ? true : false}
                                            error={errors.metaKeywords}
                                        >
                                            {/* TODO: Need to refactor this MetaKeywordsComboBox and the AWSComboBox to be a single component. They are essentiially the same. */}

                                            <Field
                                                as={MetaKeywordsComboBox}
                                                name="metaKeywords"
                                                initialData={values.metaKeywords}
                                                formikField="metaKeywords"
                                                aria-label="A form for meta keywords."
                                                onBlur={handleBlur('metaKeywords')}
                                            />
                                        </EuiFormRow>

                                        <EuiSpacer size="xl" />

                                        <EuiFormRow
                                            isInvalid={
                                                touched.personalizeKeywords && errors.personalizeKeywords ? true : false
                                            }
                                            error={errors.personalizeKeywords}
                                        >
                                            <Field
                                                as={AWSPersonalComboBox}
                                                name="personalizeKeywords"
                                                placeholder="Select or create options"
                                                initialData={values.personalizeKeywords}
                                                formikField="personalizeKeywords"
                                                onBlur={handleBlur('personalizeKeywords')}
                                            />
                                        </EuiFormRow>

                                        <EuiSpacer size="xl" />

                                        <EuiFormRow
                                            fullWidth
                                            label="Sort Weight"
                                            isInvalid={touched.sortWeight && errors.sortWeight ? true : false}
                                            error={errors.sortWeight}
                                            helpText={
                                                <span>
                                                    The higher the number, the nearer to the top of the list it will be
                                                    on the Challenges screen.
                                                </span>
                                            }
                                        >
                                            <EuiFieldNumber
                                                name="sortWeight"
                                                aria-label="A form for setting the sort weight."
                                                value={values.sortWeight}
                                                onChange={handleChange('sortWeight')}
                                            />
                                        </EuiFormRow>

                                        <EuiSpacer size="xl" />

                                        <EuiFlexGroup style={{ maxWidth: 600 }}>
                                            <EuiFlexItem>
                                                <EuiFormRow
                                                    label="Start date"
                                                    isInvalid={touched.startDate && errors.startDate ? true : false}
                                                    error={errors.startDate}
                                                >
                                                    <Field
                                                        as={EuiDatePicker}
                                                        name="startDate"
                                                        selected={values.startDate ? moment(values.startDate) : null}
                                                        onChange={(date: Moment) =>
                                                            setFieldValue('startDate', date.format('MM/DD/YYYY'))
                                                        }
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                <EuiFormRow
                                                    label="End date"
                                                    isInvalid={touched.endDate && errors.endDate ? true : false}
                                                    error={errors.endDate}
                                                >
                                                    <Field
                                                        as={EuiDatePicker}
                                                        name="endDate"
                                                        selected={values.endDate ? moment(values.endDate) : null}
                                                        onChange={(date: Moment) =>
                                                            setFieldValue('endDate', date.format('MM/DD/YYYY'))
                                                        }
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>

                                        <EuiSpacer size="xl" />

                                        <EuiFlexGroup style={{ maxWidth: 600 }}>
                                            <EuiFlexItem>
                                                <EuiFormRow
                                                    label="Registration close date"
                                                    isInvalid={touched.endDate && errors.endDate ? true : false}
                                                    error={errors.endDate}
                                                >
                                                    <Field
                                                        as={EuiDatePicker}
                                                        name="closeDate"
                                                        selected={values.endDate ? moment(values.endDate) : null}
                                                        onChange={(date: Moment) =>
                                                            setFieldValue('closeDate', date.format('MM/DD/YYYY'))
                                                        }
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                            <EuiFlexItem>
                                                <EuiFormRow
                                                    label="Maximum Registrations"
                                                    isInvalid={
                                                        touched.maxNumberOfRegistrations &&
                                                        errors.maxNumberOfRegistrations
                                                            ? true
                                                            : false
                                                    }
                                                    error={errors.maxNumberOfRegistrations}
                                                >
                                                    <Field
                                                        as={EuiFieldNumber}
                                                        name="maxRegistrations"
                                                        value={values.maxNumberOfRegistrations}
                                                        onBlur={handleBlur('maxNumberOfRegistrations')}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </Form>
                                </EuiDescribedFormGroup>
                            </EuiPanel>
                        )}
                    </Formik>

                    <EuiSpacer size="xl" />

                    {challengeId && <MediaPanel challengeId={challengeId} eventType={EVENT_TYPE.CHALLENGE} />}
                </>
            )}
        </AdminPageLayout>
    )
}
