import { ChallengeImgTypeRes, fetchChallengeImageTypes } from 'API/fetchChallengeImageTypes'
import { useQuery, UseQueryResult } from 'react-query'

export function useChallengeImgTypes() {
    const imageTypes: UseQueryResult<ChallengeImgTypeRes, unknown> = useQuery(
        'challengeImgTypes',
        fetchChallengeImageTypes,
        {
            refetchOnWindowFocus: false
        }
    )

    return imageTypes
}
