/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    EuiDataGrid,
    EuiPanel,
    EuiLink,
    EuiProgress,
    formatDate,
    EuiEmptyPrompt,
    EuiSpacer,
    EuiHealth,
    EuiSearchBar,
    EuiText
} from '@elastic/eui'
import { Link } from '@reach/router'
import { Challenge, useChallengeList } from 'hooks/useChallengeList'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import create from 'zustand'

const columns = [
    {
        id: 'isPrivateChallenge',
        displayAsText: 'Status',
        isExpandable: false,
        isResizable: false,
        initialWidth: 120,
        schema: 'boolean'
    },
    {
        id: 'challengeId',
        displayAsText: 'ID',
        initialWidth: 100,
        isExpandable: false,
        isResizable: false
    },
    {
        id: 'name',
        displayAsText: 'Name',
        schema: 'string'
    },
    {
        id: 'challengeUrl',
        displayAsText: 'URL',
        schema: 'string'
    },
    {
        id: 'startDate',
        displayAsText: 'Start date',
        schema: 'datetime'
    },
    {
        id: 'endDate',
        displayAsText: 'End date',
        schema: 'datetime'
    },
    {
        id: 'registrationCloseDate',
        displayAsText: 'Registration close',
        schema: 'datetime'
    },
    {
        id: 'sortWeight',
        displayAsText: 'Sort Weight',
        schema: 'numeric'
    }
]

const searchSchema = {
    strict: true,
    fields: {
        isPrivateChallenge: {
            type: 'boolean'
        },
        challengeId: {
            type: 'number'
        },
        name: {
            type: 'string'
        },
        challengeUrl: {
            type: 'string'
        },
        startDate: {
            type: 'datetime'
        },
        endDate: {
            type: 'datetime'
        },
        registrationCloseDate: {
            type: 'datetime'
        },
        sortWeight: {
            type: 'number'
        }
    }
}

const urlSelector = (type: number) => {
    switch (type) {
        case 4: {
            return 'exercises'
        }
        default: {
            return 'challenges'
        }
    }
}

const formatChallenges = (challenges: Challenge[], eventType: ChallengeListDataGridProps['eventType']) => {
    return challenges?.map((challenge) => {
        return {
            isPrivateChallenge: {
                formatted: challenge.isPrivateChallenge ? (
                    <EuiHealth color="subdued">Draft</EuiHealth>
                ) : (
                    <EuiHealth color="success">Published</EuiHealth>
                ),
                raw: challenge.isPrivateChallenge
            },
            challengeId: challenge.challengeId,
            name: {
                formatted: (
                    <EuiLink>
                        <Link
                            to={
                                challenge.isPrivateChallenge
                                    ? `/${urlSelector(eventType)}/${challenge.challengeId}`
                                    : `/${urlSelector(eventType)}/${challenge.challengeId}/overview`
                            }
                        >
                            {challenge.name}
                        </Link>
                    </EuiLink>
                ),
                raw: challenge.name
            },
            challengeUrl: challenge.challengeUrl ?? '',
            startDate: {
                formatted: `${formatDate(challenge.startDate, 'dateTime') || ' '}`,
                raw: challenge.startDate || '0'
            },
            endDate: {
                formatted: `${formatDate(challenge.endDate, 'dateTime') || ' '}`,
                raw: challenge.endDate || '0'
            },
            registrationCloseDate: {
                formatted: `${formatDate(challenge.registrationCloseDate, 'dateTime') || ' '}`,
                raw: challenge.registrationCloseDate || '0'
            },
            sortWeight: challenge.sortWeight ?? 0
        }
    })
}

interface ChallengeListDataGridProps {
    eventType: 1 | 2 | 3 | 4
}
interface ChallengesGridState {
    data: Array<any>
    setData: (newData: Array<any>) => void
}

const useChallengesGridStore = create<ChallengesGridState>((set) => ({
    data: [],
    setData: (newData) => set(() => ({ data: newData }))
}))

export const EventListDataGrid = ({ eventType }: ChallengeListDataGridProps) => {
    const [query, setQuery] = useState('')
    const [, setSearchError] = useState(null)
    // const [incremental, setIncremental] = useState(false)
    const { data, status, isFetching, error } = useChallengeList(eventType)

    const formattedData = useChallengesGridStore((state) => state.data)
    const setFormattedData = useChallengesGridStore((state) => state.setData)
    // const [formattedData, setFormattedData] = useState<Array<any>>([])

    const onSearchChange = ({ query, error }: any) => {
        if (error) {
            setSearchError(error)
        } else {
            setSearchError(null)
            setQuery(query)
        }
    }

    // ** Pagination config
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 25 })
    const onChangeItemsPerPage = useCallback(
        (pageSize) => {
            setPagination((pagination) => ({
                ...pagination,
                pageSize,
                pageIndex: 0
            }))
            localStorage.setItem(
                'challenges-grid:page_params',
                JSON.stringify({
                    ...pagination,
                    pageSize,
                    pageIndex: 0
                })
            )
        },
        [setPagination]
    )
    const onChangePage = useCallback(
        (pageIndex) => {
            localStorage.setItem(
                'challenges-grid:page_params',
                JSON.stringify({
                    ...pagination,
                    pageIndex
                })
            )
            setPagination((pagination) => ({ ...pagination, pageIndex }))
        },
        [setPagination]
    )

    // ** Sorting config
    const [sortingColumns, setSortingColumns] = useState([])
    const setSorting = (untouchedSortingColumns: any) => {
        // only allow 1 column to be sorted (the last one added)
        const sortingColumns: any = untouchedSortingColumns.length
            ? [untouchedSortingColumns[untouchedSortingColumns.length - 1]]
            : []

        const sortedData = [...formattedData].sort((a, b) => {
            for (let i = 0; i < sortingColumns.length; i++) {
                const column = sortingColumns[i]
                const aValue = a[column.id].hasOwnProperty('raw') ? a[column.id].raw : a[column.id]

                const bValue = b[column.id].hasOwnProperty('raw') ? b[column.id].raw : b[column.id]

                if (aValue < bValue) return column.direction === 'asc' ? -1 : 1
                if (aValue > bValue) return column.direction === 'asc' ? 1 : -1
            }

            return 0
        })

        setFormattedData(sortedData)
        setSortingColumns(sortingColumns)
        localStorage.setItem('challenges-grid:sort_params', JSON.stringify({ sortingColumns }))

        if (!sortingColumns.length) {
            const formattedChallenges = formatChallenges(data?.challenges || [], eventType || 0)
            setFormattedData(formattedChallenges)
        }
    }

    // Column visibility
    const [visibleColumns, setVisibleColumns] = useState(() => columns.map(({ id }) => id)) // initialize to the full set of columns

    const renderCellValue: any = useMemo(() => {
        // eslint-disable-next-line react/display-name
        return ({ rowIndex, columnId }: { rowIndex: number; columnId: string }) => {
            function getFormatted() {
                return formattedData[rowIndex][columnId].formatted
                    ? formattedData[rowIndex][columnId].formatted
                    : formattedData[rowIndex][columnId]
            }

            return formattedData.hasOwnProperty(rowIndex) ? getFormatted() : null
        }
    }, [formattedData])

    useEffect(() => {
        if (data?.challenges.length) {
            const queriedItems = EuiSearchBar.Query.execute(query, data?.challenges ?? [], {
                defaultFields: ['challengeId', 'challengeUrl', 'name']
            })
            const formattedChallenges = formatChallenges(queriedItems, eventType)
            setFormattedData(formattedChallenges)
        }

        return () => setFormattedData([])
    }, [data, query])

    useEffect(() => {
        const pageParams = localStorage.getItem('challenges-grid:page_params')
        const sortParams = localStorage.getItem('challenges-grid:sort_params')

        if (pageParams) {
            setPagination(JSON.parse(pageParams))
        }

        if (sortParams) {
            if (formattedData.length) {
                setSortingColumns(JSON.parse(sortParams).sortingColumns)
                setSorting(JSON.parse(sortParams).sortingColumns)
            }
        }
    }, [formattedData.length])

    return (
        <EuiPanel hasBorder paddingSize="l" style={{ position: 'relative' }}>
            <EuiSearchBar
                query={query}
                box={{
                    placeholder: 'e.g. type:visualization -is:active joe',
                    incremental: true,
                    schema: searchSchema
                }}
                // filters={filters}
                onChange={onSearchChange}
            />
            <EuiSpacer size="m" />
            {status === 'loading' && isFetching && <EuiProgress size="xs" color="primary" position="absolute" />}
            {status === 'error' ? (
                <>
                    <EuiSpacer size="xl" />
                    <EuiEmptyPrompt
                        iconType="alert"
                        iconColor="danger"
                        title={<h2>Error loading datagrid</h2>}
                        body={
                            <p>
                                There was an error loading the datagrid. Contact your administrator for help. <br />
                                <br />
                                <EuiText color="danger">{(error as any).toString()}</EuiText>
                            </p>
                        }
                    />
                </>
            ) : (
                <EuiDataGrid
                    gridStyle={{
                        border: 'horizontal'
                    }}
                    aria-label="inMemory level set to sorting data grid demo"
                    columns={columns}
                    columnVisibility={{ visibleColumns, setVisibleColumns }}
                    rowCount={formattedData.length}
                    renderCellValue={renderCellValue}
                    inMemory={{ level: 'enhancements' }}
                    sorting={{ columns: sortingColumns, onSort: setSorting }}
                    pagination={{
                        ...pagination,
                        pageSizeOptions: [25, 50, 100],
                        onChangeItemsPerPage: onChangeItemsPerPage,
                        onChangePage: onChangePage
                    }}
                />
            )}
        </EuiPanel>
    )
}
