const ASSET_HOST = process.env.REACT_APP_ASSET_HOST

export function matches(text: string, partial: string) {
    return text.toLowerCase().indexOf(partial.toLowerCase()) > -1
}

export function prependAssetHost(url: string, path?: string) {
    if (url != null && url.startsWith('http')) return url
    if (path) {
        return ASSET_HOST + path + url
    }

    return ASSET_HOST + url
}

export function prependAssetHostChallenge(url: string) {
    if (typeof url !== 'undefined' && url !== null && !url.startsWith('http') && url.length > 27)
        //Skip the initial '/s3/asset.makeyeshappen.com' on the path
        url = url.substring(27)
    return prependAssetHost(url, '')
}

export function prependAssetHostItem(url: string) {
    return prependAssetHost(url, '/challenges/')
}
