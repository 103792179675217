import { IChallenge, IPlan, IPricing } from 'API/updateChallenge'
import moment from 'moment'
import * as Yup from 'yup'

interface ISelectedCheckboxes {
    [key: string]: boolean
}

function serializePricing(plans?: IPlan[]) {
    const selectedCheckboxes: ISelectedCheckboxes = { FreeMembership: true, CYFActive: false, CYFGroup: false }
    if (!plans) return selectedCheckboxes
    const selectedPlans = plans?.filter((plan) => plan.isSelected === true)
    // check the options that correlate to group, members,
    if (selectedPlans?.length > 0) {
        selectedCheckboxes['CYFActive'] = true
        selectedCheckboxes['FreeMembership'] = false
    }
    if (selectedPlans?.filter((plan) => plan.isGroupPlan).length > 0) selectedCheckboxes['CYFGroup'] = true
    return selectedCheckboxes
}

export default {
    initialValues: (challenge?: IChallenge, challengePricing?: IPricing) => {
        console.log({ isPrivate: challenge?.isPrivateChallenge || true, challenge })
        return {
            title: challenge?.name || '',
            challengeUrl: challenge?.challengeUrl || '',
            metaDescription: challenge?.metaDescription || '',
            message: challenge?.fundraisingMessage || '',
            metaKeywords: challenge?.metaKeywords || '',
            personalizeKeywords: challenge?.personalizeKeywords || '',
            sortWeight: challenge?.sortWeight || 0,
            startDate: (challenge?.startDate && moment(challenge?.startDate)) || null,
            endDate: (challenge?.endDate && moment(challenge?.endDate)) || null,
            closeDate: (challenge?.registrationCloseDate && moment(challenge?.registrationCloseDate)) || null,
            maxRegistrations: challenge?.maxNumberOfRegistrations || 0,
            pricing: serializePricing(challengePricing?.plans),
            exclusiveUntil: (challengePricing?.plans[0].untilTS && moment(challengePricing?.plans[0].untilTS)) || null,
            keywords: challenge?.keywords || [],
            isPrivate: challenge?.isPrivateChallenge ?? true // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_OR#using_or
            // featuredMerch: challenge?.featuredMerch || [],
            // challengeSpecific: challenge?.challengeSpecific || false,
            // classEnabled: challenge?.classEnabled || true,
            // challengeEnabled: challenge?.challengeEnabled || true,
            // relatedTrainers: challenge?.relatedTrainers || [],
        }
    },
    validationSchema: Yup.object().shape({
        title: Yup.string().required('A title for the exercise is required.'),
        challengeUrl: Yup.string().required('A url is required for the exercise.'),
        metaDescription: Yup.string().max(135, 'This field needs to have less than 135 characters.').nullable(),
        message: Yup.string().required('A message is required'),
        metaKeywords: Yup.string().nullable(),
        keywords: Yup.array(),
        personalizeKeywords: Yup.string(),
        sortWeight: Yup.number(),
        startDate: Yup.string().nullable(),
        endDate: Yup.string().nullable(),
        closeDate: Yup.string().nullable(),
        maxRegistrations: Yup.number().nullable(),
        pricing: Yup.object().test((key) => (key !== (undefined || null) ? true : false)),
        exclusiveUntil: Yup.string().nullable()
        // classEnabled: Yup.bool(),
        // challengeEnabled: Yup.bool(),
        // challengeSpecific: Yup.bool(),
        // featuredMerch: Yup.array().optional(),
        // relatedTrainers: Yup.array().optional()
    })
}
