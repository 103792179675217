import { RouteComponentProps } from '@reach/router'
import { EVENT_TYPE } from 'common/constants/eventType'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { LaneAdmin } from 'components/LaneAdmin'
import { FC } from 'react'

type ProductPrioritizePageProps = RouteComponentProps

export const ProductPrioritizePage: FC<ProductPrioritizePageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.SWAG }}>
            <LaneAdmin eventType={EVENT_TYPE.SWAG} />
        </AdminPageLayout>
    )
}
