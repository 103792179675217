import { EuiPageHeader } from '@elastic/eui'
import { useMatch } from '@reach/router'

export const DashboardPageHeader = () => {
    const match = useMatch('/')

    if (!match) {
        return null
    }

    return <EuiPageHeader restrictWidth={false} pageTitle="Home" />
}
