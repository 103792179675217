import { useQuery, UseQueryResult } from 'react-query'
import axios from '../common/axios'
import { ENDPOINTS } from '../common/constants/endpoints'
// import typehole from 'typehole'
interface ChallengeListRes {
    challenges: Challenge[]
    hasError: boolean
    message: null
    error: null
}
export interface Challenge {
    challengeId: number
    name: string
    challengeUrl: null | string
    price: null | number
    isPrivateChallenge: boolean
    startDate: null | string
    endDate: null | string
    registrationCloseDate: null | string
    sortWeight: number
    isExercise: boolean
    isSwag: boolean
    is30Day: boolean
    isHowFarCanYouGo: boolean
    key: number
    eventType: number
}

export const fetchChallengeList = async () => {
    const challengeListRes = await axios.get(ENDPOINTS.CHALLENGE_LIST_API)

    if (challengeListRes.status !== 200 || challengeListRes.data?.hasError) {
        throw new Error(challengeListRes.data.message)
    }

    // const data: ChallengeListRes = typehole.t(challengeListRes.data)
    const data: ChallengeListRes = challengeListRes.data

    return data
}

export function useChallengeList(eventType: number) {
    const challenges: UseQueryResult<ChallengeListRes, unknown> = useQuery(
        ['challengeList', eventType],
        fetchChallengeList,
        {
            select: (data) => {
                return {
                    ...data,
                    challenges: data.challenges.filter((challenge) => challenge.eventType === eventType)
                }
            }
        }
    )

    return challenges
}
