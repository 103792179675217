/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    EuiButtonIcon,
    EuiDragDropContext,
    euiDragDropReorder,
    EuiDraggable,
    EuiDroppable,
    EuiEmptyPrompt,
    EuiPanel,
    EuiLoadingSpinner,
    EuiSpacer,
    EuiFlexItem,
    EuiFlexGroup,
    EuiTitle,
    EuiButton,
    htmlIdGenerator,
    EuiProgress
} from '@elastic/eui'
import { RouteComponentProps } from '@reach/router'
import { mockFetchExerciseLanes } from 'API/mockFetchExerciseLanes'
import React, { FC, useEffect } from 'react'
import { useQuery } from 'react-query'
import create from 'zustand'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { EVENT_TYPE } from 'common/constants/eventType'
import { LaneAdmin } from 'components/LaneAdmin'

type ChallengePrioritizePageProps = RouteComponentProps

export const ChallengePrioritizePage: FC<ChallengePrioritizePageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.CHALLENGE }}>
            <LaneAdmin eventType={EVENT_TYPE.CHALLENGE} />
        </AdminPageLayout>
    )
}
