import { EuiCheckboxGroup, EuiDescribedFormGroup, EuiForm, EuiFormRow, EuiPanel } from '@elastic/eui'
import { Field, FormikValues, useFormikContext } from 'formik'
import React from 'react'
import { Fragment, useState } from 'react'

interface IKeyword {
    keywordId: string
    name: string
}
interface IKeywordSection {
    keywordSectionId: number
    name: string
    description: string
    keywords: IKeyword[]
}
interface IKeywordSectionProps {
    keywordSections: IKeywordSection[]
}
interface ISelectedCheckboxes {
    [key: string]: boolean
}

export default function KeywordSections(props: IKeywordSectionProps) {
    const { errors, touched, values, setFieldValue } = useFormikContext<FormikValues>()
    const selectedCheckboxes: ISelectedCheckboxes = {}
    values.keywords.map((keywordId: number) => (selectedCheckboxes[keywordId.toString()] = true))
    const [checkboxIdToSelectedMap, setCheckboxIdToSelectedMap] = useState(selectedCheckboxes)
    React.useMemo(() => setCheckboxIdToSelectedMap(selectedCheckboxes), [values])

    const onChange = (optionId: string) => {
        const newCheckboxIdToSelectedMap = {
            ...checkboxIdToSelectedMap,
            ...{
                [optionId]: !checkboxIdToSelectedMap[optionId]
            }
        }
        setCheckboxIdToSelectedMap(newCheckboxIdToSelectedMap)
        const newValues = Object.entries(newCheckboxIdToSelectedMap)
            .filter((keyword) => keyword[1] === true)
            .map((keyword) => Number(keyword[0]))
        setFieldValue('keywords', newValues)
    }

    return (
        <>
            {props.keywordSections?.map(({ name, keywords, keywordSectionId, description }) => (
                <EuiPanel style={{ marginTop: 20 }} key={keywordSectionId}>
                    <EuiForm component="div">
                        <EuiDescribedFormGroup
                            fullWidth
                            descriptionFlexItemProps={{
                                style: {
                                    maxWidth: '300px'
                                }
                            }}
                            title={<h3>{name}</h3>}
                            description={<Fragment>{description}</Fragment>}
                        >
                            <EuiFormRow
                                isInvalid={touched.keywords && errors.keywords ? true : false}
                                error={errors.keywords}
                            >
                                <Field
                                    as={EuiCheckboxGroup}
                                    options={keywords.map((keyword) => ({
                                        id: keyword?.keywordId?.toString(),
                                        label: keyword.name
                                    }))}
                                    idToSelectedMap={checkboxIdToSelectedMap}
                                    onChange={onChange}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: 200,
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-start',
                                        marginRight: 10
                                    }}
                                />
                            </EuiFormRow>
                        </EuiDescribedFormGroup>
                    </EuiForm>
                </EuiPanel>
            ))}
        </>
    )
}
