import React, { useState } from 'react'

import {
    EuiHeader,
    EuiHeaderLogo,
    EuiHeaderSectionItemButton,
    EuiIcon,
    EuiAvatar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiLink,
    EuiPopover,
    EuiSpacer,
    EuiText,
    htmlIdGenerator
} from '@elastic/eui'

import SitewideSearchbar from './SitewideSearchbar'

import YFlogo from '../../assets/logo.svg'
import { useUserProfile } from '../../hooks/useUserProfile'
import { useNavigate } from '@reach/router'
import { useEffect } from 'react'

export default function Header() {
    const { data, status, error } = useUserProfile()
    const navigate = useNavigate()

    const logout = () => {
        localStorage.clear()
        navigate('/login')
    }

    useEffect(() => {
        if (status === 'error') {
            console.log({ error })
        }
    }, [status])

    const HeaderUserMenu = () => {
        const id = htmlIdGenerator()()
        const [isOpen, setIsOpen] = useState(false)

        const onMenuButtonClick = () => {
            setIsOpen(!isOpen)
        }

        const closeMenu = () => {
            setIsOpen(false)
        }

        const button = (
            <EuiHeaderSectionItemButton
                aria-controls={id}
                aria-expanded={isOpen}
                aria-haspopup="true"
                aria-label="Account menu"
                onClick={onMenuButtonClick}
            >
                <EuiAvatar name={`${data?.firstName} ${data?.lastName}`} size="s" />
            </EuiHeaderSectionItemButton>
        )

        return (
            <EuiPopover
                id={id}
                button={button}
                isOpen={isOpen}
                anchorPosition="downRight"
                closePopover={closeMenu}
                panelPaddingSize="none"
            >
                <div style={{ width: 320 }}>
                    <EuiFlexGroup gutterSize="m" className="euiHeaderProfile" responsive={false}>
                        <EuiFlexItem grow={false}>
                            <EuiAvatar name={`${data?.firstName} ${data?.lastName}`} size="xl" />
                        </EuiFlexItem>

                        <EuiFlexItem>
                            <EuiText>{`${data?.firstName} ${data?.lastName}`}</EuiText>
                            <EuiText color="subdued">{data?.email}</EuiText>

                            <EuiSpacer size="m" />

                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiFlexGroup justifyContent="spaceBetween">
                                        {/* <EuiFlexItem grow={false}>
                                            <EuiLink>Edit profile</EuiLink>
                                        </EuiFlexItem> */}

                                        <EuiFlexItem grow={false}>
                                            <EuiLink onClick={() => logout()}>Log out</EuiLink>
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </div>
            </EuiPopover>
        )
    }

    return (
        <EuiHeader
            theme="dark"
            sections={[
                {
                    items: [
                        <EuiHeaderLogo iconType={YFlogo} key="0">
                            Admin
                        </EuiHeaderLogo>
                    ],
                    borders: 'none'
                },
                {
                    items: [<SitewideSearchbar key="1" />],
                    borders: 'none'
                },
                {
                    items: [
                        <EuiHeaderSectionItemButton key="4" aria-label="2 Notifications">
                            <EuiIcon type="help" size="m" />
                        </EuiHeaderSectionItemButton>,
                        <EuiHeaderSectionItemButton key="4" aria-label="2 Notifications" notification={'1'}>
                            <EuiIcon type="bell" size="m" />
                        </EuiHeaderSectionItemButton>,
                        <HeaderUserMenu key="5" />
                    ],
                    borders: 'none'
                }
            ]}
        />
    )
}
