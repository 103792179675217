import axios from '../common/axios'

type FetchLanesParams = {
    eventType: number
}

export const mockFetchExerciseLanes = async ({ eventType }: FetchLanesParams) => {
    const res = await axios.get(`/api/v1/admin/DiscoverEventList/${eventType}/0`)
    console.log({ res })
    return res.data
}
