import { RouteComponentProps } from '@reach/router'
import { AdminPageLayout } from 'components/Layout/AdminPageLayout'
import { FC } from 'react'
import { EVENT_TYPE } from '../../common/constants/eventType'
import { EventListDataGrid } from 'components/EventListDataGrid'
// import { ChallengesListTable } from './components/Manage/ChallengesListTable'

type ChallengeManagePageProps = RouteComponentProps

export const ChallengeManagePage: FC<ChallengeManagePageProps> = () => {
    return (
        <AdminPageLayout createFlyoutProps={{ eventType: EVENT_TYPE.CHALLENGE }}>
            <EventListDataGrid eventType={EVENT_TYPE.CHALLENGE} />
        </AdminPageLayout>
    )
}
